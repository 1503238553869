import React, { Component } from 'react'
import { Select, Spacer } from '../index'
import Box from '../Box'
import Modal from './Modal'
import Button from '../Button'
import { __, T } from '../../translations/i18n'
import { getMatchParams } from '../../shared/router'
import { Phase, PhaseOrderSupplier } from '../../api/types'
import PhaseOrderSuppliers from '../../api/PhaseOrderSuppliers'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onConfirm: (phase: Phase | undefined) => void
  onClose: () => void
  phaseOrderSuppliers: PhaseOrderSupplier[]
  smallestAcceptablePhaseSequence?: number
}
interface State {
  phaseOrderSuppliers: PhaseOrderSupplier[]
  phases: Phase[]
  phase: Phase | undefined
}

export default class ChangeCodeApprovalModal extends Component<Props, State> {
  state: State = {
    phaseOrderSuppliers: [],
    phases: [],
    phase: undefined,
  }

  sortAndGetPhases = (phaseOrderSuppliers: PhaseOrderSupplier[], smallestAcceptablePhaseSequence: number): Phase[] => {
    return phaseOrderSuppliers
      .sort((a, b) => b.sequenzaAvanzamento - a.sequenzaAvanzamento)
      .filter((ph) => !ph.skipAvanzamento && ph.sequenzaAvanzamento >= smallestAcceptablePhaseSequence)
      .map((ph) => ph.phase)
  }

  boxSize = (length: number) => {
    return length > 5 ? length * 50 : 300
  }

  resetFields() {
    this.setState({ phase: undefined })
  }

  render() {
    const { isOpen, onClose, title, onConfirm, phaseOrderSuppliers, smallestAcceptablePhaseSequence } = this.props
    const phases = this.sortAndGetPhases(phaseOrderSuppliers, smallestAcceptablePhaseSequence ?? 0)
    const { phase } = this.state

    return (
      <Modal
        visible={isOpen ?? true}
        size="lg"
        onClose={() => {
          onClose()
          this.resetFields()
        }}
      >
        <Box>
          <Modal.Title>{title}</Modal.Title>
          <Spacer />
          <Box center row mt={15}>
            <Select
              containerStyle={{ width: 250 }}
              borderColor="transparent"
              transparent
              onSelect={(itm) => this.setState({ phase: itm })}
              options={phases}
              placeholder={__(T.misc.phase)}
              config={{
                value: 'code',
                label: 'code',
                secondaryLabel: 'code',
              }}
            />
          </Box>
          <Spacer />
          <Box height={this.boxSize(phases.length)}></Box>
          <Box center row mt={15}>
            <Button
              style={{ flex: 0 }}
              title={__(T.misc.cancel)}
              onClick={() => {
                onClose()
                this.resetFields()
              }}
              variant="default"
            />
            <Spacer />
            <Button
              style={{ flex: 1 }}
              title={__(T.misc.confirm)}
              onClick={() => {
                onConfirm(phase)
                this.resetFields()
              }}
            />
          </Box>
        </Box>
      </Modal>
    )
  }
}
