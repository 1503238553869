import React, { Component } from 'react'
import { Form, Page, Box, Icons } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { showToast } from 'shared/utils'
import { MadeFor, TmrPlace } from 'api/types'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { EncodingConfig, fakeEncodingOperation, OutboundConfig } from 'shared/RemoteConfig'
import InboundShipments from 'api/InboundShipments'

interface State {
  places?: TmrPlace[]
  madeFors?: MadeFor[]
  forceDestinationSelect: boolean
}

export default class CustomCampionarioInbound extends Component<{}> {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  formRef!: any

  state: State = {
    places: [],
    madeFors: [],
    forceDestinationSelect: false,
  }

  componentDidMount() {}

  submit = async (data) => {
    try {
      const { inputCertilogo } = data
      const encodingOperation = RemoteConfig.getOperationConfig<EncodingConfig>(fakeEncodingOperation)

      // if the user scans a certilogo qrcode eg. http://certilogo.com/qr/006FWY6B5B, select only the code
      let certilogo = inputCertilogo
      if (encodingOperation.identifierMatchRegex) {
        const match = inputCertilogo.match(encodingOperation.identifierMatchRegex) ?? []
        certilogo = match[0] || inputCertilogo
      }
      const parcelDetail = await InboundShipments.getParcelByIdentifierAndStartInbound(certilogo)
      if (parcelDetail.expectedCounter.upcs !== 0) {
        await navigate('/inbound/:configCode/samplesReading/:parcelCode', {
          configCode: this.operation.code,
          parcelCode: parcelDetail?.header.parcelCode || undefined,
        })
      }
    } catch (err) {
      showToast({ title: __(T.error.error), description: err?.message ?? __(T.error.error), status: 'error' })
      navigate(`/inbound/samples/:configCode`, { configCode: this.operation?.code })
    }
  }

  onBackPress = () => {
    navigate(`/`)
  }

  render() {
    return (
      <Page title={this.operation?.description ?? 'Inbound'} onBackPress={this.onBackPress}>
        <Box flex center>
          <Form
            ref={(ref) => (this.formRef = ref!)}
            schema={[
              {
                placeholder: __(T.misc.certilogo),
                name: 'inputCertilogo',
                required: true,
                focus: true,
                image: <Icons.Barcode />,
              },
            ]}
            submitText="Avanti"
            onSubmit={this.submit}
            width={400}
          />
        </Box>
      </Page>
    )
  }
}
