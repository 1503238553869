import React, { Component } from 'react'
import {Page, Box, KeyValueRow, Table, Icons, ParcelInfoModal} from 'components'
import { getMatchParams } from 'shared/router'
import { CancelPackingListRequest, CustomShippingNotice, PackingListFilter, ShippingParcel } from 'api/types'
import { getChoiceDescription, getDatetime, showToast } from 'shared/utils'
import { createBrowserHistory } from 'history'
import { T, __ } from 'translations/i18n'
import PackingLists from 'api/PackingLists'
import PackingListStatus from 'components/PackingListStatus'
import styled from '@emotion/styled'

interface State {
  parcels: ShippingParcel[]
  loading: boolean
  shippingNotice?: CustomShippingNotice
  parcelToModal?: string
  isOpen?: boolean
}

const history = createBrowserHistory()

export default class PackingListRowDetail extends Component {
  state: State = {
    parcels: [],
    loading: true,
  }

  packingListFilter: PackingListFilter = {
    shippingCodes: [],
    parcelCodes: [],
    parcelStates: [],
    originPlaces: [],
    destinationPlaces: [],
    packingListStates: [],
    operationId: '',
  }

  cancelPackingListRequest: CancelPackingListRequest = {
    shippingCodes: [],
  }

  navigateBack(): void {
    history.goBack()
  }

  async componentDidMount() {
    const shippingCode = getMatchParams(this.props).shippingCode
    this.packingListFilter = { ...this.packingListFilter, shippingCodes: [shippingCode] }
    try {
      const detailResponse = await PackingLists.searchDetails(this.packingListFilter)
      this.setState({
        loading: false,
        parcels: detailResponse ? detailResponse[0].parcels : undefined,
        shippingNotice: detailResponse![0] ?? undefined,
      })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
    this.cancelPackingListRequest = { shippingCodes: [shippingCode] }
  }

  getOperation = (parcel: ShippingParcel) => {
    return parcel.shippingOperationParcels
      .filter((shipOp) => shipOp.operationType === 'OUTBOUND')
      .flatMap((shipOp) => shipOp.configuration.description)[0]
  }

  getTotalItems = (parcel: ShippingParcel) => {
    return parcel.shippingOperationParcels
      .filter((shipOp) => shipOp.operationType === 'OUTBOUND')
      .map((shipOp) => shipOp.counters.detectedCounter.items)
      .reduce((x, y) => x + y)
  }

  openRowDetails = (parcel) => {
    this.setState({ parcelToModal: parcel.code, isOpen: true })
  }

  closeRowDetails = () => {
    this.setState({ parcelToModal: undefined, isOpen: false })
  }

  render() {
    const { loading, shippingNotice, parcelToModal, isOpen, parcels } = this.state
    const plId = shippingNotice?.packingListId ?? ''
    const commessaCode = shippingNotice?.tom
    const choice = shippingNotice?.choice?.code
    const destinationPlace = shippingNotice?.destinationPlace?.description

    const structure = [
      {
        label: __(T.misc.parcel),
        customRender: (parcel: ShippingParcel) => <>{parcel?.code || '---'}</>,
      },
      {
        label: `${__(T.misc.creation_date)}`,
        customRender: (parcel: ShippingParcel) => <>{getDatetime(parcel?.creationDate)}</>,
        flex: 2,
      },
      {
        label: `${__(T.misc.operation)}`,
        customRender: (parcel: ShippingParcel) => <>{this.getOperation(parcel)}</>,
        flex: 2,
      },
      {
        label: `${__(T.misc.quantity, { quantity: shippingNotice?.totalParcels })}`,
        customRender: (parcel: ShippingParcel) => (
          <SmallCounter total={this.getTotalItems(parcel)}>{`${this.getTotalItems(parcel)}`}</SmallCounter>
        ),
        flex: 2,
      },
      {
        label: `${__(T.misc.detail)}`,
        customRender: (parcel: ShippingParcel) => (
            <Box p={30} center onClick={() => this.openRowDetails(parcel)}>
              <Icons.Info />
            </Box>
        ),
        flex: 2,
      },
    ]

    return (
      <Page
        loading={loading}
        title={`${__(T.titles.packing_list)} / ${__(T.misc.parcels)}`}
        onBackPress={() => this.navigateBack()}
        header={{
          details: [
            { label: __(T.misc.packing_list_id), value: plId.toString() },
            { label: __(T.misc.shipment), value: commessaCode },
            { label: __(T.misc.choiche), value: getChoiceDescription(choice) },
            { label: __(T.misc.destination), value: destinationPlace },
          ],
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Box flex />
            <KeyValueRow
              variant="raw"
              style={{ padding: 0 }}
              loading={loading}
              label={__(T.misc.status)}
              value={<PackingListStatus status={shippingNotice?.packingListState ?? '---'} />}
            />
            <KeyValueRow
              variant="raw"
              style={{ padding: 0, marginLeft: 40, marginRight: 40 }}
              loading={loading}
              label={__(T.misc.creation_date)}
              value={getDatetime(shippingNotice?.packingListDate)}
            />
          </Page.TopBar>
          <Table loading={loading} data={parcels} structure={structure} />
          {parcelToModal && (
              <ParcelInfoModal
                  parcelCode={parcelToModal}
                  onClose={this.closeRowDetails}
                  label={__(T.misc.parcel).concat(' ').concat(parcelToModal.toString())}
                  isOpen={!!isOpen}
              />
          )}
        </Page.Content>
      </Page>
    )
  }
}

const SmallCounter = styled(Box)<{
  total?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0px 20px;
  background-color: ${({ total = 0 }) => {
    if (total === 0) return '#FFFFFF'
    if (total > 0) return '#75EBA8'
    return '#75EBA8'
  }};
`
