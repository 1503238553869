import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Box, Button, Page, Tab, Table, Section, ParcelSectionHeader } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { ShipmentParcel, GroupedShipmentProduct } from 'api/types'
import { askUserConfirmation, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import ShipmentProvider from 'ShipmentProvider'
import Structures from 'shared/Structures'
import InboundShipments from 'api/InboundShipments'

interface State {
  shipment?: ShipmentParcel
  options: any[]
  loading: boolean
  groupedProducts: GroupedShipmentProduct[]
  totalProductsQuantity: number
  totalExpectedProductsQuantity: number
}

export default class InboundShipmentDetail extends Component {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    totalProductsQuantity: 0,
    totalExpectedProductsQuantity: 0,
    groupedProducts: [],
    loading: true,
    options: [
      { value: 'product', label: __(T.misc.product), active: false },
      { value: 'parcel', label: __(T.misc.parcel), active: true },
    ],
  }

  async componentDidMount() {
    await this.initShipmentDetail()
  }

  initShipmentDetail = async () => {
    const { shippingCode } = getMatchParams(this.props)
    try {
      const { inboundShipmentParcels, groupedProducts } = await ShipmentProvider.fetchInboundShipmentParcels({
        shippingCodes: [decodeURIComponent(shippingCode)],
      })

      if (!inboundShipmentParcels || inboundShipmentParcels.length === 0)
        throw new Error(`No parcels for shipping ${shippingCode} found`)

      let totalProductsQuantity = 0
      let totalExpectedProductsQuantity = 0

      groupedProducts.forEach((product) => {
        totalProductsQuantity += product.detected
        totalExpectedProductsQuantity += product.expected
      })

      this.setState({
        loading: false,
        shipment: { ...inboundShipmentParcels[0], parcels: inboundShipmentParcels },
        groupedProducts,
        totalProductsQuantity,
        totalExpectedProductsQuantity,
      })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  navigateBack = () => navigate('/inbound/shipments/:configCode', { configCode: getMatchParams(this.props).configCode })

  getFilteredProducts(products: GroupedShipmentProduct[], filter: string) {
    return products.filter(
      (product) =>
        product.product.code.toLowerCase().includes(filter) ||
        product.product.style?.value.toLowerCase().includes(filter) ||
        product.product.material?.value.toLowerCase().includes(filter) ||
        product.product.color?.value.toLowerCase().includes(filter) ||
        product.product.size?.value.toLowerCase().includes(filter)
    )
  }

  changeViews = (option: string) => {
    const { options } = this.state
    options.forEach((opt) => (opt.active = opt.value === option))
    this.setState({ options })
  }

  onSearch = () => {}

  openReadParcel = (parcel: ShipmentParcel) => {}

  onShipmentConfirm = async () => {
    try {
      const { shipment } = this.state
      if (!shipment) return

      const confirmed = await askUserConfirmation(
        __(T.confirm.confirm_shipment),
        __(T.confirm.confirm_shipment_message)
      )
      if (!confirmed) return

      await InboundShipments.confirmShipment({
        configurationId: this.operation.id,
        shippingCode: shipment.header.shippingCode,
      })
      showToast({ title: __(T.misc.confirmed), status: 'success' })
      navigate('/inbound/shipments/:configCode', { configCode: getMatchParams(this.props).configCode })
    } catch (e) {
      showToast({ title: e.message, status: 'error' })
    }
  }

  render() {
    const {
      shipment,
      options,
      groupedProducts,
      totalProductsQuantity,
      totalExpectedProductsQuantity,
      loading,
    } = this.state
    const parcels = shipment?.parcels

    const inInboundParcels = parcels?.filter((par) => par.header.parcelState === 'IN_INBOUND')
    const inTransitParcels = parcels?.filter((par) => par.header.parcelState === 'IN_TRANSIT')
    const receivedParcels = parcels?.filter((par) => par.header.parcelState === 'RECEIVED')

    const shippingCode = shipment?.header?.parcelAttributes?.TOM
    const parcelsCount = parcels?.length?.toString() ?? '0'

    const opt = options.find((o: any) => o.active).value

    return (
      <Page
        loading={loading}
        title={`${this.operation?.description ?? 'Inbound'}`}
        onBackPress={() =>
          navigate('/inbound/shipments/:configCode', { configCode: getMatchParams(this.props).configCode })
        }
        header={{
          details: [
            { label: __(T.misc.parcels), value: parcelsCount },
            { label: __(T.misc.products), value: `${totalProductsQuantity}/${totalExpectedProductsQuantity}` },
            { label: __(T.misc.shipment), value: shippingCode },
          ],
        }}
        headerRight={
          <Button onClick={this.onShipmentConfirm} variant="secondary" size="medium">
            {__(T.misc.confirm_shipment)}
          </Button>
        }
      >
        <Page.Content>
          <Box mb={40} row vcenter>
            <Tab
              variant="secondary"
              style={{ border: '2px solid #E0E0E0' }}
              options={options}
              onOptionSelected={this.changeViews}
            />
            <Box flex />
            <Button title={__(T.misc.refresh)} onClick={this.initShipmentDetail} />
          </Box>
          {opt === 'product' && (
            <Table
              headerStyle={{ position: 'relative' }}
              structure={Structures.ShipmentProductStructure(totalProductsQuantity)}
              loading={loading}
              data={groupedProducts}
              emptyStyle={{ height: 300 }}
            />
          )}
          {opt === 'parcel' && (
            <>
              {inInboundParcels && inInboundParcels.length > 0 && (
                <>
                  <SectionTitle>{__(T.titles.in_inbound_parcels)}</SectionTitle>
                  {inInboundParcels?.map((parcel, index) => (
                    <Section
                      key={index}
                      header={
                        <ParcelSectionHeader
                          parcel={parcel}
                          openReadParcel={this.openReadParcel}
                          operation={this.operation}
                        />
                      }
                      body={
                        <Table
                          headerStyle={{ position: 'relative' }}
                          style={{ paddingTop: 0 }}
                          showHeader={false}
                          structure={Structures.ShipmentProductStructure(totalProductsQuantity)}
                          loading={loading}
                          data={parcel.groupedProducts ?? []}
                          emptyStyle={{ height: 300 }}
                        />
                      }
                    />
                  ))}
                </>
              )}
              {inTransitParcels && inTransitParcels.length > 0 && (
                <>
                  <SectionTitle>{__(T.titles.to_receive_parcels)}</SectionTitle>
                  {inTransitParcels?.map((parcel, index) => (
                    <Section
                      key={index}
                      header={
                        <ParcelSectionHeader
                          parcel={parcel}
                          openReadParcel={this.openReadParcel}
                          operation={this.operation}
                        />
                      }
                      body={
                        <Table
                          headerStyle={{ position: 'relative' }}
                          style={{ paddingTop: 0 }}
                          showHeader={false}
                          structure={Structures.ShipmentProductStructure(totalProductsQuantity)}
                          loading={loading}
                          data={parcel.groupedProducts ?? []}
                          emptyStyle={{ height: 300 }}
                        />
                      }
                    />
                  ))}
                </>
              )}
              {receivedParcels && receivedParcels.length > 0 && (
                <>
                  <SectionTitle>{__(T.titles.received_parcels)}</SectionTitle>
                  {receivedParcels?.map((parcel, index) => (
                    <Section
                      key={index}
                      header={
                        <ParcelSectionHeader
                          parcel={parcel}
                          openReadParcel={this.openReadParcel}
                          operation={this.operation}
                        />
                      }
                      body={
                        <Table
                          headerStyle={{ position: 'relative' }}
                          style={{ paddingTop: 0 }}
                          showHeader={false}
                          structure={Structures.ShipmentProductStructure(totalProductsQuantity)}
                          loading={loading}
                          data={parcel.groupedProducts ?? []}
                          emptyStyle={{ height: 300 }}
                        />
                      }
                    />
                  ))}
                </>
              )}
            </>
          )}
        </Page.Content>
      </Page>
    )
  }
}

const SectionTitle = styled.div`
  font-weight: 900;
  font-size: 28px;
  margin-bottom: 10px;
`
