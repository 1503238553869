import React, { Component } from 'react'
import { Spacer, Box, TextArea, KeyValueRow, Input } from 'components'
import styled from '@emotion/styled'
import Button from 'components/Button'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import { TmrPrinter } from 'api/types'
import { showToast } from 'shared/utils'
import Modal from './Modal'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onConfirm: (
    note: string,
    printer: TmrPrinter,
    quantity: string,
    weight?: string,
    sizeH?: string,
    sizeL?: string,
    sizeP?: string
  ) => void
  onClose: () => void
  style?: any
}
interface State {
  note: string
  quantity: string
  printer?: TmrPrinter
  weight?: string
  sizeH?: string
  sizeL?: string
  sizeP?: string
  isSizeRequired: boolean
}
export default class PrintNotesModal extends Component<Props, State> {
  state: State = {
    note: '',
    quantity: AppStore.defaultPrintQuantity ?? '1',
    sizeH: AppStore.defaultSizeH,
    sizeL: AppStore.defaultSizeL,
    sizeP: AppStore.defaultSizeP,
    weight: AppStore.defaultWeight,
    printer: AppStore.defaultPrinter,
    isSizeRequired: false,
  }

  render() {
    const { isOpen, onClose, title, onConfirm, subtitle } = this.props
    return (
      <Modal visible={isOpen ?? true} size="lg" onClose={onClose}>
        <Box>
          <Title>{title}</Title>
          {subtitle && <Value>{subtitle}</Value>}
          <KeyValueRow
            variant="grey"
            label={__(T.misc.printer)}
            value={this.state.printer?.description}
            optionModal={{
              onSelect: (printers) => {
                AppStore.saveDefaultPrinter(printers[0])
                this.setState({ printer: printers[0] })
              },
              field: 'description',
              options: AppStore.printers ?? [],
              title: __(T.titles.select_printer),
              selected: [this.state.printer],
            }}
          />
          <Spacer />
          <TextArea
            textAreaStyle={{ height: 170 }}
            placeholder="Note da mandare in stampa"
            onChange={(note) => this.setState({ note })}
            maxLength={90}
          />
          <Input
            defaultValue={this.state.quantity}
            placeholder={__(T.placeholder.print_quantity)}
            onChange={(quantity) => this.setState({ quantity })}
            required
          />
          <Spacer />
          <Box flex row center>
            <Input
              defaultValue={this.state.sizeH}
              label={'H'}
              onChange={(sizeH) => {
                this.setState({ sizeH })
              }}
            />
            <Value style={{ paddingRight: 9, paddingLeft: 2 }}>X</Value>
            <Input
              defaultValue={this.state.sizeL}
              label={'L'}
              onChange={(sizeL) => {
                this.setState({ sizeL })
              }}
            />
            <Value style={{ paddingRight: 9, paddingLeft: 2 }}>X</Value>
            <Input
              defaultValue={this.state.sizeP}
              label={'P'}
              onChange={(sizeP) => {
                this.setState({ sizeP })
              }}
            />
          </Box>
          <Spacer />
          <Input
            defaultValue={this.state.weight}
            placeholder={__(T.placeholder.insert_weight)}
            onChange={(weight) => this.setState({ weight })}
            required
          />
          <Box center row mt={15}>
            <Button style={{ flex: 0 }} title="Annulla" onClick={onClose} variant="default" />
            <Spacer />
            <Button
              style={{ flex: 1 }}
              title="Conferma collo"
              onClick={() => {
                if (!this.state.printer)
                  return showToast({
                    title: __(T.error.error),
                    description: __(T.error.printer_not_found),
                    status: 'error',
                  })

                return onConfirm(
                  this.state.note,
                  this.state.printer!,
                  this.state.quantity,
                  this.state.weight,
                  this.state.sizeH,
                  this.state.sizeL,
                  this.state.sizeP
                )
              }}
            />
          </Box>
        </Box>
      </Modal>
    )
  }
}

const Title = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
`

const Value = styled.label`
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  overflow: hidden;
`
