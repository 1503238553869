import {
  ShipmentParcel,
  ShipmentParcelFilter,
  ShippingParcelDetail,
  ShipmentParcelPreview,
  ShippingParcelCreateRequest,
  ShippingConfirmRequest,
  TmrPlacePhase,
} from './types'
import api, { responseErrorCheck } from './api'

export default class OutboundShipments {
  static endpoint = '/shipping/outbound'

  static searchCounters(
    filters?: ShipmentParcelFilter,
    requestConfig = undefined
  ): Promise<ShipmentParcelPreview[] | undefined> {
    return api
      .post<ShipmentParcelPreview[]>(`${this.endpoint}/custom/search/counters`, filters ?? {}, requestConfig)
      .then(responseErrorCheck)
      .then((data) => data?.sort((a, b) => (b.header.parcelCreationDate ?? 0) - (a.header.parcelCreationDate ?? 0)))
  }

  static searchCrossCounters(
      filters?: ShipmentParcelFilter,
      requestConfig = undefined
  ): Promise<ShipmentParcelPreview[] | undefined> {
    return api
        .post<ShipmentParcelPreview[]>(`${this.endpoint}/cross/search/counters`, filters ?? {}, requestConfig)
        .then(responseErrorCheck)
        .then((data) => data?.sort((a, b) => (b.header.parcelCreationDate ?? 0) - (a.header.parcelCreationDate ?? 0)))
  }

  static searchDetail(filters: ShipmentParcelFilter, requestConfig = undefined): Promise<ShipmentParcel[] | undefined> {
    return api.post<ShipmentParcel[]>(`${this.endpoint}/search/detail`, filters, requestConfig).then(responseErrorCheck)
  }

  static searchDetailWithoutCrossParcels(filters: ShipmentParcelFilter, requestConfig = undefined): Promise<ShipmentParcel[] | undefined> {
    return api.post<ShipmentParcel[]>(`${this.endpoint}/custom/search/detail`, filters, requestConfig).then(responseErrorCheck)
  }

  static searchCrossDetail(filters: ShipmentParcelFilter, requestConfig = undefined): Promise<ShipmentParcel[] | undefined> {
    return api.post<ShipmentParcel[]>(`${this.endpoint}/cross/search/detail`, filters, requestConfig).then(responseErrorCheck)
  }

  static updateReadings(parcelCode, data: ShippingParcelDetail, requestConfig = undefined) {
    return api
      .post(`${this.endpoint}/parcel/${parcelCode}/updateReadings`, data, requestConfig)
      .then(responseErrorCheck)
  }

  static confirmParcel(parcelCode, data: ShippingConfirmRequest = {}, requestConfig = undefined) {
    return api.post(`${this.endpoint}/parcel/${parcelCode}/confirm`, data, requestConfig).then(responseErrorCheck)
  }

  static createParcel(data: ShippingParcelCreateRequest, requestConfig = undefined) {
    return api.post<ShipmentParcel>(`${this.endpoint}/parcel/start`, data, requestConfig).then(responseErrorCheck)
  }

  static createCrossParcel(data: ShippingParcelCreateRequest, requestConfig = undefined) {
    return api.post<ShipmentParcel>(`${this.endpoint}/cross/create`, data, requestConfig).then(responseErrorCheck)
  }

  static destinationChoices(choice: string, orderCode: string, configurationId?: string, currentPhase?: string) {
    return api
      .get<TmrPlacePhase>(
        `${this.endpoint}/destination/choice?choice=${choice}&orderCode=${orderCode}${
          configurationId ? `&configurationId=${configurationId}` : ''
        }${currentPhase ? `&currentPhase=${currentPhase}` : ''}`
      )
      .then(responseErrorCheck)
  }

  static cancelParcelAx(parcelCode?: string) {
    return api.post<ShippingParcelDetail>(`${this.endpoint}/cancelParcelAx/${parcelCode}`).then(responseErrorCheck)
  }
}
