import React, { Component } from 'react'
import { Select, Spacer } from '../index'
import Box from '../Box'
import Modal from './Modal'
import Button from '../Button'
import { __, T } from '../../translations/i18n'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onConfirm: (color: string, size: string) => void
  onClose: () => void
  sizes: string[]
  colors: string[]
}
interface State {
  color: string
  size: string
}

export default class ChangeCodeManagementModal extends Component<Props, State> {
  state: State = {
    color: '',
    size: '',
  }

  boxSize = (lenght) => {
    switch (lenght) {
      case 0:
        return 50
      case 1:
        return 100
      case 2:
        return 150
      case 3:
        return 200
      case 4:
        return 250
      case 5:
        return 280
      default:
        return 300
    }
  }

  resetFields() {
    this.setState({ color: '', size: '' })
  }

  render() {
    const { isOpen, onClose, title, onConfirm, sizes, colors } = this.props
    const { color, size } = this.state

    return (
      <Modal
        visible={isOpen ?? true}
        size="lg"
        onClose={() => {
          onClose()
          this.resetFields()
        }}
      >
        <Box>
          <Modal.Title>{title}</Modal.Title>
          <Spacer />
          <Box center row mt={15}>
            <Select
              containerStyle={{ width: 250 }}
              borderColor="transparent"
              transparent
              onSelect={(itm) => this.setState({ size: itm?.codice ?? '' })}
              defaultValue={''}
              options={sizes}
              placeholder={__(T.misc.size)}
              config={{ value: 'codice', label: 'codice', secondaryLabel: 'codice' }}
            />
            <Select
              containerStyle={{ width: 250 }}
              borderColor="transparent"
              transparent
              onSelect={(itm) => this.setState({ color: itm?.codice ?? '' })}
              options={colors}
              placeholder={__(T.misc.color)}
              config={{ value: 'codice', label: 'codice', secondaryLabel: 'codice' }}
            />
          </Box>
          <Spacer />
          <Box height={this.boxSize(colors.length > sizes.length ? colors.length : sizes.length)}></Box>
          <Box center row mt={15}>
            <Button
              style={{ flex: 0 }}
              title={__(T.misc.cancel)}
              onClick={() => {
                onClose()
                this.resetFields()
              }}
              variant="default"
            />
            <Spacer />
            <Button
              style={{ flex: 1 }}
              title={__(T.misc.confirm)}
              onClick={() => {
                onConfirm(color, size)
                this.resetFields()
              }}
            />
          </Box>
        </Box>
      </Modal>
    )
  }
}
