import { CustomUpdateRequest } from 'api/Items'
import CustomItems from 'api/CustomItems'
import { TmrItem } from 'api/types'
import { navigate } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import AppStore from '../../AppStore'

export async function changeChoice(items: TmrItem[], attributes: any) {
  if (!items || items.length === 0) throw new Error(__(T.messages.list_empty))
  const confirmed = await askUserConfirmation(__(T.confirm.change_choice_title), __(T.confirm.change_choice_message))
  if (!confirmed) {
    return
  }
  const request: CustomUpdateRequest = {
    itemIds: items.map((itm) => itm.id),
  }
  let userId
  if (attributes.choice) request.choice = attributes.choice
  if (attributes.flaw) request.flaw = attributes.flaw
  if (attributes.tone != null) request.tone = attributes.tone
  if (attributes.madeFor) request.madeFor = attributes.madeFor
  if (AppStore.loggedUser?.id != null) userId = AppStore.loggedUser?.id
  await CustomItems.changheChoiceUpdate(request, userId)
  showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
  navigate('/change-choice')
}
