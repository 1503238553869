import { DdtsFilter, ShippingDdt, ShippingDdtCreateRequest, TmrPlace } from './types'
import api, { responseErrorCheck } from './api'

export default class Ddt {
  static endpoint = '/shippingddts'

  static searchDetails(ddtsFilter: DdtsFilter): Promise<ShippingDdt[] | undefined> {
    return api.post<ShippingDdt[]>(`${this.endpoint}/searchDetails`, ddtsFilter).then(responseErrorCheck)
  }

  static create(shippingDdtCreateRequest: ShippingDdtCreateRequest): Promise<ShippingDdt | undefined> {
    return api.post<ShippingDdt>(`${this.endpoint}/create`, shippingDdtCreateRequest).then(responseErrorCheck)
  }

  static update(shippingDdtRequest: ShippingDdt): Promise<ShippingDdt | undefined> {
    return api.post<ShippingDdt>(`${this.endpoint}/update`, shippingDdtRequest).then(responseErrorCheck)
  }

  static uploadPdf(pdfFile: any, idDdt: string): Promise<any | undefined> {
    return api
      .post<any>(`${this.endpoint}/uploadPdf/${idDdt}`, pdfFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(responseErrorCheck)
  }

  static exportPdf(ddtId: string): Promise<any> {
    return api
      .get<any>(`${this.endpoint}/exportPdf/${ddtId}`, undefined, {
        headers: {
          Accept: 'application/octet-stream',
          'Content-Type': 'application/pdf',
        },
        responseType: 'blob',
      })
      .then(responseErrorCheck)
  }

  static exportPdfDraft(ddtId: string): Promise<any> {
    return api
      .get<any>(`${this.endpoint}/exportPdf/draft/${ddtId}`, undefined, {
        headers: {
          Accept: 'application/octet-stream',
          'Content-Type': 'application/pdf',
        },
        responseType: 'blob',
      })
      .then(responseErrorCheck)
  }

  static searchCounters(ddtsFilter: DdtsFilter): Promise<ShippingDdt[] | undefined> {
    return api.post<ShippingDdt[]>(`${this.endpoint}/searchCounters`, ddtsFilter).then(responseErrorCheck)
  }

  static getPlaces(): Promise<TmrPlace[]> {
    return api.get<TmrPlace[]>(`${this.endpoint}/places`).then(responseErrorCheck)
  }
}
