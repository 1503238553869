import React, { Component } from 'react'
import { CustomShippingParcel } from 'api/types'
import { Box, List, ParcelStatus } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import { getDatetime } from 'shared/utils'

interface Props {
  shippingParcel: CustomShippingParcel
  checkboxHandleChange?: (event) => void
  isChecked?: boolean
  disabled?: boolean
  checkboxLabel?: string
}

export default class PackingListParcelsRow extends Component<Props> {
  getOperation = (shippingParcel: CustomShippingParcel) => {
    return shippingParcel.shippingOperationParcels.find((e) => e !== undefined)?.operationType
  }

  getMadeFor = (shippingParcel: CustomShippingParcel) => {
    const madeFor = shippingParcel?.madeFor
    return madeFor ? madeFor : "---"
  }

  getTone = (shippingParcel: CustomShippingParcel) => {
    const tone = shippingParcel?.tone
    return tone ? tone : "---"
  }

  ellipsisLabel = (label?: string) => {
      if (!label || label.length < 1) return "---"
      if (label.length <= 10) return label
      return label.slice(0, 10) + "..."
  }

  render() {
    const { shippingParcel, checkboxHandleChange, isChecked, checkboxLabel, disabled } = this.props
    const outboundParcel =
      shippingParcel.shippingOperationParcels.filter((o) => o.operationType === 'OUTBOUND')[0] || undefined

    return (
      <RowItem>
        <List.Cell style={{ width: 200 }} label={__(T.misc.parcel)} value={shippingParcel.code} />
        <Box style={{ width: 160 }}>
          <Box style={{ fontWeight: 700, marginBottom: 5 }}>{__(T.misc.status)}</Box>
          <ParcelStatus width={150} status={shippingParcel.state} />
        </Box>
        <List.Cell style={{ width: 240 }} label={__(T.misc.shipment)} value={outboundParcel.attributes.TOM ?? '---'} />
        <List.Cell
          style={{ width: 240 }}
          label={__(T.misc.creation_date)}
          value={getDatetime(shippingParcel?.creationDate)}
        />
        <List.Cell style={{ width: 150 }} label={__(T.fields.tone)} value={this.getTone(shippingParcel)} />
        <List.Cell style={{ width: 150 }} label={__(T.misc.madeFor)} value={this.getMadeFor(shippingParcel)} />
        <List.Cell
            style={{ width: 240 }}
            label={"Note"}
            value={this.ellipsisLabel(outboundParcel.attributes?.printNote)}
            tooltipEnabled={outboundParcel.attributes?.printNote?.length > 10}
            tooltipLabel={outboundParcel.attributes?.printNote}
        />
        {checkboxHandleChange && (
          <Box style={{ alignItems: 'center' }}>
            {checkboxLabel && (
              <Box height={24} style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>
                {checkboxLabel}
              </Box>
            )}
            <Box>
              <CheckBoxStyle
                type="checkbox"
                style={{ padding: 10, verticalAlign: 'middle' }}
                id={shippingParcel.code}
                value={shippingParcel.code}
                onClick={(event: any) => {
                    event.stopPropagation()
                }}
                onChange={(event: any) => {
                    checkboxHandleChange(event)
                }}
                defaultChecked={false}
                checked={isChecked}
                disabled={disabled}
              />
            </Box>
          </Box>
        )}
      </RowItem>
    )
  }
}

const CheckBoxStyle = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  transform: scale(1.5);
  :focus {
    outline: black;
  }
`
const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`
