import api, { responseErrorCheck, customResponseErrorCheck } from './api'
import { QualityControlEntry, TmrPrinter, ShipmentParcelPreview } from './types'

export interface BatchDecodeRequest {
  configurationId: string
  identifiers: string[]
  parcelDto?: ShipmentParcelPreview
}

export interface CustomBatchDecodeRequest {
  configurationId: string
  identifiers: string[]
  parcelDto: ShipmentParcelPreview
}

export default class Shipments {
  static endpoint = '/shipping'

  static batchDecode<T>(data: BatchDecodeRequest) {
    return api.post<T>(`${this.endpoint}/batchDecode`, data).then((res) => res.data)
  }

  static printLabel(parcelCode: string, printer: TmrPrinter, quantity?: number): Promise<void> {
    return api
      .get<void>(
        `${this.endpoint}/parcel/${parcelCode}/label?printer.code=${printer.code}${
          quantity === undefined ? '' : `&quantity=${quantity}`
        }`
      )
      .then((res) => res.data)
  }

  static printCrossLabel(parcelCode: string, printer: TmrPrinter, quantity?: number): Promise<void> {
    return api
        .get<void>(
            `${this.endpoint}/parcel/cross/${parcelCode}/label?printer.code=${printer.code}${
                quantity === undefined ? '' : `&quantity=${quantity}`
            }`
        )
        .then((res) => res.data)
  }

  static shippingParcelQualityControls(parcelCode: string) {
    return api
      .get<QualityControlEntry[]>(`shippingParcelQualityControls?parcelCode=${parcelCode}`)
      .then(responseErrorCheck)
  }

  static batchValidate<T>(data: BatchDecodeRequest) {
    return api.post<T>(`${this.endpoint}/batchValidate`, data).then(customResponseErrorCheck)
  }

  static customBatchValidate<T>(data: CustomBatchDecodeRequest) {
    return api.post<T>(`${this.endpoint}/batchValidate`, data).then(customResponseErrorCheck)
  }

  static getParcelCodeByIdentifier(identifier: string) {
    return api
      .get<{ value: string }>(`${this.endpoint}/findLastParcel?identifier=${identifier}`)
      .then(responseErrorCheck)
  }
}
