export default {
  parcel1: [
    '3035EBBC4830854000000001',
    '3035EBBC4830854000000002',
    'E280689400004005B6B71466',
    '3035EBBC48308540000000A2',
    '1111111',
    // '3035EBBC4830854000000004',
    // '3035EBBC4830854000000005',
    // 'E28011052000748E1E930981',
    // 'E28011052000748E1E930982',
    // 'E28011052000748E1E930983',
    // '3035EB67D44686000093DBA3',
    // '303B12300020FC4000000018', // TODO: trows 400
  ],
  parcel2: [
    'E28011606000020CCB63ACBC',
    '303B12300020FC400000000E',
    '303B12300020FC4000000001',
    '303B12300020FC4000000002',
    '303B12300020FC4000000003',
    '303B12300020FC4000000004',
    '303B12300020FC4000000005',
    '303B12300020FC4000000006',
  ],
}
