import { ConfirmModalParams, ModalType, TmrPrinter, TmrUser, TmrWorkstation, TmrZone } from 'api/types'
import Auth from 'api/Auth'
import Storage from 'shared/Storage'
import Workstations from 'api/Workstations'
import { showToast, __isDev } from 'shared/utils'
import { reloadApp } from 'shared/router'
import Zones from 'api/Zones'
import api from 'api/api'
import * as Sentry from '@sentry/react'
import { changeLanguage as changeLanguage_i18n, T, __ } from 'translations/i18n'
import RemoteConfig from 'shared/RemoteConfig'
import Printers from 'api/Printers'

export interface Emulation {
  name: string
  value: string
}

class AppStore {
  authToken?: string

  defaultWorkstation?: TmrWorkstation
  defaultPrinter?: TmrPrinter

  defaultPrintQuantity?: string

  defaultSizeH?: string
  defaultSizeL?: string
  defaultSizeP?: string
  defaultWeight?: string

  workstations?: TmrWorkstation[]
  printers?: TmrPrinter[]

  loggedUser?: TmrUser

  zones?: TmrZone[]

  language = 'it'

  languages = ['it', 'en']

  emulation = __isDev

  dailyItems = 0

  emulationList: Emulation[] = []

  envelopeNextPrintDate?: number

  envelopeWaitPrintTime?: number

  confirmModalParams: ConfirmModalParams = { title: '', message: '', onConfirm: () => {}, onCancel: () => {} }
  toggleConfirmModalHandler?: (show: boolean) => void

  openModal?: (modal: ModalType) => void
  hideModal?: (id: string) => void

  reloadRouting!: () => void

  async loadInitalData() {
    //check language
    this.language = await Storage.load('language', 'it')
    changeLanguage_i18n(this.language)

    //check if is present a custom endpoint
    api.setBaseURL(await Storage.load('endpoint', api.getBaseURL()))

    await this.loadAuthToken()
    if (this.authToken) {
      try {
        this.loggedUser = await Auth.loggedUser()
        if (this.loggedUser) {
          Sentry.setUser({
            id: this.loggedUser.id,
            username: this.loggedUser.username,
            email: this.loggedUser.email,
          })
          this.workstations = await Workstations.searchByPlaceId(this.loggedUser.place.id)
          this.printers = await Printers.search()

          if (this.workstations && this.workstations.length === 1) {
            await this.saveDefaultWorkstation(this.workstations[0])
          } else if (this.workstations && this.workstations.length > 1) {
            const dWorkstation = await this.getDefaultWorkstation()
            this.defaultWorkstation = this.workstations?.find((w) => w.id === dWorkstation?.id)
          }

          this.defaultPrinter = await this.getDefaultPrinter()

          this.zones = await Zones.search({ 'place.id': this.loggedUser.place.id })

          this.dailyItems = await Storage.load(`dailyItems`, 0)

          this.emulation = await Storage.load(`emulation`, false)

          this.emulationList = await this.loadEmulationList()

          this.defaultPrintQuantity = await this.getDefaultPrintQuantity()

          this.defaultSizeH = await this.getDefaultSizeH()
          this.defaultSizeL = await this.getDefaultSizeL()
          this.defaultSizeP = await this.getDefaultSizeP()
          this.defaultWeight = await this.getDefaultWeight()

          // load remote operations configurations
          await RemoteConfig.load()
        }
      } catch (err) {
        showToast({
          title: __(T.error.error),
          description: err?.message ?? 'Check capabilities',
          status: 'error',
        })
        this.logout()
        throw err
      }
    }
  }

  async getEndpoint() {
    return Storage.load('endpoint', api.getBaseURL())
  }

  async changeLanguage(language: string) {
    this.language = language
    await Storage.save('language', language)
    changeLanguage_i18n(language)
  }

  async setEndpoint(endpoint) {
    await Storage.save('endpoint', endpoint)
    reloadApp()
  }

  getZoneByType(type: 'STOCK' | 'SOLD' | 'INBOUND' | 'LOST') {
    if (!this.zones) return undefined

    return this.zones.find((zone) => zone.zoneType === type)
  }

  async enableEmulation(emulation = true) {
    this.emulation = emulation
    await Storage.save(`emulation`, emulation)
  }

  async increaseDailyItems() {
    this.dailyItems++
    await Storage.save(`dailyItems`, this.dailyItems)
  }

  async resetDailyItems() {
    this.dailyItems = 0
    await Storage.save(`dailyItems`, 0)
  }

  async saveDefaultWorkstation(workstation: TmrWorkstation) {
    this.defaultWorkstation = workstation
    await Storage.save(`defaultWorkstation`, workstation)
    return this.defaultWorkstation
  }

  async getDefaultWorkstation() {
    this.defaultWorkstation = await Storage.load(`defaultWorkstation`, this.defaultWorkstation)
    return this.defaultWorkstation
  }

  async saveDefaultPrinter(printer: TmrPrinter) {
    this.defaultPrinter = printer
    await Storage.save(`defaultPrinter`, printer)
    return this.defaultPrinter
  }

  async getDefaultPrinter() {
    this.defaultPrinter = await Storage.load(`defaultPrinter`, this.defaultPrinter)
    return this.defaultPrinter
  }

  async saveDefaultPrintQuantity(quantity: string) {
    this.defaultPrintQuantity = quantity
    await Storage.save(`defaultPrintQuantity`, quantity)
    return this.defaultPrintQuantity
  }

  async getDefaultPrintQuantity() {
    this.defaultPrintQuantity = await Storage.load(`defaultPrintQuantity`, this.defaultPrintQuantity)
    return this.defaultPrintQuantity
  }

  async saveDefaultSizeH(sizeH: string) {
    this.defaultSizeH = sizeH
    await Storage.save(`defaultSizeH`, sizeH)
    return this.defaultSizeH
  }

  async getDefaultSizeH() {
    this.defaultSizeH = await Storage.load(`defaultSizeH`, this.defaultSizeH)
    return this.defaultSizeH
  }

  async saveDefaultSizeL(sizeL: string) {
    this.defaultSizeL = sizeL
    await Storage.save(`defaultSizeL`, sizeL)
    return this.defaultSizeL
  }

  async getDefaultSizeL() {
    this.defaultSizeL = await Storage.load(`defaultSizeL`, this.defaultSizeL)
    return this.defaultSizeL
  }

  async saveDefaultSizeP(sizeP: string) {
    this.defaultSizeP = sizeP
    await Storage.save(`defaultSizeP`, sizeP)
    return this.defaultSizeP
  }

  async getDefaultSizeP() {
    this.defaultSizeP = await Storage.load(`defaultSizeP`, this.defaultSizeP)
    return this.defaultSizeP
  }

  async saveDefaultWeight(weight: string) {
    this.defaultWeight = weight
    await Storage.save(`defaultWeight`, weight)
    return this.defaultSizeP
  }

  async getDefaultWeight() {
    this.defaultWeight = await Storage.load(`defaultWeight`, this.defaultWeight)
    return this.defaultWeight
  }

  async saveEnvelopeNextPrintDate(envelopeNextPrintDate: number) {
    this.envelopeNextPrintDate = envelopeNextPrintDate
    await Storage.save(`envelopeNextPrintDate`, envelopeNextPrintDate)
    return this.envelopeNextPrintDate
  }

  async getEnvelopeNextPrintDate() {
    this.envelopeNextPrintDate = await Storage.load(`envelopeNextPrintDate`, this.envelopeNextPrintDate)
    return this.envelopeNextPrintDate
  }

  async saveEnvelopeWaitPrintTime(envelopeWaitPrintTime: number) {
    this.envelopeWaitPrintTime = envelopeWaitPrintTime
    await Storage.save(`envelopeWaitPrintTime`, envelopeWaitPrintTime)
    return this.envelopeWaitPrintTime
  }

  async getEnvelopeWaitPrintTime() {
    this.envelopeWaitPrintTime = await Storage.load(`envelopeWaitPrintTime`, this.envelopeWaitPrintTime)
    return this.envelopeWaitPrintTime
  }

  async saveAuthToken(token?: string) {
    this.authToken = token
    await Storage.save(`authToken`, token)
    return this.authToken
  }

  async logout() {
    // await Auth.logout()
    Storage.remove(`authToken`)
    this.authToken = undefined
    Storage.remove(`defaultWorkstation`)
    this.defaultWorkstation = undefined
    Storage.remove(`defaultPrinter`)
    this.defaultPrinter = undefined
    Storage.remove(`defaultPrintQuantity`)
    this.defaultPrintQuantity = undefined
    Storage.remove(`defaultSizeH`)
    this.defaultSizeH = undefined
    Storage.remove(`defaultSizeL`)
    this.defaultSizeL = undefined
    Storage.remove(`defaultSizeP`)
    this.defaultSizeP = undefined
    Storage.remove(`defaultWeight`)
    this.defaultWeight = undefined
    Storage.remove(`envelopeNextPrintDate`)
    this.envelopeNextPrintDate = undefined
    Storage.remove(`envelopeWaitPrintTime`)
    this.envelopeWaitPrintTime = undefined
    Storage.remove(`dailyItems`)
    Storage.remove(`emulation`)
    this.loggedUser = undefined
    if (this.reloadRouting) this.reloadRouting()
    else reloadApp()
  }

  async loadAuthToken() {
    this.authToken = await Storage.load(`authToken`, this.authToken)
    Auth.setHeaderAccessToken(this.authToken!)
    return this.authToken
  }

  async saveEmulationList(emulationList: Emulation[]) {
    this.emulationList = emulationList
    await Storage.save(`emulationList`, emulationList)
    return this.emulationList
  }

  async loadEmulationList() {
    this.emulationList = await Storage.load(`emulationList`, this.emulationList)
    return this.emulationList
  }

  setConfirmModalHandler(handler) {
    this.toggleConfirmModalHandler = handler
  }

  showConfirmModal(title: string, message: string, onConfirm: () => void, onCancel?: () => void) {
    this.confirmModalParams = { title, message, onConfirm, onCancel: onCancel ?? undefined }
    this.toggleConfirmModalHandler?.(true)
  }

  hideConfirmModal() {
    this.toggleConfirmModalHandler?.(false)
  }
}

export default new AppStore()
