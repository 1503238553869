import React, { Component } from 'react'
import { Box, Form, Page } from 'components'
import { Flaw, MadeFor } from 'api/types'
import { getChoiceOptions, showToast } from 'shared/utils'
import { isFirstChoice } from 'shared/customUtils'
import { __, T } from 'translations/i18n'
import Flaws from 'api/Flaws'
import MadeFors from 'api/MadeFors'
import { navigate } from 'shared/router'
import AppStore from 'AppStore'

interface State {
  flaws?: Flaw[]
  madeFors: MadeFor[]
  choices: any[]
}
export default class CustomChangeChoiceCreate extends Component<{}, State> {
  state: State = {
    flaws: [],
    madeFors: [],
    choices: [],
  }

  async componentDidMount() {
    if (
      AppStore.loggedUser?.place?.attributes &&
      AppStore.loggedUser?.place?.attributes['place.changeChoice.show.madeFor'] === 'true'
    )
      this.fetchMadeFors()
    this.setState({ choices: await getChoiceOptions() })
  }

  fetchFlaws = async (choiceCode: string) => {
    try {
      const flaws = isFirstChoice(choiceCode) ? [] : await Flaws.search<Flaw>({ limit: 500, 'choice.code': choiceCode })
      flaws.forEach((flaw) => {
        flaw.description = `${flaw.code} - ${flaw.description}`
      })

      this.setState({ flaws })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  fetchMadeFors = async () => {
    try {
      this.setState({ madeFors: (await MadeFors.search<MadeFor>({ limit: 500 })) ?? [] })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onChoiceChange = async (newValue: any) => {
    if (!newValue || !newValue.id) {
      this.setState({ flaws: [] })
      return
    }
    await this.fetchFlaws(newValue.id)
  }

  submit = async (data) => {
    if (!data.choice && !data.tone && !data.madeFor) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.choice_or_tone_or_madefor_required),
        status: 'error',
      })
      return
    }
    const flawEnabled =
      AppStore.loggedUser?.place?.attributes &&
      AppStore.loggedUser?.place?.attributes['place.changeChoice.show.flaw'] === 'true'
    if (data.choice && !isFirstChoice(data.choice.id) && flawEnabled && !data.flaw) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.flaw_reason_required),
        status: 'error',
      })
      return
    }

    const state = {
      choice: data.choice?.id,
      flaw: data.flaw?.code,
      tone: data.tone?.id,
      madeFor: data.madeFor?.code,
    }

    navigate('/items-list/:operationCode', { operationCode: 'change-choice' }, { state })
  }

  render() {
    const { flaws, madeFors, choices } = this.state
    const optionsObj = choices.map((opt) => {
      return {
        id: opt.value,
        description: opt.label,
      }
    })
    const tones = Array.from({ length: 21 }, (_, i) => i).map((tone) => {
      return {
        id: tone,
        description: tone == 0 ? __(T.labels.toneUndefined) : tone + '',
      }
    })

    return (
      <Page title={__(T.titles.change_choice)}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  placeholder: __(T.misc.choiche),
                  name: 'choice',
                  type: 'select',
                  options: optionsObj,
                  onChange: this.onChoiceChange,
                },
                {
                  placeholder: __(T.fields.flaw_reason),
                  name: 'flaw',
                  type: 'select',
                  options: flaws,
                  disabled: !flaws || flaws.length === 0,
                  config: { value: 'code', label: 'description', secondaryLabel: 'code' },
                  hide:
                    AppStore.loggedUser?.place?.attributes &&
                    AppStore.loggedUser?.place?.attributes['place.changeChoice.show.flaw'] !== 'true',
                },
                {
                  placeholder: __(T.fields.tone),
                  name: 'tone',
                  type: 'select',
                  options: tones,
                  hide:
                    AppStore.loggedUser?.place?.attributes &&
                    AppStore.loggedUser?.place?.attributes['place.changeChoice.show.tone'] !== 'true',
                },
                {
                  placeholder: __(T.fields.made_for),
                  name: 'madeFor',
                  type: 'select',
                  options: madeFors,
                  config: { value: 'code', label: 'description', secondaryLabel: 'code' },
                  hide:
                    AppStore.loggedUser?.place?.attributes &&
                    AppStore.loggedUser?.place?.attributes['place.changeChoice.show.madeFor'] !== 'true',
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
