import React, { Component } from 'react'
import { List, Input, Page, Icons, Box, Button, Spacer, Select, CustomDdtCountersRow } from 'components'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import { getMatchParams, navigate } from 'shared/router'
import InboundShipments from 'api/InboundShipments'
import { T, __ } from 'translations/i18n'
import { Ddt } from 'api/types'
import { showToast, uniqueBy } from 'shared/utils'

interface State {
  data: Ddt[]
  query: string
  originFilter?: Ddt
  origins?: Ddt[]
  loading?: boolean
}

export default class CustomStesiDdtInbound extends Component<{}, State> {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    data: [],
    query: '',
    loading: true,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    try {
      const data = await InboundShipments.searchDdtCounters({
        shippingState: 'READY_TO_RECEIVE',
      })
      if (this.operation.inboundMode === 'shipment') {
        //GROUP BY FOR SHIPMENT MODE
        this.setState({
          loading: false,
          data: data ?? [],
          origins: uniqueBy(data ?? [], 'originPlace.code'),
        })
      } else {
        this.setState({ loading: false, data: data ?? [] })
      }
    } catch (err) {
      this.setState({ loading: false, data: [] })
      showToast({
        title: __(T.error.error),
        description: err?.message ?? __(T.error.generic_error),
        status: 'error',
      })
    }
  }

  onRowClick = (itm: Ddt) => {
    if (this.operation.inboundMode === 'shipment') {
      const state = {
        shipments: itm.shipmentCodes,
      }
      navigate(
        '/inbound/stesi/shipments/:configCode/reading/:ddtCode',
        {
          configCode: this.operation?.code,
          ddtCode: encodeURIComponent(itm.ddt),
        },
        { state }
      )
    }
  }

  onInputEnter = (input: string) => {
    const { data } = this.state
    this.setState({ query: input })
    const filtered = data.filter(this.filterResults)

    if (filtered.length === 1) {
      this.onRowClick(filtered[0])
    }
  }

  filterResults = (result: Ddt) =>
    result.ddt?.toLowerCase().includes(this.state.query.toLowerCase()) &&
    ((this.state.originFilter && result.originPlace.code === this.state.originFilter?.originPlace.code) ||
      !this.state.originFilter)

  render() {
    const { data, loading, origins } = this.state
    const filtered = data.filter(this.filterResults)
    return (
      <Page title={`${this.operation?.description ?? 'Inbound'}`} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Page.TopBar>
            <Input
              startFocus
              style={{ width: 400 }}
              image={<Icons.Barcode />}
              placeholder={__(T.placeholder.search_ddt)}
              onChange={(query) => this.setState({ query })}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Select
              transparent
              borderColor="transparent"
              containerStyle={{ width: 250 }}
              onSelect={(itm) => this.setState({ originFilter: itm })}
              options={origins ?? []}
              placeholder={__(T.misc.origin)}
              config={{
                value: 'originPlace.code',
                label: 'originPlace.description',
                secondaryLabel: 'originPlace.code',
              }}
            />
            <Box flex />
            <Button title={__(T.misc.refresh)} onClick={this.fetchData} />
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.misc.ddt_to_receive)} (${filtered.length})`}
            renderItem={(itm) => (
              <CustomDdtCountersRow operation={this.operation} type="inbound" ddt={itm} onRowClick={this.onRowClick} />
            )}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
