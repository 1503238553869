import React, { Component } from 'react'
import { CustomShippingNotice, ShipmentParcel } from 'api/types'
import { __, T } from 'translations/i18n'
import { List, PackingListStatus } from 'components'
import { getChoiceDescription } from 'shared/customUtils'
import styled from '@emotion/styled'
import Box from './Box'

interface Props {
  notice: CustomShippingNotice
  openDetailParcel?: (parcel: ShipmentParcel) => void
  enableCheckbox?: boolean
  checkboxLabel?: string
  checkboxHandleChange?: (event) => void
  isChecked?: boolean
  madeFor?: string
}

export default class PackingListSectionHeader extends Component<Props> {
  getOperation = (shippingNotice: CustomShippingNotice) => {
    return shippingNotice.parcels.find((e) => e !== undefined)?.shippingOperationParcels.find((e) => e !== undefined)
      ?.operationType
  }

  render() {
    const { notice, enableCheckbox, checkboxLabel, checkboxHandleChange, isChecked } = this.props
    return (
      <Box vcenter row flex pr={20}>
        {notice.packingListId && (
          <List.Cell
            style={{ width: 100 }}
            label={__(T.misc.packing_list_id)}
            value={notice.packingListId.toString()}
          />
        )}
        <List.Cell
          style={{ width: 300 }}
          label={__(T.misc.shipment)}
          value={notice?.tom ?? 'No shipment code provided'}
        />
        <List.Cell
          style={{ width: 200 }}
          label={__(T.misc.operation)}
          value={notice.operationType ?? this.getOperation(notice)}
        />
        <List.Cell
          style={{ width: 150 }}
          label={__(T.misc.choiche)}
          value={getChoiceDescription(notice.choice) ?? '---'}
        />
        <List.Cell
          style={{ width: 150 }}
          label={__(T.misc.madeFor)}
          value={notice?.madeFor}
        />
        <List.Cell
          style={{ width: 150 }}
          label={__(T.misc.destination)}
          value={notice?.destinationPlace?.description || notice?.destinationPlace?.code}
        />
        <Box style={{ width: 200 }}>
          <Box height={24} style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>
            {__(T.misc.status)}
          </Box>
          <PackingListStatus width={170} status={notice.packingListState} />
        </Box>
        <SmallCounter total={notice.totalParcels}>{`${notice.totalParcels}`}</SmallCounter>

        {enableCheckbox && checkboxHandleChange && (
          <Box flex mr={10} style={{ alignItems: 'center' }}>
            {checkboxLabel && (
              <Box height={24} style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>
                {checkboxLabel}
              </Box>
            )}
            <Box flex width={100} style={{ alignItems: 'center' }}>
              <CheckBoxStyle
                type="checkbox"
                value={notice.packingListId}
                onChange={(event: any) => {
                  checkboxHandleChange(event)
                }}
                checked={isChecked}
              />
            </Box>
          </Box>
        )}
      </Box>
    )
  }
}

const SmallCounter = styled(Box)<{
  total?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0px 20px;
  background-color: ${({ total = 0 }) => {
    if (total === 0) return '#FFFFFF'
    if (total > 0) return '#75EBA8'
    return '#75EBA8'
  }};
`
const CheckBoxStyle = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  transform: scale(1.5);
  :focus {
    outline: black;
  }
`
