import React, { Component } from 'react'
import { Ddt } from 'api/types'
import { Box, List } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import { InboundConfig, OutboundConfig } from 'shared/RemoteConfig'

interface Props {
  ddt: Ddt
  onRowClick: (ddt: Ddt) => void
  type: 'inbound' | 'outbound'
  operation?: OutboundConfig | InboundConfig
}
export default class CustomDdtCountersRow extends Component<Props> {
  render() {
    const { onRowClick, ddt, type, operation } = this.props
    const hasChecklist = operation?.hasChecklist === 'yes'

    return (
      <RowItem onClick={() => onRowClick(ddt)}>
        <List.Cell style={{ width: 240 }} label={__(T.misc.ddt_code)} value={ddt.ddt} />
        <List.Cell style={{ width: 240 }} label={__(T.misc.year)} value={ddt.year} />
        {type === 'inbound' && hasChecklist && (
          <List.Cell
            style={{ width: 240 }}
            label={__(T.misc.origin)}
            value={ddt?.originPlace?.description || ddt?.originPlace?.code}
          />
        )}
        <List.Cell style={{ maxWidth: 240 }} label={__(T.misc.expected_parcels)} value={`${ddt.expectedParcels}`} />
        <List.Cell style={{ maxWidth: 240 }} label={__(T.misc.confirmed_parcels)} value={`${ddt.confirmedParcels}`} />
        <List.Cell style={{ width: 100 }} label={__(T.misc.items)} value={`${ddt.expectedItems}`} />
      </RowItem>
    )
  }
}

const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`
