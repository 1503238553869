import React from 'react'
import { Button } from 'components'

export default function CustomFileInput({
  onChange,
  buttonName,
  loading
}: {
  onChange: (file, fileName: any) => void
  buttonName: string
  loading: boolean
}) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]
    const fileUploadedName = fileUploaded ? fileUploaded.name : ''
    onChange(fileUploaded, fileUploadedName)
  }
  return (
    <>
      <Button loading={loading} onClick={() => handleClick()}>{buttonName}</Button>
      <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }}/>
    </>
  )
}
