import { QualityControl, QualityControlResponse } from 'api/types'
import { Box, Spacer, KeyValueRow } from 'components'
import React from 'react'
import { __, T } from 'translations/i18n'
import Button from 'components/Button'

export default function QualityControlModal({
  data,
  qualityControlType,
  onConfirm,
  type,
}: {
  data: QualityControlResponse
  qualityControlType: QualityControl
  onConfirm: () => void
    type: string
}) {
  return (
    <Box>
      {qualityControlType === 'COLOR_LOT_CHECK' && (
        <Box row>
          <KeyValueRow
            style={{ flex: 1 }}
            variant="grey"
            label={__(T.fields.variant_style)}
            value={data.variantStyle}
          />
          <Spacer />
          <KeyValueRow
            style={{ flex: 1 }}
            variant="grey"
            label={__(T.fields.variant_color)}
            value={data.variantColor}
          />
        </Box>
      )}
      <Spacer />
      <Box row>
        <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.fields.tom)} value={data.tom} />
        <Spacer />
          {type === 'Topfly' && (
        <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.ddt_code)} value={data.ddt} />
          )}
          {type === 'Stireria' && (
              <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.packing_list_id)} value={data.idPL} />
          )}
      </Box>
      <Spacer />
      <Box row>
          {type === 'Topfly' && (
        <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.ddt_date)} value={data.ddtDate} />
          )}

          {type === 'Stireria' && (
              <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.packing_list_date)} value={data.datePL} />
          )}
        <Spacer />
        <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.fields.supplier)} value={data.supplier} />
      </Box>
      <Spacer />
      <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.fields.total_lot_items)} value={data.totLotItems} />
      <Spacer />
      <KeyValueRow
        style={{ flex: 1 }}
        variant="grey"
        label={__(T.fields.total_lot_items_check)}
        value={data.totItemsNotChecked}
      />
      <Spacer />
      <KeyValueRow
        style={{ flex: 1 }}
        variant="grey"
        label={__(T.fields.total_lot_items_update)}
        value={data.totItemsToUpdate}
      />
      <Spacer />
      <Box center row mt={15}>
        <Button title={__(T.misc.confirm)} onClick={onConfirm} />
      </Box>
    </Box>
  )
}
