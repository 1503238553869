import { Choice } from 'api/types'
import { T, __ } from 'translations/i18n'
import { showToast } from './utils'
import Choices from 'api/Choices'

export async function getChoiceOptions(forQC?: boolean, forQCStireria?: boolean) {
  const allChoices = (await fetchChoices()) ?? []
  let optionsList = allChoices.flatMap((choice) => {
    return [{ label: getChoiceDescription(choice.code) ?? choice.code, value: choice.code }]
  })
  if (!forQC) {
    optionsList = optionsList.filter((o) => 'failed' !== o.value)
  }
  if (forQC && forQCStireria) {
    optionsList = optionsList.filter((o) => isFirstChoiceOrFailed(o.value, allChoices))
    optionsList = optionsList.filter((o) => {
      if (o.value === 'prima') {
        o.label = 'CQ OK'
      }
      return o
    })
  }
  return optionsList
}

export function getChoiceDescription(choice?: string | Choice) {
  if (!choice) return undefined

  const choiceCode = typeof choice === 'string' ? choice.toLowerCase() : choice.code

  if (!choiceCode) return undefined

  switch (choiceCode) {
    case 'prima':
      return __(T.labels.grade_a)
    case 'primetta':
      return __(T.labels.grade_b)
    case 'seconda':
      return __(T.labels.grade_c)
    case 'fallato':
      return __(T.labels.grade_d)
    case 'japan':
      return __(T.labels.japan)
    case 'failed':
      return __(T.labels.failed)
    default:
      return choiceCode
  }
}

export function isFirstChoiceOrFailed(choiceCode: string, choices: Choice[]) {
  const choicesToExclude = ['prima', 'failed']

  const choice = choices.find((c) => c.code === choiceCode)
  if (!choice) {
    throw new Error(__(T.error.cannot_find_desired_choice))
  }
  return choicesToExclude.includes(choice.code)
}

export function isFirstChoice(choice?: string | Choice) {
  if (!choice) return undefined

  const choiceCode = typeof choice === 'string' ? choice.toLowerCase() : choice.code

  switch (choiceCode) {
    case 'prima':
      return true
    case 'japan':
      return true
    default:
      return false
  }
}

export function decodeMadeFor(madefor: string | undefined) {
  if (!madefor) return '---'
  switch (madefor) {
    case 'Undefined':
      return __(T.misc.undefined)
      break
    case 'S':
      return __(T.misc.america)
      break
    case 'H':
      return __(T.misc.quebec)
      break
    case 'U':
      return __(T.misc.utah)
      break
    default:
      return madefor
  }
}

export async function fetchChoices() {
  try {
    return (await Choices.search<Choice>({ limit: 500 })) ?? []
  } catch (err) {
    showToast({
      title: __(T.error.error),
      description: __(T.error.cannot_fetch_choices),
      status: 'error',
    })
  }
}

export function getConcatCodeDescription(object?: any) {
  if (!object || !object?.code) return undefined

  const code = object.code
  const description = object?.description

  return `${code}${description ? ` (${description})` : ''}`
}
