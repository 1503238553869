import React, { Component } from 'react'
import { Input, Page, Box, Icons, List, Spacer, Select, Button } from 'components'
import { getLocationState, getMatchParams, navigate } from 'shared/router'
import { ShipmentParcel, PackingListFilter, CustomShippingNotice, PackingListRequest } from 'api/types'
import { showToast, uniqueBy } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import PackingLists from 'api/PackingLists'
import PackingListRow from 'components/PackingListRow'
import AppDatas from "../../api/AppDatas";

interface State {
  parcel?: ShipmentParcel
  filter: string
  loading: boolean
  shippingNotices: CustomShippingNotice[]
  data: CustomShippingNotice[]
  query: string
  destinationFilter?: CustomShippingNotice
  destinations?: CustomShippingNotice[]
  enablePlState79?: string
}

export default class PackingListDetail extends Component {
  state: State = {
    filter: '',
    loading: true,
    shippingNotices: [],
    data: [],
    query: '',
    enablePlState79: undefined,
  }

  packingListFilter: PackingListFilter = {
    shippingCodes: [],
    parcelCodes: [],
    parcelStates: [],
    originPlaces: [],
    destinationPlaces: [],
    packingListStates: ['WAIT_FOR_DDT'],
    operationId: '',
    parcelsData: false
  }

  packingListRequest: PackingListRequest = {}

  async componentDidMount() {
    const locationState = getLocationState(this.props)
    await this.fetchAppDatas()

    this.packingListRequest = {
      destinationPlace: locationState?.submittedDestination,
      parcelCodes: locationState?.submittedParcels,
    }
    try {
      const detailResponse = await PackingLists.searchCounters(this.packingListFilter)
      this.setState({
        loading: false,
        shippingNotices: detailResponse ? detailResponse : undefined,
        destinations: this.getDestinations(detailResponse),
      })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  navigateBack = () => navigate('/', { configCode: getMatchParams(this.props).configCode })

  onInputEnter = (input: string) => {
    this.setState({ query: input })
  }

  filterResults = (result: CustomShippingNotice) => {
    const { destinationFilter, query } = this.state
    return (result?.packingListId?.toString().includes(query) ||
        result?.tom?.toLowerCase().includes(query.toLowerCase())) &&
    ((destinationFilter &&
            result.destinationPlace.code === destinationFilter?.destinationPlace?.code) ||
        !destinationFilter)
  }

  getDestinations = (data) =>
    uniqueBy<CustomShippingNotice>(data, 'destinationPlace.code')
      .filter((d) => d.destinationPlace?.code)
      .sort((a, b) => a.destinationPlace!.code.localeCompare(b.destinationPlace!.code))

  onRowClick = (shippingCode: string) => {
    navigate('/packinglist/row-detail/:shippingCode', {
      shippingCode: shippingCode,
    })
  }

  sortingFunction = (a: CustomShippingNotice, b: CustomShippingNotice) => {
    return b.packingListId - a.packingListId
  }

  fetchAppDatas = async () => {
    const [enableCQ79] = await Promise.all([AppDatas.getValue(__(T.appSystem.enableCQ79))])
    this.setState({enablePlState79: enableCQ79 ?? false})
    console.log(this.state.enablePlState79)
    this.packingListFilter.packingListStates = this.state.enablePlState79 == 'false' ? ['WAIT_FOR_DDT'] : ['WAIT_FOR_DDT','WAIT_FOR_CQ','CQ_IN_PROGRESS','CQ_ERROR']
  }

  render() {
    const { loading, shippingNotices, destinations } = this.state
    const filtered = shippingNotices.filter(this.filterResults).sort(this.sortingFunction)
    return (
      <Page
        loading={loading}
        title={`${__(T.titles.packing_list)}`}
        onBackPress={() => navigate('/', { configCode: getMatchParams(this.props).configCode })}
        header={{}}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.placeholder.search_shipment_pl)}
              onChange={(query) => this.setState({ query })}
              image={<Icons.Barcode />}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Select
              containerStyle={{ width: 250 }}
              borderColor="transparent"
              transparent
              onSelect={(itm) => this.setState({ destinationFilter: itm })}
              options={destinations ?? []}
              placeholder={__(T.misc.destination)}
              config={{
                value: 'destinationPlace.code',
                label: 'destinationPlace.description',
                secondaryLabel: 'destinationPlace.code',
              }}
            />
            <Box flex />
            <Button loading={loading} title={__(T.misc.new_packing_list)} onClick={() => navigate('/packinglist')} />
          </Page.TopBar>

          <List
            loading={loading}
            title={`${__(T.titles.last_packing_lists)} (${filtered.length})`}
            renderItem={(notice) =>
                <PackingListRow shippingNotice={notice} onRowClick={this.onRowClick} showPLDestination={true} showPLNote={true}/>
            }
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
