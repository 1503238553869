import CustomChangeCode from 'api/CustomChangeCode'
import AppStore from 'AppStore'
import { AntennaButton, Box, Button, Icons, Input, List, Page, Spacer } from 'components'
import React, { Component } from 'react'
import { navigate } from 'shared/router'
import { showToast, sleep } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import { ChangeSkuRequest, RpacIdentifier, TmrItem } from 'api/types'
import { EncodingPageParams } from 'pages/Encoding/Encoding'
import RfidReader from 'shared/RfidReader'
import Encodings from 'api/Encodings'

interface State {
  data: ChangeSkuRequest[]
  query: string
  loading: boolean
  item?: TmrItem
}

export default class CustomChangeCodeList extends Component<{}, State> {
  state: State = { query: '', data: [], loading: true }

  async componentDidMount() {
    try {
      if ((!AppStore.workstations || AppStore.workstations.length === 0) && !AppStore.emulation) {
        navigate('/login')
        showToast({ title: __(T.error.error), description: __(T.messages.no_workstations_found), status: 'error' })
      }

      const data = await CustomChangeCode.getChangeSkuRequest()
      this.setState({ data, loading: false })
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
      this.setState({ loading: false })
    }
  }

  renderRow = (item: ChangeSkuRequest) => {
    return (
      <List.Row>
        <List.Cell
          label={__(T.misc.certilogo)}
          value={item.previousVersion.itemIdentifiers.find((idf) => idf.identifierType === 'CertilogoIdentifier')?.code}
        />
        <List.Cell label={__(T.misc.product)} value={item.previousVersion.product.code} />
        <List.Cell label={__(T.misc.description)} value={item.previousVersion.product.description} />
        <List.Cell label={__(T.misc.requested_by)} value={item.username} />
      </List.Row>
    )
  }

  navigateNewRequest = () => {
    navigate('/items-list/:operationCode', { operationCode: 'change-code-request' })
  }

  navigateUpdateRequest = () => {
    navigate('/items-list/:operationCode', { operationCode: 'change-code-update' })
  }

  onRowClick = (changeSkuRequest: ChangeSkuRequest) => {
    const state: EncodingPageParams = {
      data: { itemId: changeSkuRequest.currentItem },
      validatedEpc: this.getValidatedEpc(changeSkuRequest),
      navigateBack: true,
      hideOptions: true,
      encodeFn: 'CustomChangeCode',
      title: __(T.titles.change_code),
      autoBackOnEncoded: true,
      disableValidationCheck: ['ENCODING_ERROR.IDENTIFIER_ALREADY_ASSOCIATED'],
      disableWrite: true,
      disableQuantityVerify: true,
    }
    navigate('/change-code/encode', {}, { state })
  }

  onInputEnter = (input: string) => {
    const { data } = this.state
    this.setState({ query: input })
    const filtered = data.filter(this.filterResults)

    if (filtered.length === 1) {
      this.onRowClick(filtered[0])
    }
  }

  onItemDecoded = async (item) => {
    try {
      this.setState({ loading: true })
      if (item.id && item.itemIdentifiers) {
        await RfidReader.stop()
        const data = await CustomChangeCode.getChangeSkuRequest([item.itemIdentifiers[0].code])
        this.isRequestPending(item) && this.setState({ data, loading: false, item }, () => {
          if (this.state.data && this.state.data.length === 1) this.onRowClick(this.state.data[0])
        })
      } else if (item.epc) {
        //read tid
        RfidReader.stop()
        await sleep(1000)
        const risp = await RfidReader.readTid(item.epc)

        if(!risp || !risp.data || !risp.data.value){
          this.clearReading()
          throw new Error(__(T.error.unable_to_get_tid))
        }

        const rpacIdentifier : RpacIdentifier = await Encodings.getRpacTag(risp?.data.value)
        if(rpacIdentifier.epc){
          const data = await CustomChangeCode.getChangeSkuRequest([rpacIdentifier.epc])
          this.isRequestPending(item) && this.setState({ data, loading: false, item }, () => {
            if (this.state.data && this.state.data.length === 1) this.onRowClick(this.state.data[0])
          })
        }
      }
    } catch (error) {
      this.clearReading()
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }
    this.setState({ loading: false })
  }

  getValidatedEpc = (changeSkuRequest: ChangeSkuRequest) => {
    const identifiers = changeSkuRequest.previousVersion.itemIdentifiers
    return identifiers.find(identifier => identifier.identifierType === 'UHFTag')?.code
  }

  isRequestPending = (item : any) => {

    const isPending = item?.changeStatus === 'CHANGE_PENDING'
    !isPending && showToast({ title: __(T.error.error), description: __(T.error.item_is_not_in_pending), status: 'error'})
    return isPending

  }

  clear = async () => {
    try {
      RfidReader.clear()
      this.setState({ item: undefined })
      const data = await CustomChangeCode.getChangeSkuRequest()
      this.setState({ data, loading: false })
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }
  }

  clearReading = async () => {
    try {
      RfidReader.clear()
      this.setState({ item: undefined, loading: false })
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }
  }

  filterResults = (result: ChangeSkuRequest) =>
    result.previousVersion.itemIdentifiers
      .find((idf) => idf.identifierType === 'CertilogoIdentifier')
      ?.code.toLowerCase()
      .includes(this.state.query.toLowerCase())

  render() {
    const { loading, data, item } = this.state
    const filtered = data.filter(this.filterResults)
    const isUpdateRequestEnabled = AppStore.loggedUser?.place.attributes?.['changeCode.UpdateRequestEnabled']
    return (
      <Page
        title={__(T.titles.change_code)}
        enableEmulation
        onBackPress={() => {
          navigate('/')
        }}
      >
        <Page.Content bgGrey>
          <Page.TopBar>
            <Input
              startFocus
              style={{ width: 350 }}
              image={<Icons.Barcode />}
              placeholder={__(T.misc.certilogo)}
              onChange={(query) => this.setState({ query })}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Box flex />
            <AntennaButton
              onClear={this.clear}
              onItemDecoded={this.onItemDecoded}
              hideClear={!item}
              style={{ marginRight: 15 }}
            />
            <Button title={__(T.misc.request_code_change)} onClick={this.navigateNewRequest} size="small" />
            {(AppStore.loggedUser?.admin || isUpdateRequestEnabled) && (
              <Button
                title={__(T.misc.update_request)}
                onClick={this.navigateUpdateRequest}
                style={{ marginLeft: 15 }}
                size="small"
              />
            )}
          </Page.TopBar>
          <List
            title={__(T.misc.pending_request, { count: data?.length ?? 0 })}
            loading={loading}
            renderItem={this.renderRow}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
