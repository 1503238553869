import TransferToZone from 'api/TransferToZone'
import { TmrItem } from 'api/types'
import { navigate } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export async function customTransferToZone(items: TmrItem[], attributes: any) {
  if (!items || items.length === 0) throw new Error(__(T.messages.list_empty))
  const confirmed = await askUserConfirmation(__(T.misc.confirm), __(T.confirm.confirm_ttz))
  if (!confirmed) {
    return
  }
  const requestAttributes: any = {}
  if (attributes.tone != null) requestAttributes.tone = attributes.tone
  if (attributes.madeFor) requestAttributes.madeFor = attributes.madeFor

  const request = {
    destinationZone: { id: attributes?.destination.id },
    items: items
      .filter((item) => item.id)
      .map((item) => {
        return { id: item.id }
      }),
    attributes: requestAttributes,
  }
  await TransferToZone.customTransferToZone(request)
  showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
  navigate('/transfer-to-zone')
}
