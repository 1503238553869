import { Input, Select, Box, InputDate } from 'components'
import React, { Component } from 'react'
import styled from '@emotion/styled'
import Button from './Button'

interface Props {
  initialValues?: any
  schema: Schema[]
  onSubmit: (data: any) => void
  submitText?: string
  style?: React.CSSProperties
  width?: number
  disabled?: boolean
}

interface State {
  data: any
  loading: boolean
}

interface Schema {
  label?: any
  name: string
  placeholder?: string
  type?: 'textarea' | 'checkbox' | 'input' | 'select' | 'password' | 'date' | 'datetime'
  required?: boolean
  disabled?: boolean
  options?: any[]
  config?: { value: string; label: string; secondaryLabel?: string }
  focus?: boolean
  image?: any
  hide?: boolean
  onChange?: (newValue: any) => void
}

/**
 * Example
 * schema={[
        { placeholder: __(T.misc.username), name: 'username', required: true },
        { placeholder: __(T.misc.password), name: 'password', type: 'password', required: true },
    ]}
 */
export default class Form extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    initialValues: {},
  }

  constructor(props: Props) {
    super(props)
    this.state = { data: { ...this.props.initialValues }, loading: false }
  }

  handleSubmit = async (event: any) => {
    const { data } = this.state
    const { onSubmit } = this.props
    event.preventDefault()
    if (!this.state.loading) {
      this.setState({ loading: true })
      await onSubmit(JSON.parse(JSON.stringify(data)))
      this.setState({ loading: false })
    }
  }

  handleChange = (key: string, value: string, schema?: Schema) => {
    const { data } = this.state

    data[key] = value
    this.setState(data)
    schema?.onChange && schema.onChange(value)
  }

  renderInput = (s: Schema, index: number) => {
    const { initialValues, disabled } = this.props
    const { data } = this.state
    switch (s.type) {
      case 'textarea':
        return (
          <>
            <Label key={`label${index.toString()}`} htmlFor={s.name}>
              {s.label}
            </Label>
            {/*@ts-ignore*/}
            <TextArea
              key={`input${index.toString()}`}
              defaultValue={initialValues[s.name]}
              onChange={(event) => this.handleChange(s.name, event.target.value)}
              value={data[s.name]}
              required={s.required}
              disabled={s.disabled ?? disabled}
              id={s.name}
              placeholder={s.placeholder}
            />
          </>
        )
      case 'checkbox':
        return (
          <Box key={index.toString()}>
            <InputFormStyle
              defaultValue={initialValues[s.name]}
              style={{ padding: 10, verticalAlign: 'middle' }}
              required={s.required}
              disabled={s.disabled ?? disabled}
              onChange={(event: any) => this.handleChange(s.name, event.target.checked)}
              value={data[s.name]}
              id={s.name}
              type="checkbox"
              placeholder={s.placeholder}
            />
            <Label style={{ marginLeft: 20, color: 'grey' }} htmlFor={s.name}>
              {s.label}
            </Label>
          </Box>
        )
      case 'select':
        return (
          <>
            <Label key={`label${index.toString()}`} htmlFor={s.name}>
              {s.label}
            </Label>
            <Select
              onSelect={(itm) => this.handleChange(s.name, itm, s)}
              placeholder={s.placeholder}
              disabled={s.disabled ?? disabled}
              options={s.options ?? []}
              config={s.config ?? { value: 'id', label: 'description' }}
              defaultValue={initialValues[s.name]}
            />
          </>
        )
      case 'date':
      case 'datetime':
        return (
          <InputDate
            index={index}
            name={s.name}
            label={s.label}
            value={data[s.name] ?? initialValues[s.name]}
            handleChange={(name, value) => this.handleChange(name, value, s)}
            type={s.type}
          />
        )
      default:
        return (
          <>
            <Input
              key={`input${index.toString()}`}
              required={s.required ?? false}
              onChange={(value) => this.handleChange(s.name, value)}
              value={data[s.name] ?? initialValues[s.name]}
              id={s.name}
              type={s.type || 'text'}
              placeholder={s.placeholder ?? ''}
              startFocus={s.focus}
              image={s.image}
              label={s.label}
              disabled={s.disabled ?? disabled}
            />
          </>
        )
    }
  }

  render() {
    const { schema, submitText, style, width } = this.props
    return (
      <FormStyle width={width} style={style} onSubmit={this.handleSubmit}>
        {schema
          .filter((s) => !s.hide)
          .map((s, index) => (
            <Box flex={0} key={index}>
              {this.renderInput(s, index)}
              <Spacer />
            </Box>
          ))}
        {submitText && (
          <Button style={{ flex: 'none' }} loading={this.state.loading} type="submit">
            {submitText}
          </Button>
        )}
      </FormStyle>
    )
  }
}

const FormStyle = styled.form<{ width?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`

const Spacer = styled.div`
  height: 20px;
`

const Label = styled.label`
  font-weight: 600;
  font-size: 10pt;
`

const InputFormStyle = styled.input`
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  :focus {
    outline: black;
  }
`

const TextArea = styled.textarea`
  border-width: 1px;
  border-color: black;
  padding-left: 10px;
  height: 100px;
  font-size: 14px;
`
