import React, { Component } from 'react'
import {
  Page,
  Box,
  Spacer,
  Button,
  InputDate,
  Input,
  ShippingDdtModal,
  CustomFileInput, List, EmptyList,
} from 'components'
import { getMatchParams, navigate } from 'shared/router'
import {CustomShippingNotice, ShippingDdt} from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import styled from '@emotion/styled'
import { createBrowserHistory } from 'history'
import DdtApi from 'api/Ddt'
import PackingListRow from "../../components/PackingListRow";

interface State {
  loading: boolean
  shippingddt?: ShippingDdt
  ddtCode: string
  ddtDate: any
  uploadFile?: any
  uploadFileName?: string
  isModalOpen: boolean
  isDownloading: boolean
}

export const history = createBrowserHistory()

export function navigateBack(): void {
  history.goBack()
}

export default class DdtUpdate extends Component {
  state: State = {
    loading: true,
    ddtCode: '',
    ddtDate: '',
    isModalOpen: false,
    isDownloading: false
  }

  async componentDidMount() {
    const itemId = getMatchParams(this.props).itemId

    DdtApi.searchDetails({
      ids: [itemId],
      ddtCodes: [],
      ddtStates: ['DRAFT'],
      originPlaces: [],
      destinationPlaces: [],
    }).then((response) => {
      if (!response || response.length === 0) {
        throw new Error(__(T.error.ddt_required))
      }
      this.setState({shippingddt: response[0], loading: false})
    }).catch((err) => {
        showToast({
          title: __(T.error.error),
          description: err?.message ?? __(T.error.error),
          status: 'error',
        })
        navigateBack()
      })
  }

  downloadPdf = async (shippingddt: ShippingDdt | undefined, draft?: boolean) => {
    try {
      const response = await (draft ? DdtApi.exportPdfDraft(shippingddt!.id) : DdtApi.exportPdf(shippingddt!.id))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.download = shippingddt?.ddt?.concat('.pdf') ?? shippingddt?.idDdt?.concat('.pdf') ?? 'DDT.pdf'
      link.href = window.URL.createObjectURL(response)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? __(T.error.error),
        status: 'error',
      })
    }
    this.setState({loading: false, isModalOpen: false, isDownloading: false })
  }

  updateDdt = (shippingddt: ShippingDdt, draft?: boolean) => {
    this.setState({loading: true, isModalOpen: true, isDownloading: true })
    this.submitForm(shippingddt.idDdt)
    DdtApi.update(shippingddt)
      .then(async (response) => {
        await this.downloadPdf(shippingddt, draft)
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.operation_success),
          status: 'success',
        })
        navigate('/shippingddt/list')
      })
      .catch((error) => {
        showToast({
          title: __(T.error.error),
          description: error?.message ?? 'Generic error',
          status: 'error',
        })
      }).finally(() => this.setState({ loading: false, isModalOpen: false, isDownloading: false }))
  }

  submitForm = (ddtId) => {
    const { uploadFile } = this.state

    if (!ddtId) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.id_ddt_required),
        status: 'error',
      })
      return
    }

    const formData = new FormData()
    formData.append('uploadFile', uploadFile)

    DdtApi.uploadPdf(formData, ddtId)
      .catch((error) => {
        showToast({
          title: __(T.error.error),
          description: error?.message ?? 'Generic error',
          status: 'error',
        })
      })
      .finally(() => this.setState({ uploadFile: undefined }))
  }

  confirmModal = (shippingddt) => {
    this.updateDdt(shippingddt)
  }

  onRowClick = (shippingCode: string) => {
    navigate('/shippingddt/pl-row-detail/:shippingCode', {
      shippingCode: shippingCode
    })
  }

  onModalClose = () => {
    this.setState({ isModalOpen: false })
  }

  sortingFunction = (a: CustomShippingNotice, b: CustomShippingNotice) => {
    return b.packingListId - a.packingListId
  }

  render() {
    const { loading, shippingddt, ddtCode, ddtDate, uploadFile, uploadFileName, isModalOpen, isDownloading } = this.state
    return (
      <Page
        loading={loading}
        title={`${__(T.titles.shipping_ddt) ?? 'Shipping DDT'} / ${__(T.misc.send_ddt)}`}
        onBackPress={() => navigateBack()}
        header={{
          details: [
            {
              label: __(T.misc.ddt_origin),
              value: shippingddt?.originPlace.description || shippingddt?.originPlace.code,
            },
            {
              label: __(T.misc.ddt_destination),
              value: shippingddt?.destinationPlace.description || shippingddt?.destinationPlace.code,
            },
            {
              label: __(T.misc.ddt_state),
              value: shippingddt?.ddtState,
            },
          ],
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Spacer />
            <SectionTitle>{__(T.misc.confirm_ddt)}</SectionTitle>
          </Page.TopBar>
          <Box row>
            <Input startFocus disabled={loading} label={__(T.misc.ddt_code)} onChange={(ddtCode) => this.setState({ ddtCode })} />
            <Spacer />
            <InputDate
              loading={loading}
              index={0}
              name={'ddtDate'}
              label={__(T.misc.ddt_date)}
              value={ddtDate ?? ''}
              handleChange={(name, value) => this.setState({ ddtDate: value  })}
              type={'date'}
            />
            <Spacer />
            <CustomFileInput
              onChange={(uploadFile, uploadFileName) => this.setState({ uploadFile, uploadFileName })}
              buttonName={__(T.misc.choose_pdf)}
              loading={loading}
            />
            <Spacer />
            <FileNameTag>{uploadFileName}</FileNameTag>
            <Spacer />
            <Box flex />
            <Button
              style={{ flex: 'none' }}
              loading={loading}
              type="submit"
              onClick={() => {
                try {
                  if (!ddtCode) throw new Error(__(T.error.ddt_code_required))
                  if (!ddtDate) throw new Error(__(T.error.ddt_date_required))
                  if (!uploadFile) throw new Error(__(T.error.ddt_pdf_required))
                  shippingddt!.ddt = ddtCode
                  ddtDate.setMinutes(ddtDate.getMinutes() - ddtDate.getTimezoneOffset())
                  shippingddt!.ddtDate = ddtDate.getTime()
                  this.setState({ isModalOpen: true, shippingddt, isDownloading: false })
                } catch (err) {
                  showToast({
                    title: __(T.error.error),
                    description: err?.message ?? __(T.error.error),
                    status: 'error',
                  })
                }
              }}
            >
              {__(T.misc.confirm)}
            </Button>
            <Spacer />
            <Button
              style={{ flex: 'none' }}
              loading={loading}
              variant={'default'}
              onClick={() => {
                this.setState({loading: true, isModalOpen: true, isDownloading: true })
                this.downloadPdf(shippingddt, true)
              }}
            >
              {__(T.misc.print)}
            </Button>
          </Box>
          <Spacer />
          <Spacer />
          <Spacer />
          <List
              loading={loading}
              title={`${__(T.titles.packing_list)} (${shippingddt?.notices.length})`}
              renderItem={(notice) =>
                  <PackingListRow
                      shippingNotice={notice}
                      onRowClick={this.onRowClick}
                      showPLId={true}
                      showPLNote={true}
                  />
              }
              data={shippingddt?.notices?.sort(this.sortingFunction) ?? []}
          />
          {loading && !shippingddt && <List loading={true} data={[]} renderItem={() => <></>} />}
          {!loading && shippingddt && shippingddt?.notices?.length === 0 && <EmptyList />}
          {shippingddt && (
              <ShippingDdtModal
                  isOpen={isModalOpen}
                  loading={isDownloading}
                  loadingText={__(T.messages.waiting_download_pdf)}
                  data={shippingddt}
                  onClose={this.onModalClose}
                  onConfirm={() => this.confirmModal(shippingddt)}
              />
          )}
        </Page.Content>
      </Page>
    )
  }
}

const SectionTitle = styled.div`
  font-weight: 900;
  font-size: 28px;
  margin-bottom: 10px;
`

const FileNameTag = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  margin: auto;
`
