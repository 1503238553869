import { ApisauceConfig } from 'apisauce'
import api, { responseErrorCheck } from './api'
import TmrBaseResource from './TmrBaseResource'

export default class CustomChangeCode extends TmrBaseResource {
  static endpoint = '/'

  static getChangeSkuRequest(identifiers?: string[], requestConfig?: ApisauceConfig) {
    const data: any = {
      orderBy: 'CREATION_DATE', // CREATION_DATE, LAST_MODIFIED, USER, CODE_CHANGE_DATE, PREVIOUS_PRODUCT, NEXT_PRODUCT
      opened: true,
      limit: 500,
    }
    if (identifiers) data.identifiers = identifiers
    return api
      .post<any>(`/skuChangeRequest/advancedSearch`, data, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static reEncode(data: any, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`/custom/operations/encoding/reencode`, data, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static changeEpc(data: any, virginEpc: any, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`/custom/operations/encoding/changeEpc?virginEpc=${virginEpc}`, data, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
