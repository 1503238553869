import React, { Component } from 'react'
import { AntennaButton, Box, Form, Icons, Page } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { getChoiceOptions, showToast } from 'shared/utils'
import AppStore from 'AppStore'
import {
  CustomTmrItem,
  DestinationPool,
  MadeFor,
  Phase,
  PlaceType,
  ShippingParcelCreateRequest,
  TmrPlace,
} from 'api/types'
import { __, T } from 'translations/i18n'
import Places from 'api/Places'
import RemoteConfig, { EncodingConfig, fakeEncodingOperation, OutboundConfig } from 'shared/RemoteConfig'
import Items from 'api/Items'
import OutboundShipments from 'api/OutboundShipments'
import CustomItems from 'api/CustomItems'
import MadeFors from 'api/MadeFors'
import RfidReader from 'shared/RfidReader'
import CustomShipmentProvider from 'CustomShipmentProvider'

interface State {
  places?: TmrPlace[]
  madeFors?: MadeFor[]
  forceDestinationSelect: boolean
  item?: CustomTmrItem
  madeFor?: MadeFor
  choices: any[]
  destinationType: PlaceType
  destinationTypeChoices: PlaceType[]
  destinationPool: DestinationPool[]
  loadingPlaces: boolean
}
export default class CustomOutboundCreate extends Component<{}> {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  formRef!: any

  state: State = {
    places: [],
    madeFors: [],
    forceDestinationSelect: false,
    choices: [],
    destinationType: 'SUPPLIER',
    destinationTypeChoices: ['CUSTOMER', 'STORE', 'SUPPLIER'],
    destinationPool: [],
    loadingPlaces: false,
  }

  async componentDidMount() {
    if (this?.operation?.isCustomDirectTransfer === 'yes' || this?.operation?.isRepairMode === 'yes') {
      this.fetchPlaces()
    }

    if (
      this?.operation?.isCustomDirectTransfer === 'no' &&
      AppStore.loggedUser?.place?.attributes?.stireria === 'true'
    ) {
      this.fetchMadeFors()
    }
    this.setState({ choices: (await getChoiceOptions()) ?? [], loadingPlaces: false })
  }

  //fetch places if the operation is direct transfer
  fetchPlaces = async (placeTypeToFetch?: string) => {
    const placeType = placeTypeToFetch ?? this.state.destinationType
    try {
      //If there is no place for selected type then try to fetch them
      if (this.getPlacesByChoice(placeType).length === 0) {
        let newPlaces
        if(placeType === 'SUPPLIER') {
          //fetch places
          newPlaces = (await Places.getReadersPlaces(placeType)) ?? []
        }else{
          newPlaces = (await Places.search<TmrPlace>({ limit: 0, placeType: placeType })) ?? []
        }
        //update destinationPool
        this.setState({
          destinationPool: [
            ...this.state.destinationPool,
            {
              type: placeType,
              places: newPlaces,
            },
          ],
        })
      }
      //refresh places
      this.setState({ places: this.getPlacesByChoice(placeType) })
    } catch (err) {
      showToast({ title: __(T.error.error), description: err?.message ?? err, status: 'error' })
    }
  }

  onDestinationTypeChange = async (newValue: any) => {
    if (!newValue || !newValue.id) {
      return
    }
    this.setState({ loadingPlaces: true })
    await this.fetchPlaces(newValue.id)
    this.setState({ loadingPlaces: false })
  }

  getPlacesByChoice = (choice: string): TmrPlace[] => {
    const destinationPool = this.state.destinationPool
    const destRow = destinationPool.filter((destRow) => destRow.type === choice)
    return destRow?.length === 0 ? [] : destRow[0].places
  }

  fetchMadeFors = async () => {
    try {
      this.setState({ madeFors: (await MadeFors.search<MadeFor>({ limit: 500 })) ?? [] })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  submit = async (data) => {
    try {
      const { inputBarcode, inputCertilogo, choice, tubful, destination, parcelCode, replicaOrder, tone } = data

      let productionCode: any
      let code: any

      let phase: Phase | undefined
      let nextPhase: Phase | undefined

      if (this?.operation?.isOutboundStesiReplica === 'yes' || this?.operation?.isOutboundRiassortimentiAX === 'yes') {
        if (!parcelCode) throw new Error(__(T.error.parcel_not_found))

        const request: ShippingParcelCreateRequest = {
          configurationId: this.operation.id,
          parcelCode: parcelCode,
        }

        const response = await OutboundShipments.createParcel(request)
        if (!response || !response?.header.shippingCode || !response?.header.parcelCode)
          throw new Error('No Parcel created')

        const state = {
          shipmentCode: response.header.shippingCode,
          parcelCode: response.header.parcelCode,
          destination: response.header.destinationPlace,
        }
        navigate(
          '/outbound/:configCode/reading/:parcelCode',
          {
            configCode: this.operation?.code,
            parcelCode: state.parcelCode,
          },
          { state: state }
        )
        return
      }

      const isOutboundColliCross = this?.operation?.isOutbound78ColliCross === 'yes'

      const isStandardOutbound =
        this?.operation?.isOutboundStesiReplica === 'no' &&
        this?.operation?.isOutboundRiassortimentiAX === 'no' &&
        this.operation?.isCustomDirectTransfer === 'no'

      const isRepairOutbound = this?.operation?.isRepairMode === 'yes' || this?.operation?.isRepairReturnMode === 'yes'

      const isDirectTransferTopfly = this?.operation?.isCustomDirectTransferTopfly === 'yes'

      if (!isDirectTransferTopfly && !isOutboundColliCross) {
        if (isStandardOutbound && !isRepairOutbound) {
          if (!inputCertilogo) {
            showToast({
              title: __(T.error.error),
              description: __(T.error.certilogo_required),
              status: 'error',
            })
            return
          }
          code = inputCertilogo
        } else {
          const tomRegExp = new RegExp(/TO[MP][0-9]+_?[CR]?[0-9]*$/i)
          productionCode = tomRegExp.test(inputBarcode) ? inputBarcode : undefined
          code = inputBarcode
        }

        if (!productionCode) {
          const encodingOperation = RemoteConfig.getOperationConfig<EncodingConfig>(fakeEncodingOperation)

          // if the user scans a certilogo qrcode eg. http://certilogo.com/qr/006FWY6B5B, select only the code
          let certilogo = code
          if (encodingOperation.identifierMatchRegex) {
            const match = code.match(encodingOperation.identifierMatchRegex) ?? []
            certilogo = match[0] || code
          }
          const resultItem: CustomTmrItem = await Items.decode(certilogo)
          if (resultItem && resultItem.id) {
            this.setState({ madeFor: resultItem.madeFor })
            productionCode = resultItem.productionOrderRow?.order?.code
            phase = resultItem.phase
          }
          //Check season 78
          const isOutbound78 = this.operation.isOutbound78 === 'yes'
          if (isOutbound78) {
            const seasonId = await CustomItems.checkSeason(certilogo)
            if (seasonId != '78') {
              showToast({
                title: __(T.error.error),
                description: __(T.error.seasons_not_78),
                status: 'error',
              })
              return
            }
          }
        }

        if (!productionCode) {
          showToast({
            title: __(T.error.error),
            description: __(T.error.production_order_or_certilogo_required),
            status: 'error',
          })
          return
        }
      }

      if (isOutboundColliCross && !parcelCode) {
        showToast({ title: __(T.error.error), description: __(T.error.parcel_not_found), status: 'error' })
        return
      }

      if (this?.operation?.isCustomDirectTransfer === 'yes' && !destination) {
        showToast({ title: __(T.error.error), description: __(T.error.destination_required), status: 'error' })
        return
      }

      if (this?.operation?.isCustomDirectTransfer === 'no' && !isOutboundColliCross && !choice) {
        showToast({ title: __(T.error.error), description: __(T.error.choice_field_required), status: 'error' })
        return
      }

      if (
        (isStandardOutbound || this.operation?.isRepairReturnMode === 'yes') &&
        this?.operation?.isRepairMode !== 'yes' &&
        !isOutboundColliCross &&
        !tone
      ) {
        showToast({ title: __(T.error.error), description: __(T.error.tone_field_required), status: 'error' })
        return
      }

      if (isDirectTransferTopfly && !replicaOrder) {
        showToast({ title: __(T.error.error), description: __(T.error.topfly_order_required), status: 'error' })
        return
      }

      let placeCode = destination?.code

      if (isOutboundColliCross) {
        const request: ShippingParcelCreateRequest = {
          configurationId: this.operation.id,
          parcelCode: parcelCode,
        }
        const response = await OutboundShipments.createCrossParcel(request)
        if (!response || !response?.header.shippingCode || !response?.header.parcelCode)
          throw new Error('No Parcel created')

        navigate('/outbound/:configCode/detail/:parcelCode', {
          configCode: this.operation?.code,
          parcelCode: response.header.parcelCode,
        })
        return
      }

      if (this?.operation?.isCustomDirectTransfer === 'no' && choice && productionCode && !destination) {
        const placesPhase = await OutboundShipments.destinationChoices(
          choice?.id,
          (productionCode as string).toUpperCase(),
          isRepairOutbound || isStandardOutbound ? this.operation.id : undefined,
          isStandardOutbound && !isRepairOutbound ? phase?.code : undefined
        )
        if (placesPhase.place) {
          const places = placesPhase.place
          if (places.length > 1) {
            showToast({ title: __(T.messages.destination_required), status: 'info' })
            this.setState({ forceDestinationSelect: true, places })
            this.formRef.handleChange(
              'destination',
              places.find((place) => place?.attributes?.secondChoice === 'default')
            )
            return
          }
          placeCode = places[0].code
        }
        nextPhase = placesPhase.phase
      }

      const shipment = await CustomShipmentProvider.generateParcel(
        ((productionCode ?? '') as string).toUpperCase(),
        this.operation!,
        choice?.id ?? choice?.description ?? choice?.value,
        tubful,
        this.state.madeFor?.code,
        placeCode,
        nextPhase?.code,
        replicaOrder,
        tone?.id ?? tone?.description
      )
      if (!shipment?.parcel?.code) {
        showToast({
          title: __(T.error.error),
          description: `Impossibile generare il parcel dalla commessa: ${productionCode ?? replicaOrder}`,
          status: 'error',
        })

        return
      }
      const state = {
        orderCode: productionCode,
        choice: choice?.id ?? choice?.description ?? choice?.value, // Improve this (improving form select)
        tubful: tubful,
        madeFor: this.state.madeFor?.code,
        destination: destination,
        phase: nextPhase,
        replicaOrder: replicaOrder,
        tone: tone?.id ?? tone?.description,
      }
      navigate(
        '/outbound/:configCode/reading/:parcelCode',
        {
          configCode: this.operation?.code,
          parcelCode: shipment?.parcel?.code,
        },
        { state }
      )
    } catch (err) {
      showToast({ title: __(T.error.error), description: err?.message ?? __(T.error.error), status: 'error' })
    }
  }

  onItemDecoded = async (item) => {
    try {
      if (item.id && item.itemIdentifiers) {
        await RfidReader.stop()
        this.setState({ item }, () => {
          const certilogo = item.itemIdentifiers.find((id) => id.identifierType === 'CertilogoIdentifier')
          this.formRef.handleChange('inputCertilogo', certilogo.code)
          this.formRef.handleChange('inputBarcode', certilogo.code)
        })
      }
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }
  }

  clear = async () => {
    try {
      RfidReader.clear()
      this.setState({ item: undefined }, () => {
        this.formRef.handleChange('inputCertilogo', '')
        this.formRef.handleChange('inputBarcode', '')
      })
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }
  }

  onBackPress = () => {
    if (
      this?.operation?.isOutboundStesiReplica === 'yes' ||
      this?.operation?.isOutboundRiassortimentiAX === 'yes' ||
      this.operation.isOutbound78ColliCross === 'yes'
    ) {
      navigate('/')
      return
    }

    navigate(`/outbound/:configCode`, { configCode: this.operation?.code })
  }

  render() {
    const { places, forceDestinationSelect, madeFors, item, choices, destinationTypeChoices } = this.state
    const optionsObj = choices.map((opt) => {
      return {
        id: opt.value,
        description: opt.label,
      }
    })
    const destinationTypeChoicesObj = destinationTypeChoices.map((opt) => {
      return {
        id: opt,
        description: opt,
      }
    })

    const isOutboundStesiReplica = this?.operation?.isOutboundStesiReplica === 'yes'
    const isOutboundRiassortimentiAX = this?.operation?.isOutboundRiassortimentiAX === 'yes'
    const isRepairOutbound = this?.operation?.isRepairMode === 'yes' || this?.operation?.isRepairReturnMode === 'yes'
    const isDirectTransferTopfly = this?.operation?.isCustomDirectTransferTopfly === 'yes'
    const isDirectTransfer = this?.operation?.isCustomDirectTransfer === 'yes'
    const isOutboundColliCross78 = this?.operation?.isOutbound78ColliCross === 'yes'
    const isOnlyRepairOutbound = this?.operation?.isRepairMode === 'yes'
    const isStandardOutbound =
      !isOutboundStesiReplica &&
      !isOutboundRiassortimentiAX &&
      !isOutboundColliCross78 &&
      this?.operation?.isCustomDirectTransfer === 'no'
    const isOutbound78 = this.operation.isOutbound78 === 'yes'
    const isEnabledDirectTransferStoreCustomer =
      AppStore.loggedUser?.attributes?.isEnabledDirectTransferStoreCustomer === 'true'

    const tones = Array.from({ length: 21 }, (_, i) => i).map((tone) => {
      return {
        id: tone,
        description: tone == 0 ? __(T.labels.toneUndefined) : tone + '',
      }
    })

    return (
      <Page title={this.operation?.description ?? 'Outbound'} onBackPress={this.onBackPress} enableEmulation>
        <Box flex center>
          {(isStandardOutbound || isDirectTransfer) && (
            <AntennaButton
              onClear={this.clear}
              onItemDecoded={this.onItemDecoded}
              hideClear={!item}
              style={{ marginBottom: 20, maxWidth: 400 }}
            />
          )}
          <Form
            ref={(ref) => (this.formRef = ref!)}
            schema={[
              {
                label: __(T.misc.parcel),
                name: 'parcelCode',
                image: <Icons.Barcode />,
                focus: true,
                required: true,
                hide: !isOutboundStesiReplica && !isOutboundRiassortimentiAX && !isOutboundColliCross78,
              },
              {
                placeholder: 'Certilogo o Commessa di prod.',
                name: 'inputBarcode',
                required: true,
                focus: true,
                image: <Icons.Barcode />,
                hide:
                  isOutboundStesiReplica ||
                  isOutboundRiassortimentiAX ||
                  (isStandardOutbound && !isRepairOutbound) ||
                  isDirectTransferTopfly ||
                  isOutboundColliCross78,
              },
              {
                placeholder: __(T.misc.certilogo),
                name: 'inputCertilogo',
                required: true,
                focus: true,
                image: <Icons.Barcode />,
                hide:
                  isOutboundStesiReplica ||
                  isOutboundRiassortimentiAX ||
                  this.operation?.isCustomDirectTransfer === 'yes' ||
                  isRepairOutbound ||
                  isOutboundColliCross78,
              },
              {
                placeholder: __(T.misc.choiche),
                name: 'choice',
                required: true,
                type: 'select',
                options: optionsObj,
                hide:
                  (this?.operation?.isCustomDirectTransfer === 'yes' && !isDirectTransferTopfly) ||
                  isOutboundStesiReplica ||
                  isOutboundRiassortimentiAX ||
                  isOutboundColliCross78,
              },
              {
                placeholder: __(T.fields.tubful_code),
                name: 'tubful',
                required: true,
                hide:
                  AppStore.loggedUser?.place?.attributes?.tintoria !== 'true' ||
                  this?.operation?.isCustomDirectTransfer === 'yes' ||
                  isRepairOutbound ||
                  isOutboundStesiReplica ||
                  isOutboundRiassortimentiAX ||
                  isOutboundColliCross78,
              },
              {
                label: __(T.misc.topfly_order),
                name: 'replicaOrder',
                type: 'input',
                required: true,
                hide: !isDirectTransferTopfly,
              },
              {
                placeholder: __(T.misc.destinationType),
                name: 'destinationType',
                required: true,
                type: 'select',
                options: destinationTypeChoicesObj,
                hide: !(isDirectTransfer && isEnabledDirectTransferStoreCustomer),
                onChange: this.onDestinationTypeChange,
              },
              {
                placeholder: this.state.loadingPlaces ? __(T.misc.loading) : __(T.misc.destination),
                name: 'destination',
                required: true,
                type: 'select',
                options: this.state.places,
                config: { value: 'id', label: 'description', secondaryLabel: 'code' },
                hide:
                  !isOnlyRepairOutbound &&
                  ((this?.operation?.isCustomDirectTransfer === 'no' && !forceDestinationSelect) ||
                    isOutboundStesiReplica ||
                    isOutboundRiassortimentiAX ||
                    isOutboundColliCross78),
                disabled: this.state.loadingPlaces,
              },
              {
                placeholder: __(T.fields.tone),
                name: 'tone',
                type: 'select',
                options: tones,
                hide:
                  !isStandardOutbound ||
                  isOnlyRepairOutbound ||
                  isDirectTransferTopfly ||
                  isDirectTransfer ||
                  isOutbound78,
              },
            ]}
            initialValues={
              this?.operation?.isCustomDirectTransfer === 'no' && places && places.length > 0
                ? { destination: places.find((place) => place?.attributes?.secondChoice === 'default') }
                : undefined
            }
            submitText="Avanti"
            onSubmit={this.submit}
            width={400}
          />
        </Box>
      </Page>
    )
  }
}
