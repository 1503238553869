import 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from '@sentry/react'
import 'react-datepicker/dist/react-datepicker.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn:
    process.env.NODE_ENV !== 'production'
      ? ''
      : 'https://bf041aee00ae447eb563b5f697d55ab2@o160948.ingest.sentry.io/5596881',
  autoSessionTracking: true,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

Sentry.setTag('hostname', String(window.location.hostname))

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
