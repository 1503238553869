import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/react'

export type ButtonVariant = 'primary' | 'secondary' | 'default' | 'error' | 'warning' | 'ignore' | 'severe'
const colors = {
  primary: '#ceff00',
  secondary: '#333333',
  default: '#DCDCDC',
  ignore: '#EDEDED',
  warning: '#FFEFC6',
  error: '#FFD4CB',
  severe: '#ef0909',
}

interface Props {
  title?: string
  variant?: ButtonVariant
  size?: 'default' | 'small' | 'medium'
  onClick?: () => void
  type?: string
  style?: React.CSSProperties
  loading?: boolean
}

export default function Button({ title, onClick, children, loading, ...restProps }: React.PropsWithChildren<Props>) {
  const [internalLoading, setinternalLoading] = useState(false)
  const onPrePress = async () => {
    if (internalLoading) return
    if (!onClick) return

    try {
      setinternalLoading(true)
      await onClick()
      setinternalLoading(false)
    } catch (error) {
      //ignore
    }
  }

  const load = internalLoading || loading

  return (
    <ButtonStyle disabled={load} onClick={onPrePress} {...(restProps as any)}>
      {!load && <>{title ?? children}</>}
      {load && <Spinner />}
      {/* {load && <span>{__(T.misc.loading)}</span>} */}
    </ButtonStyle>
  )
}

const ButtonStyle = styled.button<Props>`
  position: relative;
  ${({ size }) => {
    switch (size) {
      case 'small':
        return `padding: 0px 11px; font-size: 17px; min-height: 36px;`
      case 'medium':
        return `padding: 0px 11px; font-size: 18px; min-height: 45px;`
      default:
        return `padding: 0px 30px; font-size: 24px; min-height: 65px;`
    }
  }};
  ${({ style }) => {
    if (style?.height) return `min-height: unset;`
    return ''
  }}
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  background: ${({ variant }) => {
    const bgs = {
      primary: `linear-gradient(180deg, ${colors.primary} 60.77%, ${colors.primary} 60.79%, ${colors.primary} 229.23%)`,
      secondary: `linear-gradient(180deg, ${colors.secondary} 60.77%, ${colors.secondary} 60.79%, ${colors.secondary} 229.23%)`,
      ignore: `linear-gradient(180deg, ${colors.ignore} 60.77%, ${colors.ignore} 60.79%, ${colors.ignore} 229.23%)`,
      warning: `linear-gradient(180deg, ${colors.warning} 60.77%, ${colors.warning} 60.79%, ${colors.warning} 229.23%)`,
      error: `linear-gradient(180deg, ${colors.error} 60.77%, ${colors.error} 60.79%, ${colors.error} 229.23%)`,
      severe: `linear-gradient(180deg, ${colors.severe} 60.77%, ${colors.severe} 60.79%, ${colors.severe} 229.23%)`,
      default: `${colors.default}`,
    }
    return bgs[variant ?? 'primary']
  }};
  color: ${(props) => (props.variant === 'secondary' || props.variant === 'severe' ? 'white' : 'rgba(51,51,51,1)')};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), inset 0px -1px 4px rgba(0, 0, 0, 0.1);
  border-color: ${(props) => (props.variant === 'secondary' ? 'transparent' : 'transparent')};
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  :disabled {
    opacity: 0.5;
  }
  :focus {
    outline: ${(props) => (props.variant === 'secondary' ? 'transparent' : 'transparent')};
  }
  :hover {
  }
`
