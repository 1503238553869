import React, { Component } from 'react'
import { GroupedProductsTable, Modal } from 'components'
import { GroupedShipmentProduct, ShipmentParcel, TmrTransfer } from 'api/types'

import { T, __ } from 'translations/i18n'
import { showToast } from 'shared/utils'
import ShipmentProvider from 'ShipmentProvider'

interface Props {
  parcelCode?: string
  onClose: () => void
  label: string
  isOpen: boolean
}

interface State {
  transfers?: TmrTransfer[]
  outboundShipmentParcels?: ShipmentParcel[]
  checkListType?: string
  groupedProducts?: GroupedShipmentProduct[]
  totalProductsQuantity: number
  loading: boolean
}

export default class ParcelInfoModal extends Component<Props, State> {
  state: State = {
    totalProductsQuantity: 0,
    loading: true,
  }

  async componentDidMount() {
    const { parcelCode } = this.props

    try {
      const {
        outboundShipmentParcels,
        checkListType,
        groupedProducts,
      } = await ShipmentProvider.fetchAllOutboundShipmentParcels(parcelCode)

      let totalProductsQuantity = 0

      groupedProducts.forEach((product) => {
        totalProductsQuantity += product.detected
      })

      this.setState({ outboundShipmentParcels, checkListType, groupedProducts, totalProductsQuantity, loading: false })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  render() {
    const { groupedProducts, loading } = this.state
    const { isOpen, onClose, label } = this.props
    return (
      <Modal visible={isOpen ?? true} onClose={onClose} size="6xl" title={label}>
        <GroupedProductsTable products={groupedProducts || []} loading={loading} />
      </Modal>
    )
  }
}
