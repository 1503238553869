import React, { Component } from 'react'
import { Input, Page, Box, List, Spacer, Select, Button, DdtRow } from 'components'
import { getLocationState, getMatchParams, navigate } from 'shared/router'
import {ShippingDdtCreateRequest, ShippingDdt, CustomShippingNotice} from 'api/types'
import { showToast, uniqueBy } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import Ddt from 'api/Ddt'

interface State {
  loading: boolean
  shippingddts: ShippingDdt[]
  query: string
  destinationFilter?: ShippingDdt
  destinations?: ShippingDdt[]
}

export default class DdtList extends Component {
  state: State = {
    loading: true,
    shippingddts: [],
    query: '',
  }

  ddtRequest: ShippingDdtCreateRequest = {}

  async componentDidMount() {
    const locationState = getLocationState(this.props)

    this.ddtRequest = {
      destinationPlace: locationState?.submittedDestination,
      shipmentCodes: locationState?.submittedNotices,
    }
    try {
      const detailResponse = await Ddt.searchCounters({
        ids: [],
        ddtCodes: [],
        ddtStates: ['DRAFT'],
        originPlaces: [],
        destinationPlaces: [],
      })
      this.setState({
        loading: false,
        shippingddts: detailResponse,
        destinations: this.getDestinations(detailResponse),
      })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  navigateBack = () => navigate('/', { configCode: getMatchParams(this.props).configCode })

  onRowClick = (itm: ShippingDdt) => {
    navigate('/shippingddt/update/:itemId', {
      itemId: itm.id!,
    })
  }

  onInputEnter = (input: string) => {
    this.setState({ query: input })
  }

  filterResults = (result: ShippingDdt) => {
    const { query, destinationFilter } = this.state
    return (
      result?.idDdt?.toString().includes(query) &&
      ((destinationFilter &&
        result.destinationPlace.code === destinationFilter?.destinationPlace?.code) ||
        !destinationFilter)
    )
  }

  getDestinations = (data) =>
    uniqueBy<ShippingDdt>(data, 'destinationPlace.code')
      .filter((d) => d.destinationPlace?.code)
      .sort((a, b) => a.destinationPlace!.code.localeCompare(b.destinationPlace!.code))

  sortingFunction = (a: ShippingDdt, b: ShippingDdt) => {
    if (a.idDdt < b.idDdt) return 1
    if (a.idDdt > b.idDdt) return -1
    return 0
  }

  render() {
    const { shippingddts, loading, destinations } = this.state
    const filtered = shippingddts.filter(this.filterResults).sort(this.sortingFunction)
    return (
      <Page
        loading={loading}
        title={`${__(T.titles.shipping_ddt) ?? 'Outbound'}`}
        onBackPress={() => navigate('/', { configCode: getMatchParams(this.props).configCode })}
        header={{}}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.placeholder.search_ddt_id)}
              onChange={(query) => this.setState({ query })}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Select
              containerStyle={{ width: 250 }}
              borderColor="transparent"
              transparent
              onSelect={(itm) => this.setState({ destinationFilter: itm })}
              options={destinations ?? []}
              placeholder={__(T.misc.destination)}
              config={{
                value: 'destinationPlace.code',
                label: 'destinationPlace.description',
                secondaryLabel: 'destinationPlace.code',
              }}
            />
            <Box flex />
            <Button loading={loading} title={__(T.misc.new_ddt)} onClick={() => navigate('/shippingddt')} />
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.titles.last_ddts)} (${filtered.length})`}
            renderItem={(ddt) => <DdtRow shippingddt={ddt} onRowClick={this.onRowClick} />}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
