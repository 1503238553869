import React, { Component } from 'react'
import { Form, Page, Box } from 'components'
import { TmrPlace } from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import { navigate } from 'shared/router'
import AppStore from 'AppStore'
import PackingLists from 'api/PackingLists'

interface State {
  places: TmrPlace[]
  loading: boolean
}
export default class PackingList extends Component<{}, State> {
  state: State = {
    places: [],
    loading: true
  }

  componentDidMount() {
    PackingLists.getPlaces()
      .then((data) => {
      this.setState({
        places: data.filter((p) => p.code !== AppStore.loggedUser?.place?.code) ?? [],
        loading: false
      })
    })
    .catch((err) => {
      showToast({ title: __(T.error.error), description: err?.message ?? err, status: 'error' })
    })
  }

  submit = async (data) => {
    if (!data.destination) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.destination_field_required),
        status: 'error',
      })
      return
    }
    const state = {
      destination: data.destination,
    }
    navigate('/packinglist/create', undefined, { state })
  }

  render() {
    const { places, loading } = this.state

    return (
      <Page title={__(T.titles.packing_list)} onBackPress={() => navigate('/packinglist/detail')}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  placeholder: loading ? __(T.misc.loading) : __(T.misc.destination),
                  disabled: loading,
                  name: 'destination',
                  required: true,
                  type: 'select',
                  options: places,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
