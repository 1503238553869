import React, { Component } from 'react'
import {
  Page,
  GroupedProductList,
  Spacer,
  TagCounter,
  Box,
  TextBox,
  EmulateButton,
  Button,
  Icons,
  ItemsStatesRow,
} from 'components'
import { GroupedShipmentProduct, ShipmentParcel, TmrItem, TmrZone } from 'api/types'
import { navigate, getMatchParams } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import { T, __, __UP } from 'translations/i18n'
import Shipments from 'api/Shipments'
import Gate from 'api/Gate'
import { PageParams } from 'pages'
import Sounds from 'shared/Sounds'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import styled from '@emotion/styled'
import Items from 'api/Items'
import * as Onde from 'assets/onde.json'
import Lottie from 'react-lottie'
import CustomOutboundProvider from './CustomOutboundProvider'
import CustomShipmentProvider from 'CustomShipmentProvider'

interface Params extends PageParams {
  outboundZone?: TmrZone
  parcelCode: string
}

interface State {
  parcels: ShipmentParcel[]
  checkListType: CheckListType
  groupedProducts: GroupedShipmentProduct[]
  loading: boolean
  reading: boolean
}

export default class extends Component<{}, State> {
  unexpectedFound = false

  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  params: Params = {
    ...getMatchParams(this.props),
  }

  state: State = {
    parcels: [],
    checkListType: 'ITEMS',
    groupedProducts: [],
    loading: true,
    reading: false,
  }

  async componentDidMount() {
    try {
      if (!this.operation) throw new Error(__(T.error.no_operation_config))
      if (this.operation.readingMode !== 'rfid') throw new Error(__(T.error.not_supported_reading_mode))

      const { parcelCode } = getMatchParams(this.props)
      const {
        outboundShipmentParcels,
        checkListType,
        groupedProducts,
      } = await ShipmentProvider.fetchAllOutboundShipmentParcels(parcelCode)
      if (!outboundShipmentParcels) throw new Error(__(T.error.parcel_not_found))

      Gate.initSSE(this.onTagRead, this.onError)

      await CustomShipmentProvider.fetchItemsFromShipmentParcelItems(outboundShipmentParcels[0].expectedItems)

      const outboundRequest = CustomOutboundProvider.getGateOutboundRequest(
        this.params.parcelCode,
        outboundShipmentParcels[0].expectedUpcs
      )

      await Gate.startOutbound(outboundRequest)
      this.setState({
        loading: false,
        checkListType,
        parcels: outboundShipmentParcels,
        groupedProducts,
        reading: true,
      })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  componentWillUnmount() {
    Gate.closeEventSource()
  }

  onTagRead = (event: any) => {
    const data = JSON.parse((event.data as string).replace(/'/g, '"'))

    this.onDecodedItemCallback(data.epc)
  }

  onError = (event: any) => {
    const data = JSON.parse((event.data as string).replace(/'/g, '"'))
    showToast({ status: 'error', title: `${__(T.error.error)}: ${data.code}`, description: data.message })
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error(__(T.error.undefined_operation_config))

    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
    })
  }

  onDecodedItemCallback = async (epc: string) => {
    const { parcels: outboundShipmentParcels, groupedProducts } = this.state
    const items: TmrItem[] = outboundShipmentParcels[0].expectedItems.filter(
      (itm) => itm?.itemIdentifiers?.find((itmId) => itmId.code === epc) !== undefined
    )

    if (!items || items.length === 0) {
      const item = await Items.decode(epc)
      if (item.id && item.itemIdentifiers) items.push(item as TmrItem)
    }

    ShipmentProvider.processOutboundItemsStates(items, this.operation)

    CustomOutboundProvider.processItemForUpcsChecklist(items, outboundShipmentParcels, groupedProducts)

    const counters = ShipmentProvider.getCounters(outboundShipmentParcels[0], this.state.checkListType)

    if (!this.unexpectedFound && counters.unexpected > 0) {
      this.unexpectedFound = true
      Sounds.error()
    }

    if (counters.detected === counters.expected && counters.unexpected === 0) {
      Sounds.success()
    }

    this.forceUpdate()
  }

  clear = () => {
    const { parcels: outboundShipmentParcels } = this.state
    this.unexpectedFound = false
    const groupedProducts = ShipmentProvider.clearAllReceivingReadings(outboundShipmentParcels)
    this.setState({ groupedProducts })
  }

  saveParcel = async () => {
    await this.confirmOutbound(false)
  }

  saveAndConfirm = async () => {
    await this.confirmOutbound(true)
  }

  confirmOutbound = async (confirmParcel: boolean) => {
    const { parcels: outboundShipmentParcels, checkListType } = this.state
    const parcel = outboundShipmentParcels.length ? outboundShipmentParcels[0] : undefined
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(
      parcel,
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )

    try {
      if (
        parcel?.detectedItems?.filter((item) =>
          item.__processedStates?.map((state) => state.processedState)?.includes('ERROR')
        ).length
      )
        throw new Error(__(T.error.items_in_error_found))

      if (detected < expected || detected > expected || unexpected > 0) {
        const confirmed = confirmParcel
          ? await askUserConfirmation(__(T.confirm.discrepancy_title), __(T.confirm.discrepancy_message))
          : await askUserConfirmation(__(T.confirm.save_parcel_readings), __(T.confirm.save_parcel_readings_message))

        if (!confirmed) return
      }

      if (confirmParcel) {
        parcel?.header?.parcelCode && (await Gate.closeOutbound(parcel?.header.parcelCode))
      }

      await CustomOutboundProvider.confirmOutboundIgnoringUnexpected(
        outboundShipmentParcels,
        this.operation!.id,
        parcel!.header!.destinationPlace.code,
        checkListType,
        confirmParcel
      )
      showToast({
        title: __(T.misc.success),
        description: confirmParcel ? __(T.messages.outbound_success) : __(T.messages.operation_success),
        status: 'success',
      })

      if (confirmParcel) {
        this.navigateBack()
      }
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  navigateBack = () =>
    navigate('/custom/outbound/appesi/:configCode', { configCode: getMatchParams(this.props).configCode })

  toggleGate = async () => {
    const { reading } = this.state
    try {
      if (reading) {
        const confirmed = await askUserConfirmation(__(T.misc.alarm), __(T.confirm.confirm_stop_gate))
        if (!confirmed) return
        await Gate.emergencyStop()
      } else {
        await Gate.resume()
      }
      this.setState({ reading: !this.state.reading })
    } catch (error) {
      showToast({ status: 'error', title: __(T.error.error), description: error.message })
    }
  }

  emulateTags1 = async () => {
    await Gate.emulateTags({ epcs: ['3035EB98C80905C00000002A', '3035EB98C80905C00000002B'], delay: 3000 })
  }

  emulateTags2 = async () => {
    await Gate.emulateTags({ epcs: ['3035EB98C813538000000007'], delay: 3000 })
  }

  emulateTags3 = async () => {
    await Gate.emulateTags({ epcs: ['3035EBBC48308540000000D3'], delay: 3000 })
  }

  emulate = async (epcs: string[]) => {
    await Gate.emulateTags({ epcs: epcs, delay: 1000 })
  }

  renderIcon = () => {
    const style = {
      marginRight: 10,
      width: 36,
      height: 36,
    }
    const { reading } = this.state
    if (reading) {
      return <Icons.Pause style={style} />
    }
    return <Icons.Play style={style} />
  }

  renderGateButton = () => {
    const { reading } = this.state
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: (Onde as any).default,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }
    return (
      <Container row>
        <ButtonControl
          style={{ backgroundColor: reading ? '#EF4141' : '#ceff00' }}
          row
          center
          onClick={() => this.toggleGate()}
        >
          {this.renderIcon()}
          {reading ? __UP(T.misc.alarm) : __UP(T.misc.start)}
        </ButtonControl>
        {reading && (
          <div style={{ padding: '5px 10px' }}>
            <Lottie isClickToPauseDisabled style={{ width: '100%', height: '100%' }} options={defaultOptions} />
          </div>
        )}
      </Container>
    )
  }

  removeItemFromReadings = (item: TmrItem) => {
    const { parcels: outboundShipmentParcels, groupedProducts } = this.state
    ShipmentProvider.removeItemsForItemsChecklist([item], outboundShipmentParcels, groupedProducts)
    this.forceUpdate()
  }

  render() {
    const { parcels: outboundShipmentParcels, groupedProducts, loading, checkListType } = this.state
    const parcel = outboundShipmentParcels.length ? outboundShipmentParcels[0] : undefined
    const commessaCode = parcel?.header?.parcelAttributes?.TOM
    const parcelCode = parcel?.header?.parcelCode
    const originPlace = parcel?.header?.originPlace?.description || parcel?.header?.originPlace?.code
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(
      parcel,
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )
    const error = unexpected > 0 ? __(T.error.rfid_discrepancies_found) : ''
    const pageTitle = this.operation?.description ?? __(T.misc.outbound)

    return (
      <Page
        title={pageTitle}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details: [
            { label: __(T.misc.shipment), value: commessaCode },
            { label: __(T.misc.parcel), value: parcelCode },
            { label: __(T.misc.origin), value: originPlace },
          ],
        }}
        enableEmulation
        emulationFunction={this.emulate}
      >
        <Page.Sidebar style={{ overflow: 'auto' }}>
          <Box flex style={{ overflow: 'auto' }}>
            <TagCounter detected={detected - unexpected} expected={expected} unexpected={unexpected} />
            {this.renderGateButton()}
            <Spacer />
            <TextBox text={error} style={{ marginBottom: 20 }} type="error" />
          </Box>
          <Button title={__(T.misc.save_parcel)} onClick={this.saveParcel} variant="default" />
          <Spacer />
          <Button title={__(T.misc.confirm_parcel)} onClick={this.saveAndConfirm} />
        </Page.Sidebar>

        <Page.Content>
          <ItemsStatesRow
            items={parcel?.detectedItems as TmrItem[]}
            onItemDeleteCallback={this.operation.removeMode !== 'none' ? this.removeItemFromReadings : undefined}
          />
          <GroupedProductList loading={loading} data={groupedProducts} />

          <Box>
            <EmulateButton title="Emulate tags 1" onPress={this.emulateTags1} />
            <EmulateButton title="Emulate tags 2" onPress={this.emulateTags2} />
            <EmulateButton title="Emulate tag KO" onPress={this.emulateTags3} />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}

const ButtonControl = styled(Box)`
  flex: 1;
  background-color: #ceff00;
  border-radius: 50px;
  padding: 15px 25px;
  cursor: pointer;
  font-weight: 900;
  font-size: 22px;
`

const Container = styled(Box)`
  background-color: white;
  border: solid 2px #eeeeee;
  border-radius: 50px;
  padding: 10px;
`
