import React, { Component } from 'react'
import styled from '@emotion/styled'
import Button from 'components/Button'
import { T, __UP } from 'translations/i18n'
import Box from 'components/Box'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onClose: () => void
  style?: any
}

export default class AlertModal extends Component<Props> {
  render() {
    const { onClose, title, subtitle } = this.props
    return (
      <ModalBox>
        <Box>
          <Title>{title}</Title>
          <Description>{subtitle}</Description>
        </Box>

        <Box row mt={10}>
          <CloseButton variant="secondary" title={__UP(T.misc.close)} onClick={onClose} />
        </Box>
      </ModalBox>
    )
  }
}

const ModalBox = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  background: #eb5757;
  z-index: 999999;
  align-items: center;
  justify-content: center;
`

const Title = styled.span`
  font-size: 44px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  color: #fff;
  white-space: pre-wrap;
`

const Description = styled.label`
  font-weight: 500;
  padding: 25px 15px;
  font-size: 30px;
  text-align: center;
  opacity: 0.8;
  color: #fff;
  white-space: pre-wrap;
`

const CloseButton = styled(Button)`
  flex: 1;
  min-width: auto;
  height: 74px;
  width: 320px;
`
