import React from 'react'
import Products from 'api/Products'
import { GroupedShipmentProduct, ShippingParcel } from 'api/types'
import { Image, CustomSmallTagCounter, Box, Icons } from 'components'
import { T, __ } from 'translations/i18n'
import styled from '@emotion/styled'
import { getDatetime } from './utils'

function ShipmentProductStructure(totalProductsQuantity: number) {
  const ProductImage = styled(Image)`
    object-fit: contain;
    width: 125px;
    height: 100px;
    padding: 10px;
  `
  return [
    {
      label: '',
      width: 140,
      customRender: ({ product }: GroupedShipmentProduct) => (
        <ProductImage style={{ marginTop: 10, marginBottom: 10 }} src={Products.getImageUrl(product.code)} />
      ),
    },
    {
      width: 240,
      label: __(T.misc.upc),
      customValue: ({ product }: GroupedShipmentProduct) => `${product.code}`,
    },
    {
      flex: 2,
      label: __(T.misc.model_part_color_size),
      customValue: ({ product }: GroupedShipmentProduct) =>
        `${product.material?.value ?? '--'} / ${product.style?.value ?? '--'} / ${product.color?.value ?? '--'} / ${
          product.size?.value ?? '--'
        }`,
    },
    {
      label: __(T.misc.quantity, { quantity: totalProductsQuantity }),
      width: 180,
      customRender: (product: GroupedShipmentProduct) => (
        <CustomSmallTagCounter
          detected={product.detected}
          unexpected={product.unexpected}
          expected={product.expected}
        />
      ),
    },
  ]
}

function NoticeStructure(onRowPress) {
  return [
    {
      customRender: (parcel: ShippingParcel) => <>{parcel.code}</>,
    },
    {
      customRender: (parcel: ShippingParcel) => <>{getDatetime(parcel?.creationDate)}</>,
    },
    {
      customRender: (parcel: ShippingParcel) => (
        <>
          <Box p={30} center onClick={() => onRowPress(parcel)}>
            <Icons.Info />
          </Box>
        </>
      ),
    },
  ]
}

export default {
  ShipmentProductStructure,
  NoticeStructure,
}
