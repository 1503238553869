import React, { Component } from 'react'
import { ShippingDdt } from 'api/types'
import { Box, List } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import DdtStatus from './DdtStatus'

interface Props {
  shippingddt: ShippingDdt
  onRowClick: (shippingddt: ShippingDdt) => void
}
export default class DdtRow extends Component<Props> {
  render() {
    const { shippingddt, onRowClick } = this.props
    return (
      <RowItem onClick={() => onRowClick(shippingddt)}>
        <Box style={{ width: 180 }}>
          <Box style={{ fontWeight: 700, marginBottom: 5 }}>{__(T.misc.status)}</Box>
          <DdtStatus width={170} status={shippingddt.ddtState} />
        </Box>
        <List.Cell
          style={{ width: 240 }}
          label={__(T.misc.origin)}
          value={shippingddt?.originPlace?.description || shippingddt?.originPlace?.code}
        />
        <List.Cell
          style={{ width: 240 }}
          label={__(T.misc.destination)}
          value={shippingddt?.destinationPlace?.description || shippingddt?.destinationPlace?.code}
        />
        <List.Cell style={{ width: 240 }} label={__(T.misc.ddt_id)} value={shippingddt?.idDdt} />
        <Box width={100}>
          <SmallCounter>{`${shippingddt?.notices?.length > 0 ? `${shippingddt?.notices?.length}` : ''}`}</SmallCounter>
        </Box>
      </RowItem>
    )
  }
}

const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0px 20px;
  background-color: ${({ unexpected = 0, detected = 0, expected = 0 }) => {
    if (unexpected > 0) return '#F2B57D'
    if (expected === 0) return '#EDEDED'
    if (detected < expected) return '#EDEDED'
    if (detected === expected) return '#75EBA8'
    if (detected > expected) return '#F2B57D'
    return '#75EBA8'
  }};
`
