import TmrBaseResource from "./TmrBaseResource";
import api, {responseErrorCheck} from "./api";


export default class ScalarinoApi extends TmrBaseResource {
    static endpoint = '/scalarino'


    static getTaglie(modello: any) {
        return api
            .get<any>(`${this.endpoint}/listTaglie?modello=${modello}`)
            .then(responseErrorCheck)
            .then((res) => res)
    }

    static getColori(modello: any) {
        return api
            .get<any>(`${this.endpoint}/listColori?modello=${modello}`)
            .then(responseErrorCheck)
            .then((res) => res)
    }
}