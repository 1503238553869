import React, { Component } from 'react'
import {
  Page,
  Box,
  Spacer,
  Button,
  Section,
  Table,
  ParcelInfoModal,
  PackingListSectionHeader,
  EmptyList, List, TextArea
} from 'components'
import { getLocationState, navigate } from 'shared/router'
import { ShipmentParcel, CustomShippingNotice, PackingListRequest } from 'api/types'
import { askUserConfirmation, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import PackingLists from 'api/PackingLists'
import styled from '@emotion/styled'
import { createBrowserHistory } from 'history'
import Structures from 'shared/Structures'
import PackingListModal from "../../components/modals/PackingListModal";
import AppDatas from "../../api/AppDatas";

interface State {
  parcel?: ShipmentParcel
  filter: string
  loading: boolean
  shippingNotices: CustomShippingNotice[]
  data: CustomShippingNotice[]
  query: string
  destinations?: CustomShippingNotice[]
  parcelToModal?: string
  isOpen: boolean
  showNoteModal: boolean
  isPLNoteModalEnabled?: string
}

export const history = createBrowserHistory()

export function navigateBack(): void {
  history.goBack()
}


export default class PackingListPreviewDetail extends Component {
  state: State = {
    filter: '',
    loading: true,
    shippingNotices: [],
    data: [],
    query: '',
    parcelToModal: undefined,
    isOpen: false,
    showNoteModal: false,
    isPLNoteModalEnabled: undefined

  }

  packingListRequest: PackingListRequest = {}

    async componentDidMount() {
    const locationState = getLocationState(this.props)
    await this.fetchAppDatas()

    this.packingListRequest = {
      destinationPlace: locationState?.submittedDestination,
      parcelCodes: locationState?.submittedParcels,
    }

    PackingLists.preview(this.packingListRequest)
        .then((data) => {
          this.setState({ loading: false, shippingNotices: data})
        })
        .catch((error) => {
          this.setState({ loading: false, shippingNotices: []})
          showToast({
            title: __(T.error.error),
            description: error?.message ?? 'Generic error',
            status: 'error',
          })
          navigateBack()
    })
  }

  onRowPress = (parcel) => {
    this.setState({ parcelToModal: parcel!.code, isOpen: true })
  }

  onClose = () => {
    this.setState({ parcelToModal: undefined, isOpen: true })
  }

  onInputEnter = (input: string) => {
    this.setState({ query: input })
  }
    confirmOutbound = async (
        notes?: string
    ) => {
        if (notes) this.packingListRequest.note = notes

            await PackingLists.create(this.packingListRequest)
                .then((detailResponse) => {
                    this.setState({
                        loading: false,
                        shippingNotices: detailResponse ? detailResponse : undefined,
                    })
                    setInterval(async () => {},10000)
                    showToast({
                        title: __(T.misc.success),
                        description: __(T.messages.operation_success),
                        status: 'success',
                    })
                    navigate('/packinglist/detail')
                })
                .catch((error) => {
                    showToast({
                        title: __(T.error.error),
                        description: error?.message ?? 'Generic error',
                        status: 'error',
                    })
                    navigateBack()
                })

        }


    fetchAppDatas = async () => {
        const [isPLNoteModalEnabled] = await Promise.all([AppDatas.getValue(__(T.appSystem.isPLNoteModalEnabled))])
        this.setState({isPLNoteModalEnabled: isPLNoteModalEnabled ?? false})
    }


  render() {
    const { loading, shippingNotices, isOpen, parcelToModal, showNoteModal,isPLNoteModalEnabled} = this.state
    return (
      <Page loading={loading} title={`${__(T.titles.packing_list)}`} onBackPress={() => navigateBack()} header={{}}>
        <Page.Content>
          <Page.TopBar>
            <Spacer />
            <SectionTitle>{__(T.misc.preview_packing_list)}</SectionTitle>
            <Box flex />
            <Button
              loading={loading}
              title={__(T.misc.create_packing_list)}
              onClick={async () => {
                  if(isPLNoteModalEnabled == 'true'){
                      this.setState({showNoteModal: true})
                  }else{
                      ((await askUserConfirmation(
                              __(T.confirm.create_new_packing_list_title),
                              __(T.confirm.create_new_packing_list)
                          )) ??
                          false) &&
                      this.confirmOutbound()
                  }
              }}
            />
          </Page.TopBar>
          <>
            {shippingNotices?.map((notice, index) => (
              <Section
                key={index}
                header={<PackingListSectionHeader notice={notice} />}
                body={
                  <Table
                    headerStyle={{ position: 'relative' }}
                    style={{ paddingTop: 0 }}
                    showHeader={false}
                    structure={Structures.NoticeStructure(this.onRowPress)}
                    loading={loading}
                    data={notice.parcels ?? []}
                    emptyStyle={{ height: 300 }}
                  />
                }
              />
            ))}
            {parcelToModal && (
              <ParcelInfoModal
                parcelCode={parcelToModal}
                onClose={this.onClose}
                label={__(T.misc.parcel).concat(' ').concat(parcelToModal.toString())}
                isOpen={isOpen}
              />
            )}
              {showNoteModal && (
                  <PackingListModal
                      title={__(T.misc.create_packing_list)}
                      onClose={() => {
                          this.setState({ showNoteModal: false })
                      }}
                      onConfirm={this.confirmOutbound}
                  />
              )}
          </>
          {loading && shippingNotices?.length === 0 && <List loading={true} data={[]} renderItem={() => <></>}/>}
          {!loading && shippingNotices?.length === 0 && <EmptyList />}
        </Page.Content>
      </Page>
    )
  }
}

const SectionTitle = styled.div`
  font-weight: 900;
  font-size: 28px;
  margin-bottom: 10px;
`
