import React, { Component } from 'react'
import AppStore from 'AppStore'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider, Theme } from '@emotion/react'
import { PublicRoutes, RegisteredRoutes } from 'pages'
import { history } from 'shared/router'
import { hot } from 'react-hot-loader/root'
import { changeLanguage } from 'translations/i18n'
import { Box, ConfirmModal, Modal } from 'components'
import { ChakraProvider, Spinner } from '@chakra-ui/react'
import { ModalType } from 'api/types'

const theme: Theme = {
  background1: '#F2F2F2',
  background2: '#FFFFFF',
  bgGrey: '#D7D7D7',
  primary: '#CEFF00',
  secondary: '#222222',
  primaryBg: 'linear-gradient(180deg, #CEFF00 60.77%, #CEFF00 60.79%, #B3DD00 229.23%)',
  secondaryBg: 'linear-gradient(180deg, #666666 15%, #525252 120%)',
  padding: '20px',
}

interface State {
  loading: boolean
  showConfirm: boolean
  modals: ModalType[]
}

class App extends Component<{}, State> {
  state: State = {
    loading: true,
    showConfirm: false,
    modals: [],
  }

  async componentDidMount() {
    changeLanguage('it')

    await AppStore.loadInitalData()
    AppStore.toggleConfirmModalHandler = (show) => this.setState({ showConfirm: show })
    AppStore.openModal = (modal: ModalType) => this.setState({ modals: [...this.state.modals, modal] })
    AppStore.hideModal = (id: string) => {
      const { modals } = this.state
      const modalIndex = modals.findIndex((modal) => modal.id === id)
      modals[modalIndex].visible = false
      this.setState({ modals: modals.filter((m) => m.visible) })
    }
    AppStore.reloadRouting = () => this.forceUpdate()
    this.setState({ loading: false })
  }

  render() {
    const { loading, modals, showConfirm } = this.state
    if (loading)
      return (
        <ChakraProvider>
          <ThemeProvider theme={theme}>
            <Box flex center vcenter>
              <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
            </Box>
          </ThemeProvider>
        </ChakraProvider>
      )

    const authenticated = !!AppStore.loggedUser

    return (
      <ChakraProvider>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Switch>
              {!authenticated &&
                Object.entries(PublicRoutes).map(([path, route]) => {
                  return <Route path={path} component={route.component} key={path} exact />
                })}

              {authenticated &&
                Object.entries(RegisteredRoutes).map(([path, route]) => {
                  return <Route path={path} component={route.component} key={path} exact />
                })}
              <Redirect to={authenticated ? '/' : '/login'} />
            </Switch>
          </Router>

          {modals.map((modal) => {
            if ('modal' in modal) return modal.modal

            return (
              <Modal
                {...modal}
                key={modal.id}
                onClose={() => {
                  modal.visible = false
                  this.setState({ modals: modals.filter((m) => m.visible) })
                  modal?.onClose?.()
                }}
              >
                {typeof modal.body === 'function' ? modal.body() : modal.body}
              </Modal>
            )
          })}

          {showConfirm && (
            <ConfirmModal
              title={AppStore.confirmModalParams.title}
              subtitle={AppStore.confirmModalParams.message}
              onClose={() => {
                AppStore.confirmModalParams?.onCancel?.()
                this.setState({ showConfirm: false })
              }}
              onConfirm={() => {
                AppStore.confirmModalParams?.onConfirm()
                this.setState({ showConfirm: false })
              }}
            />
          )}
        </ThemeProvider>
      </ChakraProvider>
    )
  }
}

export default hot(App)
