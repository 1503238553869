import React, { useState } from 'react'
import {
  Page,
  LeftHeader,
  KeyValueRow,
  RightContainer,
  Spacer,
  Box,
  UserInfoModal,
  SettingsModal,
  Image,
  Icons,
} from 'components'
import styled from '@emotion/styled'
import { navigate, refresh, NavigationOptions } from 'shared/router'
import { Routes } from 'pages'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import { TmrWorkstation } from 'api/types'
import { showToast } from 'shared/utils'
import RemoteConfig, { InboundConfig, OutboundConfig, PrintingConfig } from 'shared/RemoteConfig'

export default function Dashboard() {
  const [userModalVisible, setuserModalVisible] = useState(false)
  const [settingsModalVisible, setsettingsModalVisible] = useState(false)
  const selectDefaultWorkstation = async (workstation: TmrWorkstation) => {
    await AppStore.saveDefaultWorkstation(workstation)
    refresh()
  }

  const routeMap: {
    inbound: Routes
    outbound: Routes
    shipmentInbound: Routes
    outboundCreate: Routes
    inboundCreate: Routes
    shipmentGateInbound: Routes
    customOutboundAppesi: Routes
    customCampionarioInbound: Routes
    packingList: Routes
    ddt: Routes
    inboundStesiDdt: Routes
    printTagEnvelope: Routes
    changeCodeRequest: Routes
    changeCodeApproval: Routes
  } = {
    inbound: `/inbound/:configCode`,
    outbound: `/outbound/:configCode`,
    shipmentInbound: `/inbound/shipments/:configCode`,
    outboundCreate: '/outbound/:configCode/create',
    inboundCreate: '/custom/inbound/:configCode/create',
    shipmentGateInbound: '/inbound/gate/shipments/:configCode',
    customOutboundAppesi: '/custom/outbound/appesi/:configCode',
    customCampionarioInbound: '/inbound/samples/:configCode',
    packingList: '/packinglist/detail',
    ddt: '/shippingddt/list',
    inboundStesiDdt: '/inbound/stesi/shipments/:configCode',
    printTagEnvelope: '/print-tag-envelope/:configCode',
    changeCodeRequest: '/change-code/management',
    changeCodeApproval: '/change-code/approval',
  }

  const iconMap = {
    inbound: <Icons.Inbound />,
    outbound: <Icons.Outbound />,
    transfers: <Icons.Transfers />,
    repair: <Icons.Repair />,
    print: <Icons.Print />,
  }

  const userCapabilities = AppStore.loggedUser?.roles?.flatMap((role) => role.capabilities) ?? []

  const remoteOp = RemoteConfig.getAllShippingOperations().map((op) => {
    let operationRoute = routeMap[op.operationType]
    if (op.operationType === 'inbound') {
      if ((op as InboundConfig).inboundMode === 'shipment') {
        operationRoute = routeMap.shipmentInbound
        if ((op as InboundConfig).isCustomGateInbound === 'yes') operationRoute = routeMap.shipmentGateInbound
        if ((op as InboundConfig).isCustomCampionarioInbound === 'yes')
          operationRoute = routeMap.customCampionarioInbound
        if ((op as InboundConfig).isStesiDdtInbound === 'yes') {
          operationRoute = routeMap.inboundStesiDdt
        }
      }
      if ((op as InboundConfig).inboundMode === 'parcelByParcel') {
        if ((op as InboundConfig).isIngressoResiInbound === 'yes') operationRoute = routeMap.inboundCreate
      }
    }
    if (op.operationType === 'outbound') {
      if ((op as OutboundConfig).isOutboundStesiReplica === 'yes') operationRoute = routeMap.outboundCreate
      if ((op as OutboundConfig).isOutbound78ColliCross === 'yes') operationRoute = routeMap.outboundCreate
      if ((op as OutboundConfig).isOutboundRiassortimentiAX === 'yes') {
        operationRoute = routeMap.outboundCreate
        if ((op as OutboundConfig).isCustomGateOutbound === 'yes') {
          operationRoute = routeMap.customOutboundAppesi
        }
      }
    }

    return {
      operation: operationRoute,
      routeParams: { configCode: op.code },
      icon: iconMap[op.icon ?? op.operationType],
      text: op.description,
    }
  })

  remoteOp.push(
    ...RemoteConfig.getAllPrintingOperations().reduce((filtered, op) => {
      /*
    Al momento non esiste un default per l'operazione di stampa, quindi se non esiste una di quelle censite non ritorno nulla
    Nel caso venga censita, sostituire il reduce con il map e implementare la stessa logica presente per inbound/outbound
    */
      let operationRoute = routeMap[op.operationType]
      if ((op as PrintingConfig).isPrintTagEnvelope === 'yes') {
        if (AppStore.loggedUser?.admin || userCapabilities.includes('Resource.EnvelopeTag.print'))
          operationRoute = routeMap.printTagEnvelope
        else operationRoute = undefined
      }

      if (operationRoute) {
        filtered.push({
          operation: operationRoute,
          routeParams: { configCode: op.code },
          icon: iconMap[op.icon ?? op.operationType],
          text: __(T.titles.print_tag_envelope_main),
        })
      }

      return filtered
    }, [] as any[])
  )

  const operations: DashboardButtonProps[] = [
    { operation: '/encoding', icon: <Icons.Encoding />, text: __(T.misc.encoding) },
    { operation: '/tag-info', icon: <Icons.TagInfo />, text: __(T.misc.tag_info) },
    ...remoteOp,
    { operation: '/transfer-to-zone', icon: <Icons.TransferToZone />, text: __(T.titles.transfer_to_zone) },
    { operation: '/change-code', icon: <Icons.ChangeCode />, text: __(T.titles.change_code) },
    { operation: '/change-choice', icon: <Icons.ChangeChoice />, text: __(T.titles.change_choice) },
    //{ operation: '/repair', icon: <Icons.Repair />, text: __(T.titles.repairs) },
    //{ operation: '/change-bay', text: __(T.titles.change_bay) },
    // {
    //   operation: '/items-list/:operationCode',
    //   routeParams: { operationCode: 'test-1' },
    //   navigationOptions: { state: { testParam: 'Test 1 location state param' } },
    //   icon: <Icons.TagInfo />,
    //   text: 'Test 1',
    // },
    // {
    //   operation: '/items-list/:operationCode',
    //   routeParams: { operationCode: 'test-2' },
    //   icon: <Icons.TagInfo />,
    //   text: 'Test 2',
    // },
    // {
    //   operation: '/items-list/:operationCode',
    //   routeParams: { operationCode: 'test-default' },
    //   icon: <Icons.TagInfo />,
    //   text: 'Test default',
    // },
  ]

  if (AppStore.loggedUser?.admin || AppStore.loggedUser?.place?.attributes?.changeTagEnabled === 'true')
    operations.push({ operation: '/change-tag', icon: <Icons.Encoding />, text: __(T.titles.change_tag) })

  if (AppStore.loggedUser?.admin || AppStore.loggedUser?.place?.attributes?.qualityControl === 'true')
    operations.push({
      operation: '/quality-control/:configCode',
      icon: <Icons.ChangeChoice />,
      text: __(T.titles.quality_control),
      routeParams: { configCode: 'topfly' },
    })

  if (AppStore.loggedUser?.admin || AppStore.loggedUser?.place?.attributes?.qualityControlStireria === 'true')
    operations.push({
      operation: '/quality-control/:configCode',
      icon: <Icons.ChangeChoice />,
      text: __(T.titles.quality_control_stireria),
      routeParams: { configCode: 'stireria' },
    })

  if (AppStore.loggedUser?.admin || userCapabilities.includes('Resource.PackingList.edit')) {
    operations.push({
      operation: routeMap.packingList,
      icon: <Icons.Outbound />,
      text: __(T.titles.packing_list),
    })
  }

  if (AppStore.loggedUser?.admin || userCapabilities.includes('Resource.Ddt.edit')) {
    operations.push({ operation: routeMap.ddt, icon: <Icons.Outbound />, text: __(T.misc.ddt) })
  }
  if (AppStore.loggedUser?.admin || userCapabilities.includes('Resource.ChangeCode.manage')) {
    operations.push({
      operation: routeMap.changeCodeRequest,
      icon: <Icons.Encoding />,
      text: __(T.misc.manage_change_code),
    })
  }
  if (AppStore.loggedUser?.admin || userCapabilities.includes('Resource.ChangeCode.approve')) {
    operations.push({
      operation: routeMap.changeCodeApproval,
      icon: <Icons.Encoding />,
      text: __(T.misc.approve_change_code),
    })
  }

  return (
    <Page title="Dashboard" header={null}>
      <Box flex row>
        <LeftHeader>
          <KeyValueRow
            onClick={() => setuserModalVisible(!userModalVisible)}
            label={__(T.misc.user)}
            value={AppStore.loggedUser?.username}
            image={<Icons.Info />}
          />
          <Spacer />
          <KeyValueRow
            label={__(T.misc.workstation)}
            value={AppStore.defaultWorkstation?.code ?? '---'}
            optionModal={{
              onSelect: (item) => selectDefaultWorkstation(item[0]),
              field: 'code',
              options: AppStore.workstations ?? [],
              selected: [AppStore.defaultWorkstation],
              title: __(T.titles.select_workstation),
              searchable: true,
            }}
          />
        </LeftHeader>

        <RightContainer style={{ flex: 1.5, alignItems: 'center' }}>
          <DashView>
            {operations.map((operation, index) => (
              <DashboardButton key={index} {...operation} />
            ))}

            {operations.length % 2 === 1 && <DashboardFakeButton />}
          </DashView>
        </RightContainer>
      </Box>
      <UserInfoModal user={AppStore.loggedUser} onClose={() => setuserModalVisible(false)} visible={userModalVisible} />
      <SettingsModal onClose={() => setsettingsModalVisible(false)} visible={settingsModalVisible} />
    </Page>
  )
}

type DashboardButtonProps = {
  operation?: Routes
  routeParams?: any
  navigationOptions?: NavigationOptions
  icon?: any
  text?: string
}

function DashboardButton({ operation, routeParams, icon, text, navigationOptions }: DashboardButtonProps) {
  const onClick = () => {
    if (!AppStore.defaultWorkstation) {
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      if (!AppStore.emulation) return
    }
    operation && navigate(operation, routeParams, navigationOptions)
  }
  return (
    <DashboardItemStyle onClick={onClick}>
      {icon && typeof icon !== 'string' && icon}
      {icon && typeof icon === 'string' && <DashImage alt={text} src={icon} />}
      {text}
    </DashboardItemStyle>
  )
}

const DashboardItemStyle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  width: 276px;
  height: 202px;
  background: #ffffff;
  border: 2px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px;
  margin: 30px 20px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const DashboardFakeButton = styled.div`
  width: 276px;
  height: 202px;
  background: #ffffff;
  border: 2px dashed #eeeeee;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px;
  margin: 30px 20px;
  margin-top: 0px;
`

const DashImage = styled(Image)`
  margin-bottom: 10px;
  max-height: 100px;
  max-width: 100px;
`

const DashView = styled(Box)`
  max-width: 700px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: start;
  padding-top: 50px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
