import React, { Component } from 'react'
import { Form, Page, Box } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { CustomShippingRequest, TmrPlace } from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import InboundShipments from 'api/InboundShipments'
import Places from 'api/Places'
import moment from 'moment'

interface State {
  configurationId: string
  places?: TmrPlace[]
  clientCode: string
  ddtCode?: string
  ddtDate?: string
  nar: string
  shipmentCode?: string
  parcelCode?: string
  destination?: string
}

export default class CustomInboundCreate extends Component {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    configurationId: '',
    clientCode: '',
    ddtCode: '',
    ddtDate: '',
    nar: '',
  }

  componentDidMount() {
    this.fetchClientCodes()
  }

  submit = async (data) => {
    this.state = {
      configurationId: this.operation.id,
      clientCode: data.clientCode.code,
      ddtCode: data.ddtCode,
      ddtDate: this.reformatDdtDate(data.ddtDate),
      nar: data.nar,
    }

    try {
      if (data.ddtCode && !data.ddtDate) {
        throw new Error(__(T.error.ddtOk_ddtDateKo))
      } else if (!data.ddtCode && data.ddtDate) {
        throw new Error(__(T.error.ddtko_ddtDateOk))
      }

      const request: CustomShippingRequest = {
        configurationId: this.state.configurationId,
        originPlaceCode: this.state.clientCode,
        ddt: this.state.ddtCode,
        ddtDate: this.state.ddtDate,
        attributes: { nar: this.state.nar },
      }
      const response = await InboundShipments.startParcelRI(request)
      if (!response || !response?.header?.parcelCode) throw new Error('No Parcel created') // Never shown to user

      this.state = {
        ...this.state,
        shipmentCode: response?.header?.shippingCode,
        parcelCode: response?.header?.parcelCode,
        destination: data.destination,
      }
      const state = this.state
      navigate(
        '/custom/inbound/:configCode/reading/:parcelCode',
        {
          configCode: this.operation?.code,
          parcelCode: this.state.parcelCode,
        },
        { state }
      )
    } catch (error) {
      showToast({ status: 'error', title: error.message })
    }
  }

  reformatDdtDate = (data) => {
    try {
      return data ? moment(data).format('DD/MM/YYYY') : data
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onBackPress = () => {
    navigate(`/`)
  }

  fetchClientCodes = async () => {
    try {
      this.setState({ places: (await Places.search<TmrPlace>({ limit: 10000, placeType: 'STORE' })) ?? [] })
    } catch (err) {
      showToast({ title: __(T.error.error), description: err?.message ?? err, status: 'error' })
    }
  }

  render() {
    const places = this.state.places

    return (
      <Page title={this.operation?.description ?? 'Inbound'} onBackPress={this.onBackPress}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  label: __(T.misc.client_code),
                  name: 'clientCode',
                  type: 'select',
                  options: places,
                  config: { value: 'code', label: 'description', secondaryLabel: 'code' },
                  focus: true,
                  required: true,
                },
                {
                  label: __(T.misc.ddt_code),
                  name: 'ddtCode',
                  type: 'input',
                  required: false,
                },
                {
                  label: __(T.misc.ddt_date),
                  name: 'ddtDate',
                  type: 'date',
                  required: false,
                },
                {
                  label: __(T.misc.nar),
                  name: 'nar',
                  type: 'input',
                  required: true,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
