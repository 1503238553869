import React, { Component } from 'react'
import { Box, Form, Page } from 'components'
import { showToast } from 'shared/utils'
import { __, T } from 'translations/i18n'
import { navigate } from 'shared/router'
import ProductionOrders from 'api/ProductionOrders'
import CustomItems from 'api/CustomItems'
import { CustomTmrItem, Order } from 'api/types'
import ScalarinoApi from '../../api/Scalarino'

interface State {
  items: CustomTmrItem[]
  productionOrder?: Order
  taglie: string[]
  colori: string[]
}

export default class ChangeCodeManagement extends Component<{}> {
  state: State = {
    items: [],
    taglie: [],
    colori: [],
  }

  async componentDidMount() {}

  submit = async (data) => {
    if (!data.orderCode) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.production_order_required),
        status: 'error',
      })
      return
    }

    const order = await ProductionOrders.get(data.orderCode)
    if (order.length === 0) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.production_order_not_found, { code: data.orderCode }),
        status: 'error',
      })
      return
    }
    this.setState({ productionOrder: order[0] })

    try {
      const items = await CustomItems.getChangeCodeToManage(order[0].code)
      if (items?.length === 0) {
        showToast({
          title: __(T.error.error),
          description: __(T.error.no_item_in_requested_state),
          status: 'error',
        })
        return
      }
      this.setState({ items: items })

      if (this.state.items.length > 0) {
        const modello = this.state.items[0].product.style !== undefined ? this.state.items[0].product.style.value : null
        const listaTaglie = await ScalarinoApi.getTaglie(modello)
        this.setState({ taglie: listaTaglie })
        const listaColori = await ScalarinoApi.getColori(modello)
        this.setState({ colori: listaColori })
      }

      navigate('/change-code/management/association', {}, { state: this.state })
    } catch (e) {
      showToast({ title: __(T.error.error), description: e?.message ?? e, status: 'error' })
    }
  }

  render() {
    return (
      <Page title={__(T.titles.change_code_management)}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  label: __(T.misc.production_order),
                  name: 'orderCode',
                  focus: true,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
