import React, { Component } from 'react'
import { List, Input, Page, Icons, Box, CustomShipmentCountersRow, Button, Spacer, Select } from 'components'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import { getMatchParams, navigate } from 'shared/router'
import { SearchShippingCounters } from 'api/types'
import InboundShipments from 'api/InboundShipments'
import { showToast, uniqueBy } from 'shared/utils'
import { T, __ } from 'translations/i18n'

interface State {
  data: SearchShippingCounters[]
  query: string
  originFilter?: SearchShippingCounters
  origins?: SearchShippingCounters[]
  loading?: boolean
}

export default class Inbound extends Component<{}, State> {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    data: [],
    query: '',
    loading: true,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async (state: 'IN_INBOUND' | 'IN_TRANSIT' = 'IN_INBOUND') => {
    try {
      const data = await InboundShipments.searchShippingCounters({
        parcelCodes: [],
        shippingCodes: [],
        shippingState: state,
      })
      if (this.operation.inboundMode === 'shipment') {
        //GROUP BY FOR SHIPMENT MODE
        this.setState({
          loading: false,
          data: data ?? [],
          origins: uniqueBy(data ?? [], 'originPlace.code'),
        })
      } else {
        this.setState({ loading: false, data: data ?? [] })
      }
    } catch (err) {
      this.setState({ loading: false, data: [] })
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onRowClick = (itm: SearchShippingCounters) => {
    if (this.operation.inboundMode === 'shipment') {
      navigate('/inbound/:configCode/:shippingCode/parcels', {
        configCode: this.operation?.code,
        shippingCode: encodeURIComponent(itm.shippingCode),
      })
    }
  }

  onInputEnter = (input: string) => {
    const { data } = this.state
    this.setState({ query: input })
    const filtered = data.filter(this.filterResults)

    if (filtered.length === 1) {
      this.onRowClick(filtered[0])
    }
  }

  filterResults = (result: SearchShippingCounters) =>
    (result.codiceddt?.toLowerCase().includes(this.state.query.toLowerCase()) ||
      result.tom.toLowerCase().includes(this.state.query.toLowerCase()) ||
      result.parcels?.find((par) => par.code?.toLowerCase().includes(this.state.query.toLowerCase())) !== undefined) &&
    ((this.state.originFilter && result.originPlace.code === this.state.originFilter?.originPlace.code) ||
      !this.state.originFilter)

  render() {
    const { data, loading, origins } = this.state
    const filtered = data.filter(this.filterResults)
    return (
      <Page title={`${this.operation?.description ?? 'Inbound'}`} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Page.TopBar>
            <Input
              startFocus
              style={{ width: 400 }}
              image={<Icons.Barcode />}
              placeholder={__(T.placeholder.search_shipment_parcel_tom)}
              onChange={(query) => this.setState({ query })}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Select
              transparent
              borderColor="transparent"
              containerStyle={{ width: 250 }}
              onSelect={(itm) => this.setState({ originFilter: itm })}
              options={origins ?? []}
              placeholder={__(T.misc.origin)}
              config={{
                value: 'originPlace.code',
                label: 'originPlace.description',
                secondaryLabel: 'originPlace.code',
              }}
            />
            <Box flex />
            <Button title={__(T.misc.show_all)} variant="default" onClick={() => this.fetchData('IN_TRANSIT')} />
            <Button title={__(T.misc.refresh)} onClick={this.fetchData} style={{ marginLeft: 15 }} />
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.misc.shipments_to_receive)} (${filtered.length})`}
            renderItem={(itm) => (
              <CustomShipmentCountersRow
                operation={this.operation}
                type="inbound"
                shipment={itm}
                onRowClick={this.onRowClick}
              />
            )}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
