import React, { Component } from 'react'
import { SearchShippingCounters } from 'api/types'
import { Box, List, ParcelStatus } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import { getDatetime } from 'shared/utils'
import { InboundConfig, OutboundConfig } from 'shared/RemoteConfig'

interface Props {
  shipment: SearchShippingCounters
  onRowClick: (shipmentParcel: SearchShippingCounters) => void
  type: 'inbound' | 'outbound'
  operation?: OutboundConfig | InboundConfig
}
export default class CustomShipmentCountersRow extends Component<Props> {
  render() {
    const { onRowClick, shipment, type, operation } = this.props

    let operationMode = ''
    if (operation && 'inboundMode' in operation && operation.inboundMode) operationMode = operation.inboundMode
    if (operation && 'outboundMode' in operation && operation.outboundMode) operationMode = operation.outboundMode

    const hasChecklist = operation?.hasChecklist === 'yes'

    return (
      <RowItem onClick={() => onRowClick(shipment)}>
        <List.Cell style={{ width: 240 }} label={__(T.misc.ddt_code)} value={shipment.codiceddt} />
        <List.Cell style={{ width: 240 }} label={__(T.misc.shipment)} value={shipment.tom} />
        {operationMode === 'shipment' && (
          <Box style={{ width: 160 }}>
            <Box style={{ fontWeight: 700, marginBottom: 5 }}>{__(T.misc.status)}</Box>
            <ParcelStatus type={type} width={150} status={shipment.state} />
          </Box>
        )}
        {type === 'outbound' && (
          <List.Cell
            style={{ width: 240 }}
            label={__(T.misc.destination)}
            value={shipment?.destinationPlace?.description || shipment?.destinationPlace?.code}
          />
        )}
        {type === 'inbound' && hasChecklist && (
          <List.Cell
            style={{ width: 240 }}
            label={__(T.misc.origin)}
            value={shipment?.originPlace?.description || shipment?.originPlace?.code}
          />
        )}
        <List.Cell style={{ width: 240 }} label={__(T.misc.creation_date)} value={getDatetime(shipment.creationDate)} />
        {operationMode === 'shipment' && (
          <List.Cell
            style={{ maxWidth: 100 }}
            label={__(T.misc.parcels)}
            value={`${shipment.confirmedParcels}/${shipment.expectedParcels}`}
          />
        )}
      </RowItem>
    )
  }
}

const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`
