import React, { Component } from 'react'
import { StatusLabel } from 'components'
import { ShippingDdtState } from 'api/types'

interface Props {
  status: ShippingDdtState
  width?: number
}

export default class DdtStatus extends Component<Props> {
  getPackingListState = () => {
    const { status } = this.props
    return status
  }

  render() {
    const { width } = this.props

    return (
      <StatusLabel width={width} status={this.getPackingListState()} text={this.getPackingListState().toUpperCase()} />
    )
  }
}
