import React, { Component } from 'react'
import { Page, Box, KeyValueRow, Button, Table } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { CancelPackingListRequest, CustomShippingNotice, PackingListFilter, ShippingParcel } from 'api/types'
import { askUserConfirmation, getChoiceDescription, getDatetime, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import PackingLists from 'api/PackingLists'
import PackingListStatus from 'components/PackingListStatus'
import styled from '@emotion/styled'

interface State {
  shippingCode: string
  parcels: ShippingParcel[]
  loading: boolean
  shippingNotice?: CustomShippingNotice
}

export default class PackingListRowDetail extends Component {
  state: State = {
    shippingCode: '',
    parcels: [],
    loading: true,
  }

  packingListFilter: PackingListFilter = {
    shippingCodes: [],
    parcelCodes: [],
    parcelStates: [],
    originPlaces: [],
    destinationPlaces: [],
    packingListStates: [],
    operationId: '',
  }

  cancelPackingListRequest: CancelPackingListRequest = {
    shippingCodes: [],
  }

  sortingFunction = (a: ShippingParcel, b: ShippingParcel) => {
    if (a.code! < b.code!) return 1
    if (a.code! > b.code!) return -1
    return 0
  }

  async componentDidMount() {
    const shippingCode = getMatchParams(this.props).shippingCode
    this.packingListFilter = { ...this.packingListFilter, shippingCodes: [shippingCode] }
    try {
      const detailResponse = await PackingLists.searchDetails(this.packingListFilter)
      this.setState({
        loading: false,
        parcels: detailResponse ? detailResponse[0].parcels.sort(this.sortingFunction) : undefined,
        shippingNotice: detailResponse![0] ?? undefined,
      })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
    this.cancelPackingListRequest = { shippingCodes: [shippingCode] }
  }

  navigateBack = () => navigate('/packinglist/detail')

  setFilter = (filter: string) => this.setState({ filter })

  getOperation = (parcel: ShippingParcel) => {
    return parcel.shippingOperationParcels
      .filter((shipOp) => shipOp.operationType === 'OUTBOUND')
      .flatMap((shipOp) => shipOp.configuration.description)[0]
  }

  getTotalItems = (parcel: ShippingParcel) => {
    return parcel.shippingOperationParcels
      .filter((shipOp) => shipOp.operationType === 'OUTBOUND')
      .map((shipOp) => shipOp.counters.detectedCounter.items)
      .reduce((x, y) => x + y)
  }

  downloadPDFPL = async (shippingNotice: CustomShippingNotice | undefined) =>  {
    try {
      const response = await PackingLists.exportPdf(shippingNotice!.id)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.download = shippingNotice?.packingListId?.toString().concat('.pdf') ?? 'PackingList.pdf'
      link.href = window.URL.createObjectURL(response)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? __(T.error.error),
        status: 'error',
      })
    }
  }

  render() {
    const { loading, shippingNotice, parcels } = this.state
    const plId = shippingNotice?.packingListId ?? ''
    const commessaCode = shippingNotice?.tom
    const choice = shippingNotice?.choice?.code
    const destinationPlace = shippingNotice?.destinationPlace?.description

    const structure = [
      {
        label: __(T.misc.parcel),
        customRender: (parcel: ShippingParcel) => <>{parcel?.code || '---'}</>,
      },
      {
        label: `${__(T.misc.creation_date)}`,
        customRender: (parcel: ShippingParcel) => <>{getDatetime(parcel?.creationDate)}</>,
        flex: 2,
      },
      {
        label: `${__(T.misc.operation)}`,
        customRender: (parcel: ShippingParcel) => <>{this.getOperation(parcel)}</>,
        flex: 2,
      },
      {
        label: `${__(T.misc.quantity, { quantity: shippingNotice?.totalParcels })}`,
        customRender: (parcel: ShippingParcel) => (
          <SmallCounter total={this.getTotalItems(parcel)}>{`${this.getTotalItems(parcel)}`}</SmallCounter>
        ),
        flex: 2,
      },
    ]

    return (
      <Page
        loading={loading}
        title={`${__(T.titles.packing_list)} / ${__(T.misc.parcels)}`}
        onBackPress={() => this.navigateBack()}
        header={{
          details: [
            { label: __(T.misc.packing_list_id), value: plId.toString() },
            { label: __(T.misc.shipment), value: commessaCode },
            { label: __(T.misc.choiche), value: getChoiceDescription(choice) },
            { label: __(T.misc.destination), value: destinationPlace },
          ],
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Box flex />
            {shippingNotice?.packingListState == 'WAIT_FOR_CQ' &&
              <Button
                  loading={loading}
                  style={{ marginLeft: 40, marginRight: 40 }}
                  title={__(T.misc.print)}
                  onClick={async () => this.downloadPDFPL(shippingNotice)}
              />
            }
            <KeyValueRow
              variant="raw"
              style={{ padding: 0 }}
              loading={loading}
              label={__(T.misc.status)}
              value={<PackingListStatus status={shippingNotice?.packingListState ?? '---'} />}
            />
            <KeyValueRow
              variant="raw"
              style={{ padding: 0, marginLeft: 40, marginRight: 40 }}
              loading={loading}
              label={__(T.misc.creation_date)}
              value={getDatetime(shippingNotice?.packingListDate)}
            />
            {shippingNotice?.packingListState && shippingNotice.packingListState != 'CQ_IN_PROGRESS' && (
                <Button
                    loading={loading}
                    title={__(T.misc.cancel_packing_list)}
                    onClick={async () => {
                      if (
                          (await askUserConfirmation(
                              __(T.confirm.confirm_cancel_current_packing_list),
                              __(T.confirm.cancel_current_packing_list)
                          )) ??
                          false
                      ) {
                        try {
                          await PackingLists.cancel(this.cancelPackingListRequest)
                          showToast({
                            title: __(T.misc.success),
                            description: __(T.messages.pl_cancel_success),
                            status: 'success',
                          })
                          this.navigateBack()
                        } catch (err) {
                          showToast({
                            title: __(T.error.error),
                            description: err?.message ?? 'Generic error',
                            status: 'error',
                          })
                        }
                      }
                    }}
                />
            )}
          </Page.TopBar>
          <Table loading={loading} data={parcels} structure={structure} />
        </Page.Content>
      </Page>
    )
  }
}

const SmallCounter = styled(Box)<{
  total?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0px 20px;
  background-color: ${({ total = 0 }) => {
    if (total === 0) return '#FFFFFF'
    if (total > 0) return '#75EBA8'
    return '#75EBA8'
  }};
`
