import { ShippingDdt } from 'api/types'
import { Box, Spacer, KeyValueRow, Modal, Icons } from 'components'
import React, { Component } from 'react'
import { __, T } from 'translations/i18n'
import Button from 'components/Button'
import { getDate } from 'shared/utils'
import styled from "@emotion/styled";

interface Props {
    isOpen: boolean
    data: ShippingDdt
    onClose: () => void
    onConfirm: () => void
    loading?: boolean
    loadingText?: string
}

interface State {
}

export default class ShippingDdtModal extends Component<Props, State> {
    state: State = {}

    render() {
        const { isOpen, data, onConfirm, onClose, loading, loadingText } = this.props
        return loading ?
            <Modal visible={isOpen && loading} onClose={() => {}} size="md" >
                <Box>
                    <Description>{loadingText}</Description>
                </Box>
                <Box row mt={10}>
                    <Icons.Loader style={{ flex: 1 }} />
                </Box>
            </Modal>
            :
            <Modal visible={isOpen && !loading} onClose={onClose} size="xl" title={__(T.titles.shipping_ddt)}>
                <Box>
                    <Box row>
                        <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.ddt_code)} value={data.ddt} />
                        <Spacer />
                        <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.ddt_date)} value={getDate(data.ddtDate)} />
                    </Box>
                    <Spacer />
                    <Box row>
                        <KeyValueRow
                            style={{ flex: 1 }}
                            variant="grey"
                            label={__(T.misc.ddt_origin)}
                            value={data.originPlace.description || data.originPlace.code}
                        />
                        <Spacer />
                        <KeyValueRow
                            style={{ flex: 1 }}
                            variant="grey"
                            label={__(T.misc.ddt_destination)}
                            value={data.destinationPlace.description || data.destinationPlace.code}
                        />
                    </Box>
                    <Spacer />
                    <Box center row mt={15}>
                        <Button title={__(T.misc.confirm)} onClick={() => {
                            this.setState({ loadingDownload: true })
                            onConfirm()
                        }} />
                    </Box>
                </Box>
            </Modal>
    }
}

const Description = styled.label`
  font-weight: 500;
  padding: 25px 15px;
  font-size: 21px;
  text-align: center;
  opacity: 0.5;
`