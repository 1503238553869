import React, { Component } from 'react'
import { StatusLabel } from 'components'
import { T, __ } from 'translations/i18n'

interface Props {
  status: string
  width?: number
}

export default class ProductionOrderCounterStatus extends Component<Props> {
  statuses = {
    NONE: __(T.labels.none),
    CHANGE_REQUESTED: __(T.labels.change_requested),
    CHANGE_AWAIT: __(T.labels.change_await),
    CHANGE_APPROVED: __(T.labels.change_approved),
    CHANGE_PENDING: __(T.labels.change_pending),
  }

  getProductionOrderCounterState = () => {
    const { status } = this.props
    return status
  }

  getStatusLabelText = (): string => {
    const { status } = this.props
    return this.statuses[this.getProductionOrderCounterState()] ?? status
  }

  render() {
    const { width } = this.props

    return (
      <StatusLabel
        width={width}
        status={this.getProductionOrderCounterState()}
        text={this.getStatusLabelText().toUpperCase()}
      />
    )
  }
}
