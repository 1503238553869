import {
  PackingListFilter,
  CustomShippingNotice,
  ShippingParcel,
  PackingListRequest,
  CancelPackingListRequest,
  TmrPlace, CustomShippingParcel,
} from './types'
import api, { responseErrorCheck } from './api'

export default class PackingLists {
  static endpoint = '/packinglists'

  static searchDetails(packingListFilter: PackingListFilter): Promise<CustomShippingNotice[] | undefined> {
    return api
      .post<CustomShippingNotice[]>(`${this.endpoint}/searchDetails`, packingListFilter)
      .then(responseErrorCheck)
  }

  static sendAwait(destinationCode: String, orderCode?: String): Promise<CustomShippingParcel[]> {
    return api
      .get<ShippingParcel[]>(`${this.endpoint}/parcels/sendAwait`, { destinationCode, orderCode })
      .then(responseErrorCheck)
  }

  static searchCounters(packingListFilter: PackingListFilter): Promise<CustomShippingNotice[] | undefined> {
    return api
      .post<CustomShippingNotice[]>(`${this.endpoint}/searchCounters`, packingListFilter)
      .then(responseErrorCheck)
  }

  static preview(packingListRequest: PackingListRequest): Promise<CustomShippingNotice[] | undefined> {
    return api.post<CustomShippingNotice[]>(`${this.endpoint}/preview`, packingListRequest).then(responseErrorCheck)
  }

  static create(packingListRequest: PackingListRequest): Promise<CustomShippingNotice[] | undefined> {
    return api.post<CustomShippingNotice[]>(`${this.endpoint}/create`, packingListRequest).then(responseErrorCheck)
  }

  static cancel(cancelPackingListRequest: CancelPackingListRequest): Promise<void> {
    return api.post<void>(`${this.endpoint}/cancel`, cancelPackingListRequest).then(responseErrorCheck)
  }

  static getPlaces(): Promise<TmrPlace[]> {
    return api.get<TmrPlace[]>(`${this.endpoint}/places`).then(responseErrorCheck)
  }

  static exportPdf(packingListId: string): Promise<any> {
    return api
        .get<any>(`${this.endpoint}/exportPdf/${packingListId}`, undefined, {
          headers: {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/pdf',
          },
          responseType: 'blob',
        })
        .then(responseErrorCheck)
  }
}
