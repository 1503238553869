import React, { Component } from 'react'
import { Form, Page, Box } from 'components'
import { Flaw, MadeFor } from 'api/types'
import { getChoiceOptions, isFirstChoice, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import Flaws from 'api/Flaws'
import MadeFors from 'api/MadeFors'
import {getMatchParams, navigate} from 'shared/router'
import AppStore from 'AppStore'

interface State {
  flaws?: Flaw[]
  madeFors: MadeFor[]
  title: string
  choices : any[]
}
export default class CustomQualityControlCreate extends Component<{}, State> {
  state: State = {
    flaws: [],
    madeFors: [],
    title: '',
    choices: []
  }


  async componentDidMount() {
    const { configCode } = getMatchParams(this.props)
    if (
      AppStore.loggedUser?.place?.attributes &&
      AppStore.loggedUser?.place?.attributes['place.qualityControl.show.madeFor'] === 'true'
    )
      this.fetchMadeFors()
    const options = await getChoiceOptions(true, configCode === 'stireria')
    this.setState({choices: options})
  }

  fetchFlaws = async (choiceCode: string) => {
    try {
      const flaws = isFirstChoice(choiceCode) ? [] : await Flaws.search<Flaw>({ limit: 500, 'choice.code': choiceCode })
      flaws.forEach((flaw) => {
        flaw.description = `${flaw.code} - ${flaw.description}`
      })

      this.setState({ flaws })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  fetchMadeFors = async () => {
    try {
      this.setState({ madeFors: (await MadeFors.search<MadeFor>({ limit: 500 })) ?? [] })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onChoiceChange = async (newValue: any) => {
    if (!newValue || !newValue.id) {
      this.setState({ flaws: [] })
      return
    }
    await this.fetchFlaws(newValue.id)
  }

  submit = async (data) => {
    const { configCode } = getMatchParams(this.props)
    if (!data.choice && !data.tone && !data.madeFor) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.choice_or_tone_or_madefor_required),
        status: 'error',
      })
      return
    }

    const state = {
      choice: data.choice?.id,
      tone: data.tone?.id,
      madeFor: data.madeFor?.code,
    }
    if(configCode === 'topfly') {
      navigate('/items-list/:operationCode', {operationCode: 'quality-control'}, {state})
    }
    if(configCode === 'stireria') {
      navigate('/items-list/:operationCode', {operationCode: 'quality-control-stireria'}, {state})
    }
  }

  render() {
    const { flaws, madeFors, choices } = this.state
    const { configCode } = getMatchParams(this.props)

    const optionsObj = choices.map((opt) => {
      return {
        id: opt.value,
        description: opt.label,
      }
    })
    const tones = Array.from({ length: 21 }, (_, i) => i).map((tone) => {
      return {
        id: tone,
        description: tone == 0 ? __(T.labels.toneUndefined) : tone + '',
      }
    })

    if(configCode === 'topfly'){
     this.state.title = __(T.titles.quality_control)
    }
    if(configCode === 'stireria'){
      this.state.title = __(T.titles.quality_control_stireria)
    }

    const { title } = this.state

    return (
        <Page title={title}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  placeholder: __(T.misc.choiche),
                  name: 'choice',
                  type: 'select',
                  options: optionsObj,
                  onChange: this.onChoiceChange,
                },
                {
                  placeholder: __(T.fields.tone),
                  name: 'tone',
                  type: 'select',
                  options: tones,
                  hide:
                    AppStore.loggedUser?.place?.attributes &&
                    AppStore.loggedUser?.place?.attributes['place.qualityControl.show.tone'] !== 'true',
                },
                {
                  placeholder: __(T.fields.made_for),
                  name: 'madeFor',
                  type: 'select',
                  options: madeFors,
                  config: { value: 'code', label: 'description', secondaryLabel: 'code' },
                  hide:
                    AppStore.loggedUser?.place?.attributes &&
                    AppStore.loggedUser?.place?.attributes['place.qualityControl.show.madeFor'] !== 'true',
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}