import React, { Component } from 'react'
import {
  Box,
  Form, Icons, Image,
  Page,
} from 'components'
import { MadeFor } from 'api/types'
import {getMatchParams, navigate, NavigationOptions} from 'shared/router'
import { __, T } from 'translations/i18n'
import AppStore from 'AppStore'
import MadeFors from 'api/MadeFors'
import { showToast } from 'shared/utils'
import styled from "@emotion/styled";
import {Routes} from "../index";
import RemoteConfig, {InboundConfig} from "../../shared/RemoteConfig";

interface State {
  madeFors: MadeFor[],
  selectedOp?: PrintEnvelopeDashboardButtonProps
}

export default class PrintTagEnvelopeCreate extends Component<{}, State> {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)
  state: State = {
    madeFors: [],
  }

  operations: PrintEnvelopeDashboardButtonProps[] = [{
    name: "EnvelopeTagPrint",
    route: '/print-tag-envelope/:configCode/print',
    routeParams: { configCode: this.operation?.code },
    icon: <Icons.Print />,
    text: __(T.titles.print_tag_envelope),
  },{
    name: "EnvelopeTagRePrint",
    route: '/print-tag-envelope/:configCode/reprint',
    routeParams: { configCode: this.operation?.code },
    icon: <Icons.Reprint />,
    text: __(T.titles.reprint_tag_envelope),
  }]

  PrintEnvelopeDashboardButton = ({ name, route, routeParams, icon, text }: PrintEnvelopeDashboardButtonProps) => {
    const { selectedOp } = this.state
    let isSelected = selectedOp?.name === name
    return (
        <DashboardItemStyle isSelected={isSelected} onClick={(e) => {
          this.setState({ selectedOp: {name, route, routeParams, icon, text} })
        }}>
          {icon && typeof icon !== 'string' && icon}
          {icon && typeof icon === 'string' && <DashImage alt={text} src={icon} />}
          {text}
        </DashboardItemStyle>
    )
  }

  fetchMadeFors = async () => {
    try {
      this.setState({ madeFors: (await MadeFors.search<MadeFor>({ limit: 500 })) ?? [] })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  submit = async (data) => {
    try {
      const { selectedOp } = this.state

      if(!selectedOp)
        throw new Error(__(T.error.print_tag_envelope_oper_not_chosen))

      if (!data.printer)
        throw new Error(__(T.error.print_tag_envelope_printer_not_chosen))

      if (!data.printsNumber)
        throw new Error(__(T.error.print_tag_envelope_print_number_not_chosen))

      const state = {
        madeFor: data.madeFor?.code,
        printer: data.printer.code,
        printsNumber: data.printsNumber.code
      }
      navigate(selectedOp!.route, selectedOp?.routeParams, { state })

    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }

  }

  async componentDidMount() {
    await this.fetchMadeFors()
  }

  render() {
    const { madeFors, selectedOp } = this.state
    return (
      <Page title={__(T.titles.print_tag_envelope_main)} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Box center>
            <DashView>
              {this.operations.map((operation, index) => (
                  <this.PrintEnvelopeDashboardButton key={index} {...operation} />
              ))}
            </DashView>
            <Form
              schema={[
                {
                  placeholder: __(T.titles.number_prints),
                  name: 'printsNumber',
                  type: 'select',
                  options: [{ code: 1, description: "1" },{ code: 2, description: "2" }],
                  config: { value: 'code', label: 'description', secondaryLabel: 'code' },
                  required: true,
                },
                {
                  placeholder: __(T.fields.made_for),
                  name: 'madeFor',
                  type: 'select',
                  options: madeFors,
                  config: { value: 'code', label: 'description', secondaryLabel: 'code' },
                  hide:
                    AppStore.loggedUser?.place?.attributes &&
                    AppStore.loggedUser?.place?.attributes['place.changeChoice.show.madeFor'] !== 'true',
                },
                {
                  placeholder: __(T.titles.select_printer),
                  name: 'printer',
                  type: 'select',
                  options: AppStore.printers ?? [],
                  config: { value: 'code', label: 'description', secondaryLabel: 'code' },
                  required: true,
                  onChange: (printer) => {
                    AppStore.saveDefaultPrinter(printer)
                  }
                }
              ]}
              initialValues={
                AppStore.defaultPrinter ? {printer: AppStore.defaultPrinter } : undefined
              }
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}

type PrintEnvelopeDashboardButtonProps = {
  name: string,
  route: Routes
  routeParams?: any
  navigationOptions?: NavigationOptions
  icon?: any
  text?: string
}

const DashboardItemStyle = styled.div<{
  isSelected?: boolean
}>`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  width: 276px;
  height: 202px;
  background: #ffffff;
  ${({ isSelected }) => {
  if (isSelected) {
    return 'border: 4px solid #ceff00;'
  }
  return 'border: 2px solid #dcdcdc;'
  }};
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px;
  margin: 30px 20px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const DashImage = styled(Image)`
  margin-bottom: 10px;
  max-height: 100px;
  max-width: 100px;
`

const DashView = styled(Box)`
  max-width: 700px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: start;
  padding-top: 50px;
  flex-basis: 150px;
`