import { QualityControl, TmrItem } from 'api/types'
import { T, __ } from 'translations/i18n'
import { CustomUpdateRequest } from 'api/Items'
import CustomItems from 'api/CustomItems'
import { hideModal, openModal, showToast } from 'shared/utils'
import QualityControlModal from 'components/modals/QualityControlModal'
import { navigate } from 'shared/router'

export async function qualityControl(items: TmrItem[], attributes: any, qualityControlType: QualityControl) {
  if (!items || items.length === 0) throw new Error(__(T.messages.list_empty))
  if (!qualityControlType || qualityControlType === 'NONE') throw new Error(__(T.error.quality_control_invalid))

  const request: CustomUpdateRequest = {
    itemIds: items.map((itm) => itm.id),
    choice: attributes?.choice,
    flaw: attributes?.flaw,
    tone: attributes?.tone,
    madeFor: attributes?.madeFor,
  }

  try {
    let info = await CustomItems.getQualityControlInfo(request, qualityControlType)
    openModal({
      id: 'qualityControlModal',
      title: __(T.titles.quality_control),
      size: 'xl',
      body: QualityControlModal({
        data: info,
        qualityControlType: qualityControlType,
        onConfirm: () => updateQualityControl(request, qualityControlType),
        type: 'Topfly'
      }),
    })
  } catch (error) {
    showToast({
      title: __(T.error.error),
      description: error.message ?? 'Generic error',
      status: 'error',
    })
  }
}

export async function updateQualityControl(request: CustomUpdateRequest, qualityControlType: QualityControl) {
  try {
    await CustomItems.updateQualityControl(request, qualityControlType)
    showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
    hideModal('qualityControlModal')
    navigate('/quality-control/:configCode', {configCode:'topfly'})
  } catch (error) {
    showToast({
      title: __(T.error.error),
      description: error.message ?? 'Generic error',
      status: 'error',
    })
  }
}



export async function qualityControlStireria(items: TmrItem[], attributes: any, qualityControlType: QualityControl) {
  if (!items || items.length === 0) throw new Error(__(T.messages.list_empty))
  if (!qualityControlType || qualityControlType === 'NONE') throw new Error(__(T.error.quality_control_invalid))

  const request: CustomUpdateRequest = {
    itemIds: items.map((itm) => itm.id),
    choice: attributes?.choice,
    flaw: attributes?.flaw,
    tone: attributes?.tone,
    madeFor: attributes?.madeFor,
  }

  try {
    let info = await CustomItems.getQualityControlStireriaInfo(request, qualityControlType)

    openModal({
      id: 'qualityControlModal',
      title: __(T.titles.quality_control_stireria),
      size: 'xl',
      body: QualityControlModal({
        data: info,
        qualityControlType: qualityControlType,
        onConfirm: () => updateQualityControlStireria(request, qualityControlType),
        type: 'Stireria'
      }),
    })
  } catch (error) {
    showToast({
      title: __(T.error.error),
      description: error.message ?? 'Generic error',
      status: 'error',
    })
  }
}

export async function updateQualityControlStireria(request: CustomUpdateRequest, qualityControlType: QualityControl) {
  try {
    await CustomItems.updateQualityControlStireria(request, qualityControlType)
    showToast({ title: __(T.misc.success), description: __(T.messages.cq_in_progress), status: 'success' })
    hideModal('qualityControlModal')
    navigate('/quality-control/:configCode', {configCode:'stireria'})
  } catch (error) {
    showToast({
      title: __(T.error.error),
      description: error.message ?? 'Generic error',
      status: 'error',
    })
  }
}

