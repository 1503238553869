import CustomItems from 'api/CustomItems'
import { TmrItem } from 'api/types'
import { navigate } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export async function changeCode(items: TmrItem[]) {
  if (!items || items.length === 0) throw new Error(__(T.messages.list_empty))
  const confirmed = await askUserConfirmation(__(T.confirm.change_code_title), __(T.confirm.change_code_message))
  if (!confirmed) {
    return
  }
  await CustomItems.requestChangeCode(items.map((itm) => itm.id))
  showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
  navigate('/change-code')
}
