import Encodings from 'api/Encodings'
import AppDatas from 'api/AppDatas'
import OperationConfigurations from 'api/OperationConfigurations'
import { navigate } from 'shared/router'
import { getListFromCommaSeparatedString } from './utils'

// TYPES //////////////////////////////////////////////////////////////////////////////////////////////////////

export type OperationConfigType = 'inbound' | 'outbound' | 'encoding' | 'print'
export type OperationConfigClient = 'frontend' | 'mobile' | 'station'

export type EncodingInitialType = 'order' | 'ean' | 'certilogo'

interface OperationConfig {
  id: string
  code: string
  description: string
  operationType: OperationConfigType
  clients: OperationConfigClient[]
  attributes: Record<string, any>
}

interface ShippingConfig extends OperationConfig {
  readingMode: 'rfid' | 'mixed' | 'barcode' // tmr.operation.config.reading.mode
  canConfirmWithMissing: 'no' | 'yes' | 'withWarning' // tmr.operation.config.missing.confirm
  canConfirmWithOverQuantity: 'no' | 'yes' | 'withWarning' // tmr.operation.config.overqty.confirm
  canConfirmWithUnexpected: 'no' | 'yes' | 'withWarning' // tmr.operation.config.unexpected.confirm
  canForceConfirm: 'no' | 'yes' | 'withExpectedReadings' // tmr.operation.config.force.confirm
  removeMode: 'sku' | 'rfid' | 'epc' | 'identifier' | 'none' // tmr.operation.config.remove.mode
  itemWarningStates: string[] // tmr.operation.config.item.warning.states
  itemErrorStates: string[] // tmr.operation.config.item.error.states
  itemIgnoreStates: string[] // tmr.operation.config.item.ignore.states
  itemRequireStates: string[] // tmr.operation.config.item.require.states
  icon?: string
  hasChecklist: 'no' | 'yes'
}

export interface InboundConfig extends ShippingConfig {
  isCustomCampionarioInbound?: 'yes' | 'no' // tmr.stoneisland.operation.config.campionario.inbound
  isIngressoResiInbound?: 'yes' | 'no' //tmr.operation.config.inbound.ingresso.resi
  inboundMode?: 'parcelByParcel' | 'multiParcel' | 'shipment' // tmr.operation.config.inbound.mode
  isCustomGateInbound: 'yes' | 'no'
  isStesiDdtInbound?: 'yes' | 'no' // tmr.stoneisland.operation.config.stesi.ddt.inbound
}

export interface OutboundConfig extends ShippingConfig {
  outboundMode?: 'parcelByParcel' | 'shipment' // tmr.operation.config.outbound.mode
  isCustomDirectTransfer: 'yes' | 'no'
  isCustomDirectTransferTopfly: 'yes' | 'no'
  isRepairMode: 'yes' | 'no'
  isOutboundStesiReplica: 'yes' | 'no'
  isOutboundRiassortimentiAX: 'yes' | 'no' //tmr.operation.config.outbound.ax.riassortimenti
  isRepairReturnMode: 'yes' | 'no' //tmr.operation.config.outbound.isRepairReturnMode
  isPackingList: 'yes' | 'no' //tmr.operation.config.packing.list
  isDdt: 'yes' | 'no' //tmr.operation.config.ddt
  isCustomGateOutbound: 'yes' | 'no' //tmr.stoneisland.operation.config.gate.outbound
  isOutbound78: 'yes' | 'no' //tmr.operation.config.outbound.78
  isOutbound78ColliCross: 'yes' | 'no' //tmr.operation.config.outbound.cross.parcels.78
}

export interface EncodingConfig {
  initialType?: 'custom' | 'identifier' | 'product' // tmr.logistics.encoding.initial.type
  validateProductionOrder?: boolean // tmr.logistics.encoding.productionorder.validate
  enableWrite?: boolean // tmr.logistics.encoding.write.enable
  identifierMatchRegex?: RegExp // tmr.logistics.encoding.identifier.match.regex
}

export interface PrintingConfig extends OperationConfig {
  isPrintTagEnvelope?: 'yes' | 'no' //tmr.operation.config.printTagEnvelope
  envelopeWaitPrintTime: number //tmr.operation.config.printTagEnvelope.time.reprint
  icon?: string
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fakeEncodingOperation = 'FAKE_ENCODING_OPERATION'

export class RemoteConfigBase {
  private operations: OperationConfig[] = []

  defaultTagsBatchInterval?: number

  async load(): Promise<void> {
    try {
      this.fetchAppDatas()
      const operations = await OperationConfigurations.search<OperationConfig>({
        client: 'station',
        orderBy: 'PRIORITY',
      })
      this.operations = operations.map(({ operationType, ...rest }) => ({
        operationType: String(operationType).toLowerCase() as OperationConfigType,
        ...rest,
      }))
      const encodingSettings = await Encodings.settings()
      if (encodingSettings) {
        const encodingOpConfig: OperationConfig = {
          id: fakeEncodingOperation,
          code: fakeEncodingOperation,
          description: fakeEncodingOperation,
          clients: ['station'],
          operationType: 'encoding',
          attributes: encodingSettings,
        }
        this.operations.push(encodingOpConfig)
      }
    } catch (error) {
      console.log(error)
    }
  }

  getAllShippingOperations(): ShippingConfig[] {
    return this.operations
      .filter((op) => op.operationType === 'inbound' || op.operationType === 'outbound')
      .map((op) => this.getOperationConfigByCode(op.code) as ShippingConfig)
  }

  getAllPrintingOperations(): PrintingConfig[] {
    return this.operations
        .filter((op) => op.operationType === 'print')
        .map((op) => this.getOperationConfigByCode(op.code) as PrintingConfig)
  }

  getOperationConfig<T>(code: string): T {
    return this.getOperationConfigByCode(code) as T
  }

  private getOperationConfigByCode(code: string) {
    const opConfig = this.operations.find((o) => o.code === code)
    if (!opConfig) {
      navigate('/') //TODO navigate messa per retrocompatibilità con AppStore, analizzare come migliorare
      throw new Error(`Unable to find OperationConfig for code: ${code}`)
    }

    if (opConfig.operationType === 'outbound') {
      const mapping: OutboundConfig = {
        ...opConfig,
        readingMode: opConfig.attributes['tmr.operation.config.reading.mode'] ?? 'rfid',
        outboundMode: opConfig.attributes['tmr.operation.config.outbound.mode'] ?? 'parcelByParcel',
        canConfirmWithMissing: opConfig.attributes['tmr.operation.config.missing.confirm'] ?? 'withWarning',
        canConfirmWithOverQuantity: opConfig.attributes['tmr.operation.config.overqty.confirm'] ?? 'withWarning',
        canConfirmWithUnexpected: opConfig.attributes['tmr.operation.config.unexpected.confirm'] ?? 'withWarning',
        removeMode: opConfig.attributes['tmr.operation.config.remove.mode'] ?? 'sku',
        canForceConfirm: opConfig.attributes['tmr.operation.config.force.confirm'] ?? 'no',
        itemWarningStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.warning.states']
        ),
        itemErrorStates: getListFromCommaSeparatedString(opConfig.attributes['tmr.operation.config.item.error.states']),
        itemIgnoreStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.ignore.states']
        ),
        itemRequireStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.require.states']
        ),
        icon: opConfig.attributes['tmr.operation.config.custom.icon'],
        isCustomDirectTransfer: opConfig.attributes['tmr.stoneisland.operation.config.direct.transfer'] ?? 'no',
        isCustomDirectTransferTopfly:
          opConfig.attributes['tmr.stoneisland.operation.config.direct.transfer.topfly'] ?? 'no',
        hasChecklist: opConfig.attributes['tmr.operation.config.hasChecklist'] ?? 'no',
        isRepairMode: opConfig.attributes['tmr.operation.config.outbound.isRepairMode'] ?? 'no',
        isOutboundStesiReplica: opConfig.attributes['tmr.operation.config.outbound.stesi.replica'] ?? 'no',
        isOutboundRiassortimentiAX: opConfig.attributes['tmr.operation.config.outbound.ax.riassortimenti'] ?? 'no',
        isRepairReturnMode: opConfig.attributes['tmr.operation.config.outbound.isRepairReturnMode'] ?? 'no',
        isPackingList: opConfig.attributes['tmr.operation.config.packing.list'] ?? 'no',
        isDdt: opConfig.attributes['tmr.operation.config.ddt'] ?? 'no',
        isCustomGateOutbound: opConfig.attributes['tmr.stoneisland.operation.config.gate.outbound'] ?? 'no',
        isOutbound78: opConfig.attributes['tmr.operation.config.outbound.78'] ?? 'no',
        isOutbound78ColliCross: opConfig.attributes['tmr.operation.config.outbound.cross.parcels.78'] ?? 'no',
      }
      return mapping
    }

    if (opConfig.operationType === 'inbound') {
      const mapping: InboundConfig = {
        ...opConfig,
        readingMode: opConfig.attributes['tmr.operation.config.reading.mode'] ?? 'rfid',
        inboundMode: opConfig.attributes['tmr.operation.config.inbound.mode'] ?? 'parcelByParcel',
        canConfirmWithMissing: opConfig.attributes['tmr.operation.config.missing.confirm'] ?? 'withWarning',
        canConfirmWithOverQuantity: opConfig.attributes['tmr.operation.config.overqty.confirm'] ?? 'withWarning',
        canConfirmWithUnexpected: opConfig.attributes['tmr.operation.config.unexpected.confirm'] ?? 'withWarning',
        removeMode: opConfig.attributes['tmr.operation.config.remove.mode'] ?? 'sku',
        canForceConfirm: opConfig.attributes['tmr.operation.config.force.confirm'] ?? 'no',
        itemWarningStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.warning.states']
        ),
        itemErrorStates: getListFromCommaSeparatedString(opConfig.attributes['tmr.operation.config.item.error.states']),
        itemIgnoreStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.ignore.states']
        ),
        itemRequireStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.require.states']
        ),
        icon: opConfig.attributes['tmr.operation.config.custom.icon'],
        isCustomGateInbound: opConfig.attributes['tmr.stoneisland.operation.config.gate.inbound'] ?? 'no',
        hasChecklist: opConfig.attributes['tmr.operation.config.hasChecklist'] ?? 'yes',
        isCustomCampionarioInbound: opConfig.attributes['tmr.stoneisland.operation.config.campionario.inbound'] ?? 'no',
        isIngressoResiInbound: opConfig.attributes['tmr.operation.config.inbound.ingresso.resi'] ?? 'no',
        isStesiDdtInbound: opConfig.attributes['tmr.stoneisland.operation.config.stesi.ddt.inbound'] ?? 'no',
      }
      return mapping
    }

    if (opConfig.operationType === 'encoding') {
      const mapping: EncodingConfig = {
        ...opConfig,
        initialType: opConfig.attributes['tmr.logistics.encoding.initial.type']?.toLowerCase(),
        validateProductionOrder: opConfig.attributes['tmr.logistics.encoding.productionorder.validate']
          ? opConfig.attributes['tmr.logistics.encoding.productionorder.validate']?.toLowerCase() === 'true'
          : true,
        enableWrite: opConfig.attributes['tmr.logistics.encoding.write.enable']
          ? opConfig.attributes['tmr.logistics.encoding.write.enable']?.toLowerCase() === 'true'
          : false,
        identifierMatchRegex: opConfig.attributes['tmr.logistics.encoding.identifier.match.regex']
          ? new RegExp(opConfig.attributes['tmr.logistics.encoding.identifier.match.regex'])
          : undefined,
      }
      return mapping
    }

    if (opConfig.operationType === 'print') {
      const mapping: PrintingConfig = {
        ...opConfig,
        isPrintTagEnvelope: opConfig.attributes['tmr.operation.config.printTagEnvelope'] ?? 'no',
        envelopeWaitPrintTime: !Number.isNaN(Number(opConfig.attributes['tmr.operation.config.printTagEnvelope.time.reprint']))
          ? Number(opConfig.attributes['tmr.operation.config.printTagEnvelope.time.reprint'])
          : 0
      }
      return mapping
    }

    throw new Error(`Undefined operationType for OperationConfig with code: ${code}`)
  }

  getEncodingInitialType(encodingConfig: EncodingConfig): EncodingInitialType {
    let initialType: EncodingInitialType = 'order'
    if (encodingConfig.validateProductionOrder === false) initialType = 'ean'
    if (encodingConfig.initialType === 'custom') initialType = 'certilogo'

    return initialType
  }

  private fetchAppDatas = async () => {
    const [defaultTagsBatchInterval] = await Promise.all([AppDatas.getValue('defaultTagsBatchInterval')])

    this.defaultTagsBatchInterval = Number.isNaN(Number(defaultTagsBatchInterval))
      ? undefined
      : Number(defaultTagsBatchInterval)
  }
}

export default new RemoteConfigBase()
