import React, { Component } from 'react'
import { CustomShippingNotice } from 'api/types'
import { Box, List, PackingListStatus } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import { getChoiceDescription, getDatetime } from 'shared/utils'

interface Props {
  shippingNotice: CustomShippingNotice
  onRowClick: (packinigListId: string) => void
  enableCheckbox?: boolean
  checkboxLabel?: string
  checkboxHandleChange?: (event) => void
  isChecked?: boolean
  showPLId?: boolean
  disabledCheckBox?: boolean
  showPLDestination?: boolean
  showPLNote?: boolean
}
export default class PackingListRow extends Component<Props> {

  ellipsisLabel = (label?: string) => {
      if (!label || label.length < 1) return "---"
      if (label.length <= 10) return label
      return label.slice(0, 10) + "..."
  }

  render() {
    const { shippingNotice, disabledCheckBox, onRowClick, enableCheckbox, checkboxHandleChange, checkboxLabel,
        isChecked, showPLId, showPLDestination, showPLNote} = this.props
    return (
      <RowItem onClick={() => onRowClick(shippingNotice.code ?? '')}>
        {showPLId && (
            <List.Cell
                style={{width: 100}}
                label={__(T.misc.packing_list_id)}
                value={shippingNotice.packingListId.toString() ?? '---'}/>
        )}
        <List.Cell style={{ width: 200, marginRight: 20}} label={__(T.misc.shipment)} value={shippingNotice.tom ?? '---'} />
        <List.Cell
          style={{ width: 100, marginLeft: 20 }}
          label={__(T.misc.choiche)}
          value={getChoiceDescription(shippingNotice.choice) ?? '---'}
        />
        <List.Cell
          style={{ width: 100 }}
          label={__(T.misc.madeFor)}
          value={shippingNotice.madeFor ?? '---'}
        />
        <List.Cell style={{width: 240}} label={__(T.fields.tone)} value={shippingNotice.tone}/>
        { showPLDestination && (
            <List.Cell
                style={{width: 300}}
                label={__(T.misc.destination)}
                value={shippingNotice?.destinationPlace?.description || shippingNotice?.destinationPlace?.code}
            />
        )}
        { showPLNote && (
          <List.Cell
              style={{ width: 240 }}
              label={"Note"}
              value={this.ellipsisLabel(shippingNotice.description)}
              tooltipEnabled={(shippingNotice.description ?? '').length > 10}
              tooltipLabel={shippingNotice.description}
          />
        )}
        {!showPLId && (
            <List.Cell
              style={{ width: 240 }}
              label={__(T.misc.creation_date)}
              value={getDatetime(shippingNotice?.packingListDate)}
            />
        )}

        <Box style={{ width: 200 }}>
          <Box style={{ fontWeight: 700, marginBottom: 5 }}>{__(T.misc.status)}</Box>
          <PackingListStatus width={170} status={shippingNotice.packingListState} />
        </Box>

        {!showPLId && (
            <Box width={100}>
                <SmallCounter>{`${shippingNotice?.totalParcels > 0 ? `${shippingNotice?.totalParcels}` : ''}`}</SmallCounter>
            </Box>
        )}

        {enableCheckbox && checkboxHandleChange && (
          <Box flex mr={10} style={{ alignItems: 'center' }}>
            {checkboxLabel && (
              <Box height={24} style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>
                {checkboxLabel}
              </Box>
            )}
            <Box flex width={100}>
              <CheckBoxStyle
                disabled={disabledCheckBox}
                type="checkbox"
                id={shippingNotice.packingListId.toString()}
                value={shippingNotice.packingListId}
                onClick={(event: any) => {
                  event.stopPropagation()
                }}
                onChange={(event: any) => {
                    checkboxHandleChange(event)
                }}
                checked={isChecked}
                defaultChecked={false}
              />
            </Box>
          </Box>
        )}
      </RowItem>
    )
  }
}

const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0px 20px;
  background-color: ${({ unexpected = 0, detected = 0, expected = 0 }) => {
    if (unexpected > 0) return '#F2B57D'
    if (expected === 0) return '#EDEDED'
    if (detected < expected) return '#EDEDED'
    if (detected === expected) return '#75EBA8'
    if (detected > expected) return '#F2B57D'
    return '#75EBA8'
  }};
`
const CheckBoxStyle = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  transform: scale(1.5);
  cursor: pointer;
  :focus {
    outline: black;
  }
`
