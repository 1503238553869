import React, { Component } from 'react'
import { Box, Button, Icons, Input, List, Page, Spacer } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import {
  ChangeCodeManagement,
  CustomTmrItem,
  Phase,
  PhaseOrderSupplier,
  PhaseOrderSupplierFilter,
} from 'api/types'
import { __, T } from 'translations/i18n'
import CustomItems from 'api/CustomItems'
import { askUserConfirmation, showToast } from 'shared/utils'
import ChangeCodeApprovalRow from '../../components/ChangeCodeApprovalRow'
import ChangeCodeApprovalModal from '../../components/modals/ChangeCodeApprovalModal'
import PhaseOrderSuppliers from '../../api/PhaseOrderSuppliers'

interface State {
  orderCode: string
  orderId: string
  items: CustomTmrItem[]
  filter: string
  loading: boolean
  disableCheckboxes: boolean
  submittedDestination: string
  query: string
  parcelRows: any
  isAllChecked: boolean
  phaseOrderSuppliers: PhaseOrderSupplier[]
  phase: string
  smallestAcceptablePhaseSequence?: number
  madeFor?: string
  showModal: boolean
  dto?: ChangeCodeManagement
}

let checkedList: any = []

export default class PackingListCreate extends Component<{}, State> {
  state: State = {
    orderCode: '',
    orderId: '',
    filter: '',
    loading: false,
    disableCheckboxes: false,
    submittedDestination: '',
    query: '',
    parcelRows: undefined,
    isAllChecked: false,
    phaseOrderSuppliers: [],
    phase: '',
    items: [],
    showModal: false,
    smallestAcceptablePhaseSequence: undefined,
  }

  phaseOrderSupplierFilter: PhaseOrderSupplierFilter = {
    asc: false,
    offset: 0,
    limit: 25,
    orderBy: ['CREATION_DATE'],
    orderId: '',
  }

  navigateBack = () => {
    this.resetState()
    navigate('/change-code/approval')
  }

  async componentDidMount() {
    await this.reloadItems()
    const orderCode = getMatchParams(this.props).orderCode
    this.phaseOrderSupplierFilter = { ...this.phaseOrderSupplierFilter, orderId: this.state.orderId }
    const phaseOrderSuppliers = await PhaseOrderSuppliers.get(this.phaseOrderSupplierFilter)
    this.setState({ loading: false, orderCode, phaseOrderSuppliers }) //order id will be present here
  }

  async reloadPage() {
    this.reloadItems()
    this.setState({ loading: false, showModal: false })
  }

  async reloadItems() {
    const orderCode = getMatchParams(this.props).orderCode
    checkedList = []
    this.setState({ loading: true })
    const items = await CustomItems.getChangeCodeToApprove(orderCode)
    this.setState({ items, orderId: items[0]?.orderId ?? '' })
  }

  onInputEnter = (input: string) => {
    this.setState({ query: input })
  }

  filterResults = (result: CustomTmrItem) => {
    const { query } = this.state
    return result.itemIdentifiers
      .find((idf) => idf.identifierType === 'CertilogoIdentifier')
      ?.code.toString()
      .includes(query)
  }
  handleChange = (event: any) => {
    let { items } = this.state
    let isAllChecked = false
    if (event.target.checked) {
      checkedList.push(event.target.value)
      isAllChecked = checkedList.length === items.length
    } else {
      const idx = checkedList.indexOf(event.target.value)
      checkedList.splice(idx, 1)
    }
    this.setState({ isAllChecked })
  }

  handleAllCheck = (checked: boolean, data: string[]) => {
    this.setState({ disableCheckboxes: true })
    const { items } = this.state
    if (checked) {
      data.forEach((n) => {
        if (!checkedList.includes(n)) checkedList.push(n)
      })
    } else {
      data.forEach((n) => {
        const i = checkedList.indexOf(n)
        checkedList.splice(i, 1)
      })
    }
    this.setState({
      isAllChecked: checkedList.length === items.length,
      disableCheckboxes: false,
    })
  }

  onClickExecutionPhaseChange = () => {
    if (checkedList.length === 0) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.item_not_selected),
        status: 'error',
      })
      return
    }

    const { items, phaseOrderSuppliers } = this.state
    const itemPhases = items.filter((item) => checkedList.includes(item.id)).map((item) => item?.phase?.code)
    const smallestAcceptablePhaseSequence = phaseOrderSuppliers
      .filter((ph) => itemPhases.includes(ph.phase.code) && !ph.skipAvanzamento)
      .sort((a, b) => b.sequenzaAvanzamento - a.sequenzaAvanzamento)[0].sequenzaAvanzamento
    this.setState({ showModal: true, smallestAcceptablePhaseSequence })
  }

  onConfirmExecutionPhaseChange = async (phase?: Phase) => {
    try {
      this.confirmExecutionPhase(phase?.code)
    } catch (e) {
      showToast({ title: __(T.error.error), description: e?.message ?? e, status: 'error' })
    }
  }

  onClickRejectChangeCode = async () => {
    if (checkedList.length === 0) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.item_not_selected),
        status: 'error',
      })
      return
    }
    const { items } = this.state
    if (checkedList.length === 0) {
      return
    }
    try {
      if (await askUserConfirmation(__(T.confirm.confirm_exec_phases), __(T.messages.confirm_rejection_change_code))) {
        const requestData: ChangeCodeManagement = { itemId: checkedList }
        await CustomItems.rejectChangeCode(requestData)
        showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
      } else {
        return
      }
    } catch (e) {
      showToast({ title: __(T.error.error), description: e?.message ?? e, status: 'error' })
    }
    if (items.length === checkedList.length) {
      this.navigateBack()
    } else {
      this.reloadItems()
    }
    this.setState({ loading: false })
  }

  onConfirmApproval = async () => {
    if (checkedList.length === 0) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.item_not_selected),
        status: 'error',
      })
      return
    }

    if (await askUserConfirmation(__(T.confirm.confirm_exec_phases), __(T.messages.confirm_execution_phase_message))) {
      this.confirmExecutionPhase()
    }
  }

  confirmExecutionPhase = async (overridePhase?: string) => {
    const groupBy = (input, key) => {
      return input.reduce((acc, currentValue) => {
        let groupKey = currentValue[key]
        if (!acc[groupKey]) {
          acc[groupKey] = []
        }
        acc[groupKey].push(currentValue)
        return acc
      }, {})
    }

    const groupedItems = groupBy(
      this.state.items
        .filter((item) => checkedList.includes(item.id))
        .map((item) => {
          return { id: item.id, executionPhase: overridePhase ?? item.executionPhase?.code }
        }),
      'executionPhase'
    )

    let requestData: ChangeCodeManagement[] = []
    for (const [key, value] of Object.entries(groupedItems)) {
      const values: any = value
      requestData.push({ itemId: values.map((v) => v.id), phase: key })
    }
    try {
      await CustomItems.approveChangeCode(requestData)
      showToast({ title: __(T.misc.success), description: __(T.messages.exec_phase_success), status: 'success' })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
    await this.reloadPage()
  }

  resetState = async () => {
    checkedList = []
    this.setState({
      orderCode: '',
      orderId: '',
      filter: '',
      loading: false,
      disableCheckboxes: false,
      submittedDestination: '',
      query: '',
      parcelRows: undefined,
      isAllChecked: false,
      phaseOrderSuppliers: [],
      phase: '',
      showModal: false,
      smallestAcceptablePhaseSequence: undefined,
    })
  }

  onClose = () => {
    this.setState({ showModal: false })
  }

  render() {
    const {
      loading,
      disableCheckboxes,
      isAllChecked,
      orderCode,
      items,
      showModal,
      phaseOrderSuppliers,
      smallestAcceptablePhaseSequence,
    } = this.state
    const filtered = items.filter(this.filterResults)

    return (
      <Page
        loading={loading}
        title={`${__(T.titles.approve_change_code)} / ${__(T.misc.approve)}`}
        header={{
          details: [
            {
              label: __(T.misc.production_order),
              value: orderCode,
            },
          ],
        }}
        onBackPress={() => {
          this.navigateBack()
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.misc.certilogo)}
              onChange={(query) => this.setState({ query })}
              image={<Icons.Barcode />}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Box flex />
            <Button
              loading={loading}
              title={isAllChecked ? __(T.misc.unselect_all) : __(T.misc.select_all)}
              variant={'secondary'}
              onClick={() =>
                this.handleAllCheck(
                  !isAllChecked,
                  items.map((n) => n!.id!)
                )
              }
            />
            <Spacer />
            <Button
              variant="severe"
              loading={loading}
              title={__(T.confirm.reject_change_code)}
              onClick={this.onClickRejectChangeCode}
            />
            <Spacer />
            <Button
              variant="error"
              loading={loading}
              title={__(T.confirm.change_exec_phase)}
              onClick={this.onClickExecutionPhaseChange}
            />
            <Spacer />
            <Button loading={loading} title={__(T.confirm.approve_items)} onClick={this.onConfirmApproval} />
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.titles.change_code)}`}
            renderItem={(i) => (
              <ChangeCodeApprovalRow
                disabled={disableCheckboxes}
                items={i}
                checkboxHandleChange={this.handleChange}
                isChecked={checkedList.includes(i.id)}
                checkboxLabel={__(T.misc.select)}
              />
            )}
            data={filtered}
          />
          <ChangeCodeApprovalModal
            title={__(T.misc.approve)}
            onConfirm={this.onConfirmExecutionPhaseChange}
            onClose={this.onClose}
            phaseOrderSuppliers={phaseOrderSuppliers}
            isOpen={showModal}
            smallestAcceptablePhaseSequence={smallestAcceptablePhaseSequence}
          />
        </Page.Content>
      </Page>
    )
  }
}
