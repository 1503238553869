import React, { Component } from 'react'
import {
  AntennaButton,
  EmulateButton,
  GroupedProductList,
  Page,
  PrintNotesModal,
  Spacer,
  TagCounter,
  Box,
  Button,
  TextBox,
  ItemsStatesRow,
  AlertModal,
} from 'components'
import {
  GroupedShipmentProduct,
  Phase,
  ShipmentParcel,
  ShipmentParcelPreview,
  ShippingRequestParcel,
  TmrItem,
  TmrPrinter,
} from 'api/types'
import { navigate, getMatchParams, getLocationState } from 'shared/router'
import { askUserConfirmation, getChoiceDescription, showToast } from 'shared/utils'
import RfidReader from 'shared/RfidReader'
import ShipmentProvider from 'ShipmentProvider'
import Shipments from 'api/Shipments'
import AppStore from 'AppStore'
import testTags from 'shared/testTags'
import Sounds from 'shared/Sounds'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import OutboundShipments from 'api/OutboundShipments'
import CustomShipmentProvider from 'CustomShipmentProvider'

interface State {
  parcel?: ShipmentParcel
  checkListType: 'UPCS' | 'TAGS' | 'ITEMS'
  groupedProducts: GroupedShipmentProduct[]
  showPrintNoteConfirmation: boolean
  printNote?: string
  productionCode?: string
  choice?: string
  tubful?: string
  madeFor?: string
  virginTagsFound: boolean
  phase?: Phase
  replicaOrder?: string
  tone?: string
}

export default class CustomOutboundReading extends Component<{}, State> {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    checkListType: 'ITEMS',
    groupedProducts: [],
    showPrintNoteConfirmation: false,
    virginTagsFound: false,
  }

  async componentDidMount() {
    const { parcelCode } = getMatchParams(this.props)
    const locationState = getLocationState(this.props)
    let parcel!: ShipmentParcel
    try {
      if (!parcelCode && (!locationState?.parcelCode || !locationState?.shipmentCode || !locationState?.destination))
        throw new Error('Invalid data for parcel creation')
      if (!this.operation) throw new Error('Operation configuration not found')
      if (this.operation.readingMode !== 'rfid') throw new Error('Reading mode not supported')
      if (!parcelCode) {
        parcel = ShipmentProvider.createShipmentParcel(
          locationState.parcelCode,
          locationState.shipmentCode,
          locationState.destination
        )
      }

      const { outboundShipmentParcel, checkListType, groupedProducts } =
        this?.operation?.isOutboundStesiReplica === 'yes' || this?.operation?.isOutboundRiassortimentiAX === 'yes'
          ? await CustomShipmentProvider.fetchOutboundShipmentParcels(parcelCode, parcel)
          : await ShipmentProvider.fetchOutboundShipmentParcels(parcelCode, parcel)

      await this.initRfidDevice(checkListType)
      outboundShipmentParcel.header.parcelAttributes.workCode = outboundShipmentParcel.header?.parcelAttributes?.TOM
      this.setState({
        parcel: outboundShipmentParcel,
        checkListType,
        groupedProducts,
        productionCode: outboundShipmentParcel.header?.parcelAttributes?.TOM,
        choice: locationState?.choice ?? outboundShipmentParcel.header?.parcelAttributes?.choice,
        tubful: locationState?.tubful ?? outboundShipmentParcel.header?.parcelAttributes?.tubful,
        madeFor: this.decodeMadeFor(outboundShipmentParcel.header?.parcelAttributes?.madeFor),
        phase: locationState?.phase ?? outboundShipmentParcel.header?.parcelAttributes?.phase,
        replicaOrder: locationState?.replicaOrder ?? outboundShipmentParcel.header?.parcelAttributes?.replicaOrder,
        tone: locationState?.tone ?? outboundShipmentParcel.header?.parcelAttributes?.tone
      })
    } catch (err) {
      showToast({ title: __(T.error.error), description: err.message ?? err, status: 'error' })
      this.navigateBack()
    }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  async initRfidDevice(checkListType) {
    await RfidReader.initialize()

    if (checkListType === 'TAGS') {
      RfidReader.onTagReadCallback = this.handleReceivedTags
    } else {
      RfidReader.onDecodedItemCallback = this.onDecodedItemCallback as any
      RfidReader.setDecodeFunction(this.decodeFunction)
    }
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error('Operation configuration cannot be undefined')

    const { parcel } = this.state

    let parcelPreview: ShipmentParcelPreview | undefined
    if (parcel?.header) {
      parcelPreview = {
        detectedCounter: { items: 0, tags: 0, upcs: 0 },
        expectedCounter: { items: 0, tags: 0, upcs: 0 },
        missingCounter: { items: 0, tags: 0, upcs: 0 },
        unexpectedCounter: { items: 0, tags: 0, upcs: 0 },
        header: parcel?.header,
      }
    }

    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
      parcelDto: parcelPreview,
    })
  }

  //:)
  decodeMadeFor = (madeForCode : string | undefined) => {
    if(!madeForCode) return '---'
    switch (madeForCode) {
      case 'Undefined':
        return __(T.misc.undefined)
        break
      case 'S':
        return __(T.misc.america)
        break
      case 'H':
        return __(T.misc.quebec)
        break
      case 'U':
        return __(T.misc.utah)
        break
      default:
        return madeForCode
    }
  }

  encodeMadeFor = (fullMadeFor : string) => {
    switch (fullMadeFor) {
      case __(T.misc.undefined):
        return 'Undefined'
        break
      case __(T.misc.america):
        return 'S'
        break
      case __(T.misc.quebec):
        return 'H'
        break
      case __(T.misc.utah):
        return 'U'
        break
      default:
        return fullMadeFor
    }
  }

  onDecodedItemCallback = async (itemMap: { [key: string]: TmrItem }) => {
    const { parcel, groupedProducts } = this.state
    let items: TmrItem[] = []
    Object.entries(itemMap).forEach(([epc, item]) => {
      if (item && (item.id || item.product)) items.push(item)
    })

    // play warning if virgin tags found
    if (
      (!this.state.virginTagsFound && items.find((itm) => itm.id === null)) ||
      items.length !== Object.entries(itemMap).length
    ) {
      RfidReader.stop()
      setTimeout(() => {
        RfidReader.tags = RfidReader.tags.filter((t) => t.epc !== Object.keys(itemMap)[0] || t.pending !== false)
      }, 1000)
      this.setState({ virginTagsFound: true }, () => Sounds.fail())
    }

    ShipmentProvider.processOutboundItemsStates(items, this.operation)

    items = items.filter(
      (itm) => itm && !itm.__processedStates?.map((state) => state.processedState)?.includes('IGNORE')
    )

    if (this?.operation?.isOutboundStesiReplica === 'yes' || this?.operation?.isOutboundRiassortimentiAX === 'yes') {
      CustomShipmentProvider.processItemForItemsChecklist(items, [parcel!], groupedProducts)
    } else {
      ShipmentProvider.processItemForItemsChecklist(items, [parcel!], groupedProducts)
    }
    this.forceUpdate()
  }

  handleReceivedTags = () => {}

  clear = () => {
    const { parcel } = this.state
    const groupedProducts =
      this?.operation?.isOutboundStesiReplica === 'yes' || this?.operation?.isOutboundRiassortimentiAX === 'yes'
        ? CustomShipmentProvider.clearAllReceivingReadings([parcel!])
        : ShipmentProvider.clearAllReceivingReadings([parcel!])
    this.setState({ groupedProducts, virginTagsFound: false })
    RfidReader.clear()
  }

  navigateBack = () => {
    if (this?.operation?.isOutboundStesiReplica === 'yes' || this?.operation?.isOutboundRiassortimentiAX === 'yes') {
      navigate('/outbound/:configCode/create', { configCode: this.operation?.code })
      return
    }
    navigate('/outbound/:configCode', { configCode: this.operation?.code })
  }

  printLabelCallback = async (parcel: ShippingRequestParcel, printer?: TmrPrinter, quantity?: number) => {
    if (!printer) {
      this?.operation?.isOutboundStesiReplica !== 'yes' &&
        this?.operation?.isOutboundRiassortimentiAX !== 'yes' &&
        showToast({
          title: __(T.error.error),
          description: __(T.error.printer_not_found),
          status: 'error',
        })
      return
    }
    await Shipments.printLabel(parcel.parcelCode, printer, quantity)
  }

  confirmOutbound = async (
    notes?: string,
    printer?: TmrPrinter,
    quantity?: string,
    weight?: string,
    sizeH?: string,
    sizeL?: string,
    sizeP?: string
  ) => {
    const { parcel } = this.state
    try {
      const printQuantity = Number(quantity)
      if (
        this?.operation?.isOutboundStesiReplica !== 'yes' &&
        this?.operation?.isOutboundRiassortimentiAX !== 'yes' &&
        (Number.isNaN(printQuantity) || printQuantity <= 0)
      ) {
        throw new Error(__(T.error.invalid_print_quantity))
      }

      if (this?.operation?.isOutboundStesiReplica === 'yes' || this?.operation?.isOutboundRiassortimentiAX === 'yes') {
        const { detected: detectedItems, unexpected: unexpectedItems } = ShipmentProvider.getCounters(parcel, 'ITEMS')
        const { detected: detectedTags, unexpected: unexpectedTags } = ShipmentProvider.getCounters(parcel, 'TAGS')
        const detected = detectedItems + detectedTags
        const unexpected = unexpectedItems + unexpectedTags
        const { expected } = ShipmentProvider.getCounters(parcel, 'UPCS')

        if (detected < expected || detected > expected || unexpected > 0) {
          throw new Error(__(T.error.discrepancy_message))
        }
      }

      if (this.operation.isRepairMode === 'yes' && !notes?.trim()) {
        throw new Error(__(T.error.repair_reason_required))
      }

      if (
        this.state.parcel?.detectedItems?.filter((item) =>
          item.__processedStates?.map((state) => state.processedState)?.includes('ERROR')
        ).length
      )
        throw new Error(__(T.error.items_in_error_found))

      if (this?.operation?.isOutboundStesiReplica !== 'yes' && this?.operation?.isOutboundRiassortimentiAX !== 'yes') {
        await AppStore.saveDefaultPrintQuantity(String(printQuantity))
        sizeH && (await AppStore.saveDefaultSizeH(String(sizeH)))
        sizeL && (await AppStore.saveDefaultSizeL(String(sizeL)))
        sizeP && (await AppStore.saveDefaultSizeP(String(sizeP)))
        weight && (await AppStore.saveDefaultWeight(String(weight)))
      }

      const attributes: Record<string, string> = {}
      if (this.state.choice) attributes.choice = this.state.choice
      if (notes) attributes.printNote = notes
      if (weight) attributes.weight = weight
      if (sizeH) attributes.sizeH = sizeH
      if (sizeL) attributes.sizeL = sizeL
      if (sizeP) attributes.sizeP = sizeP
      if (this.state.tubful) attributes.tubful = this.state.tubful
      if (this.state.madeFor && this.state.madeFor != '---') attributes.madeFor = this.encodeMadeFor(this.state.madeFor)
      if (this.state.productionCode) attributes.workCode = this.state.productionCode
      if (this.state.phase?.code) attributes.phase = this.state.phase.code
      if (this.state.replicaOrder) attributes.replicaOrder = this.state.replicaOrder
      if (this.state.tone != null) attributes.tone = this.state.tone
      await ShipmentProvider.confirmOutbound(
        [this.state.parcel!],
        this.operation!.id,
        this.state.parcel!.header.destinationPlace.code,
        {
          attributes: attributes,
        },
        (shippingParcel) => this.printLabelCallback(shippingParcel, printer, printQuantity)
      )
      showToast({ title: __(T.misc.success), description: __(T.messages.outbound_success), status: 'success' })
      this.navigateBack()
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }
  }

  askConfirmationWithNotes = async () => {
    try {
      await RfidReader.stop()
    } catch (error) {
      console.error(error)
    }

    try {
      if (this?.operation?.isOutboundStesiReplica === 'yes' || this?.operation?.isOutboundRiassortimentiAX === 'yes') {
        await this.confirmOutbound()
        return
      }
      this.setState({ showPrintNoteConfirmation: true })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  removeItemFromReadings = (item: TmrItem) => {
    const { parcel, groupedProducts } = this.state
    ShipmentProvider.removeItemsForItemsChecklist([item], [parcel!], groupedProducts)
    const itemIdentifiers = item.itemIdentifiers.map((id) => id.code)
    RfidReader.removeTags(itemIdentifiers)
    this.forceUpdate()
  }

  cancelParcelAx = async () => {
    const { parcel } = this.state
    if (await askUserConfirmation(__(T.titles.cancel_parcel), __(T.messages.are_you_sure_to_cancel_parcel))) {
      try {
        await OutboundShipments.cancelParcelAx(parcel?.header.parcelCode)
        showToast({ title: __(T.misc.success), description: __(T.messages.cancel_success), status: 'success' })
        this.navigateBack()
      } catch (err) {
        showToast({
          title: __(T.error.error),
          description: err?.message ?? 'Generic error',
          status: 'error',
        })
      }
    }
  }

  render() {
    const {
      parcel,
      checkListType,
      groupedProducts,
      showPrintNoteConfirmation,
      productionCode,
      choice,
      tubful,
      madeFor,
      replicaOrder,
      tone
    } = this.state
    const destinationPlace = parcel?.header?.destinationPlace?.description || parcel?.header?.destinationPlace?.code
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(
      parcel,
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )
    const isDirectTransferTopfly = this?.operation?.isCustomDirectTransferTopfly === 'yes'
    let details =
      // eslint-disable-next-line no-nested-ternary
      this?.operation?.isCustomDirectTransfer === 'no'
        ? [
            { label: __(T.misc.parcel), value: parcel?.header.parcelCode },
            { label: __(T.misc.choiche), value: getChoiceDescription(choice) },
            { label: __(T.misc.shipment), value: productionCode },
            {
              label: __(T.misc.destination),
              value: destinationPlace !== '' ? destinationPlace : parcel?.header?.destinationPlace?.code,
            },
          ]
        : isDirectTransferTopfly
        ? [
            {
              label: __(T.misc.parcel),
              value: parcel?.header.parcelCode,
            },
            {
              label: __(T.misc.topfly_order),
              value: replicaOrder,
            },
            {
              label: __(T.misc.choiche),
              value: getChoiceDescription(choice),
            },
            {
              label: __(T.misc.destination),
              value: destinationPlace !== '' ? destinationPlace : parcel?.header?.destinationPlace?.code,
            },
          ]
        : [
            {
              label: __(T.misc.parcel),
              value: parcel?.header.parcelCode,
            },
            {
              label: __(T.misc.shipment),
              value: productionCode,
            },
            {
              label: __(T.misc.destination),
              value: destinationPlace !== '' ? destinationPlace : parcel?.header?.destinationPlace?.code,
            },
          ]

    if (
      this?.operation?.isCustomDirectTransfer === 'no' &&
      AppStore.loggedUser?.place?.attributes?.tintoria === 'true'
    ) {
      details.splice(0, 0, { label: __(T.misc.tubful), value: tubful })
    }

    if (
      this?.operation?.isCustomDirectTransfer === 'no'
    ) {
      details.splice(0, 0, { label: __(T.fields.made_for), value: madeFor })
    }

    if (this?.operation?.isOutboundStesiReplica === 'yes' || this?.operation?.isOutboundRiassortimentiAX === 'yes') {
      details = [
        { label: __(T.misc.parcel), value: parcel?.header.parcelCode },
        {
          label: __(T.misc.destination),
          value: destinationPlace !== '' ? destinationPlace : parcel?.header?.destinationPlace?.code,
        },
      ]
    }

    if (
        this?.operation?.isCustomDirectTransfer === 'no' &&
        AppStore.loggedUser?.place?.attributes?.stireria === 'true'
        && (tone != null)
    ) {
      if(tone == '0') {
        details.splice(0, 0, {label: __(T.fields.tone), value: '---'})
      }
      else {
        details.splice(0, 0, {label: __(T.fields.tone), value: tone})
      }
    }

    return (
      <>
        {this.state.virginTagsFound && (
          <AlertModal
            title={__(T.error.virgin_tags_found)}
            subtitle={__(T.error.virgin_tags_found_check_required)}
            onClose={() => this.setState({ virginTagsFound: false })}
          />
        )}
        <Page
          title={`${this.operation?.description ?? 'Outbound'}`}
          onBackPress={this.navigateBack}
          header={{
            details: details,
          }}
          enableEmulation
        >
          <Page.Sidebar>
            <TagCounter detected={detected} expected={expected} unexpected={unexpected} />
            <Spacer />
            <AntennaButton onClear={this.clear} hideClear={detected === 0} />
            <Spacer />
            {this.state.virginTagsFound && <TextBox text="Tag Vergini trovati" type="warning" />}
            <EmulateButton title="Emulate tags" onPress={() => RfidReader.emulateTags(testTags.parcel1)} />
            <Box flex />
            {this.operation.isOutboundRiassortimentiAX === 'yes' && (
              <Button variant="secondary" title={__(T.misc.cancel_parcel)} onClick={this.cancelParcelAx} />
            )}
            <Spacer />
            <Button title={__(T.misc.confirm_parcel)} onClick={this.askConfirmationWithNotes} />
          </Page.Sidebar>
          <Page.Content>
            <ItemsStatesRow
              items={parcel?.detectedItems as TmrItem[]}
              onItemDeleteCallback={this.operation.removeMode !== 'none' ? this.removeItemFromReadings : undefined}
            />
            <GroupedProductList
              data={groupedProducts}
              noChecklist={
                this?.operation?.isOutboundStesiReplica !== 'yes' &&
                this?.operation?.isOutboundRiassortimentiAX !== 'yes'
              }
            />
          </Page.Content>
          {showPrintNoteConfirmation && (
            <PrintNotesModal
              title="Note"
              onClose={() => {
                this.setState({ showPrintNoteConfirmation: false })
              }}
              onConfirm={this.confirmOutbound}
            />
          )}
        </Page>
      </>
    )
  }
}
