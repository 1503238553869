import TmrBaseResource from "./TmrBaseResource";
import api, {responseErrorCheck} from "./api";

export default class ProductionOrders extends TmrBaseResource {
    static endpoint = '/productionOrders'

    static get(code: string): any {
        return api
            .get<any[]>(`productionOrders?code=${code}`)
            .then(responseErrorCheck)
            .then((res) => res)
    }
}
