import { QualityControl, TmrItem } from 'api/types'
import { Header } from 'components/Page'
import { navigate } from 'shared/router'
import { T, __ } from 'translations/i18n'
import { getChoiceDescription } from 'shared/customUtils'
import { changeCode } from './ChangeCodeExtensions'
import { changeChoice } from './ChangeChoiceExtensions'
import { confirmTest1 } from './Test1Extensions'
import { confirmTest2 } from './Test2Extensions'
import { customTransferToZone } from './TransferToZoneExtensions'
import { updateChangeCodeRequest } from './UpdateChangeCodeExtensions'
import { qualityControl, qualityControlStireria } from './QualityControlExtensions'

export default class SimpleItemsListProvider {
  static getPageTitle(operationCode: string) {
    switch (operationCode) {
      case 'test-1':
        return 'Test 1'
      case 'test-2':
        return 'Test 2'
      case 'change-code-request':
        return __(T.titles.change_code)
      case 'change-choice':
        return __(T.titles.change_choice)
      case 'quality-control':
        return __(T.titles.quality_control)
      case 'quality-control-stireria':
        return __(T.titles.quality_control_stireria)
      case 'transfer-to-zone':
        return __(T.titles.transfer_to_zone)
      case 'change-code-update':
        return __(T.titles.update_change_code_request)
      default:
        return __(T.misc.items)
    }
  }

  static getMainActionButtonLabel(operationCode: string) {
    switch (operationCode) {
      case 'test-1':
        return 'Confirm test 1'
      case 'test-2':
        return 'Confirm test 2'
      case 'change-code-request':
        return __(T.misc.request_code_change)
      case 'change-code-update':
        return __(T.misc.update_request)
      default:
        return __(T.misc.confirm)
    }
  }

  static getSecondaryActionButtonLabel(operationCode: string) {
    switch (operationCode) {
      case 'quality-control':
        return __(T.misc.confirm_lot)
      case 'quality-control-stireria':
        return __(T.misc.confirm_lot)
      default:
        return undefined
    }
  }

  static getTertiaryActionButtonLabel(operationCode: string) {
    switch (operationCode) {
      case 'quality-control':
        return __(T.misc.confirm_variant_lot)
      case 'quality-control-stireria':
        return __(T.misc.confirm_variant_lot)
      default:
        return undefined
    }
  }

  static getMainAction(operationCode: string, items: TmrItem[], locationState?: any) {
    switch (operationCode) {
      case 'test-1':
        return confirmTest1(items, locationState)
      case 'test-2':
        return confirmTest2(items)
      case 'change-code-request':
        return changeCode(items)
      case 'change-choice':
        return changeChoice(items, locationState)
      case 'quality-control':
        let type: QualityControl = 'SINGLE_CHECK'
        return qualityControl(items, locationState, type)
      case 'quality-control-stireria':
        let typeStireria: QualityControl = 'SINGLE_CHECK'
        return qualityControlStireria(items, locationState, typeStireria)
      case 'transfer-to-zone':
        return customTransferToZone(items, locationState)
      case 'change-code-update':
        return updateChangeCodeRequest(items)
      default:
        return new Promise((resolve, reject) => {
          resolve(true)
        })
    }
  }

  static getSecondaryAction(operationCode: string, items: TmrItem[], locationState?: any) {
    switch (operationCode) {
      case 'quality-control':
        let type: QualityControl = 'LOT_CHECK'
        return qualityControl(items, locationState, type)
      case 'quality-control-stireria':
        let typeStireria: QualityControl = 'LOT_CHECK'
        return qualityControlStireria(items, locationState, typeStireria)
      default:
        return new Promise((resolve, reject) => {
          resolve(true)
        })
    }
  }

  static getTertiaryAction(operationCode: string, items: TmrItem[], locationState?: any) {
    switch (operationCode) {
      case 'quality-control':
        let type: QualityControl = 'COLOR_LOT_CHECK'
        return qualityControl(items, locationState, type)
      case 'quality-control-stireria':
        let typeStireria: QualityControl = 'COLOR_LOT_CHECK'
        return qualityControlStireria(items, locationState, typeStireria)
      default:
        return new Promise((resolve, reject) => {
          resolve(true)
        })
    }
  }

  // eslint-disable-next-line consistent-return
  static getOnBackPressAction(operationCode: string) {
    switch (operationCode) {
      case 'test-1':
        return () => {
          console.log('on back test 1')
          navigate('/')
        }
      case 'test-2':
        return () => {
          console.log('on back test 2')
          navigate('/')
        }
      case 'change-code-request':
        return () => {
          navigate('/change-code')
        }
      case 'change-code-update':
        return () => {
          navigate('/change-code')
        }
      case 'change-choice':
        return () => {
          navigate('/change-choice')
        }
      case 'quality-control/':
        return () => {
          navigate('/quality-control/:configCode', { configCode: 'topfly' })
        }
      case 'quality-control-stireria':
        return () => {
          navigate('/quality-control/:configCode', { configCode: 'stireria' })
        }
      case 'transfer-to-zone':
        return () => {
          navigate('/transfer-to-zone')
        }
    }
  }

  static getHideRowParcelCode(operationCode: string) {
    switch (operationCode) {
      case 'transfer-to-zone':
        return true
      default:
        return false
    }
  }

  static getPageHeader(operationCode: string, locationState?: any): Header | undefined {
    switch (operationCode) {
      case 'test-1':
        return { details: [{ label: 'Test Label', value: locationState.testParam }] }
      case 'test-2':
        return undefined
      case 'quality-control':
      case 'change-choice': {
        const header: Header = { details: [] }
        if (locationState.choice)
          header.details?.push({ label: __(T.misc.choiche), value: getChoiceDescription(locationState.choice) })
        if (locationState.flaw) header.details?.push({ label: __(T.fields.flaw_reason), value: locationState.flaw })
        if (locationState.tone != null) header.details?.push({ label: __(T.fields.tone), value: locationState.tone })
        if (locationState.madeFor) header.details?.push({ label: __(T.fields.made_for), value: locationState.madeFor })
        return header.details?.length ? header : undefined
      }
      case 'transfer-to-zone': {
        const header: Header = { details: [] }
        if (locationState.destination)
          header.details?.push({ label: __(T.misc.destination), value: locationState.destination.description })
        if (locationState.tone != null) header.details?.push({ label: __(T.fields.tone), value: locationState.tone })
        if (locationState.madeFor) header.details?.push({ label: __(T.fields.made_for), value: locationState.madeFor })
        return header.details?.length ? header : undefined
      }

      case 'quality-control-stireria':
      case 'change-choice': {
        const header: Header = { details: [] }
        if (locationState.choice)
          header.details?.push({
            label: __(T.misc.choiche),
            value: locationState.choice === 'prima' ? 'CQ OK' : getChoiceDescription(locationState.choice),
          })
        if (locationState.flaw) header.details?.push({ label: __(T.fields.flaw_reason), value: locationState.flaw })
        if (locationState.tone != null) header.details?.push({ label: __(T.fields.tone), value: locationState.tone })
        if (locationState.madeFor) header.details?.push({ label: __(T.fields.made_for), value: locationState.madeFor })
        return header.details?.length ? header : undefined
      }
      case 'transfer-to-zone': {
        const header: Header = { details: [] }
        if (locationState.destination)
          header.details?.push({ label: __(T.misc.destination), value: locationState.destination.description })
        if (locationState.tone != null) header.details?.push({ label: __(T.fields.tone), value: locationState.tone })
        if (locationState.madeFor) header.details?.push({ label: __(T.fields.made_for), value: locationState.madeFor })
        return header.details?.length ? header : undefined
      }
      default:
        return undefined
    }
  }
}
