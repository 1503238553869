import React, { Component } from 'react'
import { ShipmentParcel } from 'api/types'
import { __, T } from 'translations/i18n'
import { OutboundConfig, InboundConfig } from 'shared/RemoteConfig'
import { CustomSmallTagCounter } from 'components'
import Box from './Box'
import Button from './Button'
import ParcelStatus from './ParcelStatus'
import { Spacer } from './Various'

interface Props {
  parcel: ShipmentParcel
  openReadParcel?: (parcel: ShipmentParcel) => void
  openDetailParcel?: (parcel: ShipmentParcel) => void
  operation: InboundConfig | OutboundConfig
}
export default class ParcelSectionHeader extends Component<Props> {
  render() {
    const { parcel, openReadParcel, openDetailParcel, operation } = this.props
    const detected = parcel.groupedProducts?.reduce((sum, prod) => sum + prod.detected, 0)
    const expected = parcel.groupedProducts?.reduce((sum, prod) => sum + prod.expected, 0)
    const unexpected = parcel.groupedProducts?.reduce((sum, prod) => sum + prod.unexpected, 0)
    return (
      <Box vcenter row flex pr={20}>
        {parcel.header?.parcelCode ?? 'No parcel code provided'}
        <Spacer />
        <ParcelStatus type={operation.operationType} status={parcel.header?.parcelState ?? '---'} />
        <Box flex />
        <CustomSmallTagCounter detected={detected} unexpected={unexpected} expected={expected} />
        {'inboundMode' in operation &&
          parcel.header?.parcelState === 'IN_INBOUND' &&
          false && ( // TODO: enable to add reading page
            <Button
              style={{ paddingRight: 20, paddingLeft: 20 }}
              size="medium"
              title={__(T.misc.receive)}
              onClick={() => openReadParcel?.(parcel)}
            />
          )}
        {'outboundMode' in operation && (
          <Button
            variant="secondary"
            style={{ paddingRight: 20, paddingLeft: 20 }}
            size="medium"
            title={__(T.misc.detail)}
            onClick={() => openDetailParcel?.(parcel)}
          />
        )}
      </Box>
    )
  }
}
