import React, { Component } from 'react'
import {
  Box,
  Button,
  ChangeCodeManagementModal,
  ChangeCodeManagementRow,
  Icons,
  Input,
  List,
  Page,
  Spacer,
} from 'components'
import { getLocationState, navigate, reloadApp } from 'shared/router'
import { ChangeCodeManagement, CustomTmrItem, Order } from 'api/types'
import { __, T } from 'translations/i18n'
import CustomItems from 'api/CustomItems'
import ScalarinoApi from 'api/Scalarino'
import { showToast } from 'shared/utils'

interface State {
  productionOrder: Order
  items: CustomTmrItem[]
  filter: string
  loading: boolean
  disableCheckboxes: boolean
  submittedDestination: string
  query: string
  parcelRows: any
  isAllChecked: boolean
  madeFor?: string
  taglie: string[]
  colori: string[]
  showModal: boolean
  dto?: ChangeCodeManagement
}

let checkedList: any = []

export default class PackingListCreate extends Component<{}, State> {
  state: State = {
    filter: '',
    loading: true,
    disableCheckboxes: false,
    submittedDestination: '',
    query: '',
    parcelRows: undefined,
    isAllChecked: false,
    productionOrder: getLocationState(this.props).productionOrder,
    items: [],
    taglie: getLocationState(this.props).taglie,
    colori: getLocationState(this.props).colori,
    showModal: false,
  }

  navigateBack = () => {
    this.resetState()
    navigate('/change-code/management')
  }

  async componentDidMount() {
    await this.reloadItems()
  }

  async reloadItems() {
    checkedList = []
    this.setState({ loading: true, showModal: false })
    const items = await CustomItems.getChangeCodeToManage(this.state.productionOrder.code)
    this.setState({ loading: false, items: items })
  }

  onInputEnter = (input: string) => {
    this.setState({ query: input })
  }

  filterResults = (result: CustomTmrItem) => {
    const { query } = this.state
    return result.itemIdentifiers
      .find((idf) => idf.identifierType === 'CertilogoIdentifier')
      ?.code.toString()
      .includes(query)
  }
  handleChange = (event: any) => {
    let { items } = this.state
    let isAllChecked = false
    if (event.target.checked) {
      checkedList.push(event.target.value)
      isAllChecked = checkedList.length === items.length
    } else {
      const idx = checkedList.indexOf(event.target.value)
      checkedList.splice(idx, 1)
    }
    this.setState({ isAllChecked })
  }

  handleAllCheck = (checked: boolean, data: string[]) => {
    this.setState({ disableCheckboxes: true })
    const { items } = this.state
    if (checked) {
      data.forEach((n) => {
        if (!checkedList.includes(n)) checkedList.push(n)
      })
    } else {
      data.forEach((n) => {
        const i = checkedList.indexOf(n)
        checkedList.splice(i, 1)
      })
    }
    this.setState({
      isAllChecked: checkedList.length === items.length,
      disableCheckboxes: false,
    })
  }

  onClickConfirm = () => {
    if (checkedList.length === 0) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.item_not_selected),
        status: 'error',
      })
    } else {
      this.setState({ showModal: true })
    }
  }

  onConfirm = async (color?: string, size?: string) => {
    try {
      const requestData: ChangeCodeManagement = { itemId: checkedList, color: color, size: size }
      await CustomItems.manageChangeCode(requestData)
      showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
    } catch (e) {
      showToast({ title: __(T.error.error), description: e?.message ?? e, status: 'error' })
    }
    this.reloadItems()
  }

  resetState = async () => {
    checkedList = []
    this.setState({
      filter: '',
      loading: false,
      disableCheckboxes: false,
      submittedDestination: '',
      query: '',
      parcelRows: undefined,
      isAllChecked: false,
      productionOrder: getLocationState(this.props).productionOrder,
      items: [],
      taglie: [],
      colori: [],
      showModal: false,
    })
  }

  onClose = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { loading, disableCheckboxes, isAllChecked, productionOrder, items, taglie, colori, showModal } = this.state
    const filtered = items.filter(this.filterResults)

    return (
      <Page
        loading={loading}
        title={`${__(T.titles.change_code_management) ?? 'Packing List'} / ${__(T.misc.association)}`}
        header={{
          details: [
            {
              label: __(T.misc.production_order),
              value: productionOrder.code,
            },
          ],
        }}
        onBackPress={() => {
          checkedList = []
          this.navigateBack()
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.misc.certilogo)}
              onChange={(query) => this.setState({ query })}
              image={<Icons.Barcode />}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Box flex />
            <Button
              loading={loading}
              title={isAllChecked ? __(T.misc.unselect_all) : __(T.misc.select_all)}
              variant={'secondary'}
              onClick={() =>
                this.handleAllCheck(
                  !isAllChecked,
                  items.map((n) => n!.id!)
                )
              }
            />
            <Spacer />
            <Button loading={loading} title={__(T.confirm.change_items)} onClick={this.onClickConfirm} />
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.titles.change_code)}`}
            renderItem={(i) => (
              <ChangeCodeManagementRow
                disabled={disableCheckboxes}
                items={i}
                checkboxHandleChange={this.handleChange}
                isChecked={checkedList.includes(i.id)}
                checkboxLabel={__(T.misc.select)}
              />
            )}
            data={filtered}
          />
          <ChangeCodeManagementModal
            title={__(T.misc.manage)}
            onConfirm={this.onConfirm}
            onClose={this.onClose}
            sizes={taglie}
            colors={colori}
            isOpen={showModal}
          />
        </Page.Content>
      </Page>
    )
  }
}
