import React, { Component } from 'react'
import { AntennaButton, ItemList, Page, Spacer, TagCounter, TextBox } from 'components'
import { CustomTmrItem } from 'api/types'
import { __, T } from 'translations/i18n'
import { getLocationState, getMatchParams, navigate } from 'shared/router'
import RfidReader from 'shared/RfidReader'
import { showToast } from 'shared/utils'
import CustomItems from 'api/CustomItems'
import Sounds from 'shared/Sounds'
import Countdown, { zeroPad } from 'react-countdown'
import AppStore from 'AppStore'
import { CountdownRenderProps } from 'react-countdown/dist/Countdown'
import Items from 'api/Items'
import RemoteConfig, { PrintingConfig } from "../../shared/RemoteConfig";

interface State {
  itemList: CustomTmrItem[],
  autoStart: boolean
}

export default class PrintTagEnvelope extends Component<{}, State> {
  readedItem?: string
  operation = RemoteConfig.getOperationConfig<PrintingConfig>(getMatchParams(this.props).configCode)

  state: State = {
    itemList: [],
    autoStart: false
  }

  renderCountdown = (props: CountdownRenderProps) => {
    const { itemList, autoStart } = this.state
    if (AppStore.envelopeNextPrintDate && props.api.isStopped()) props.api.start()
    if (props.api.isCompleted()) {
      // Render a completed state
      return (
        <AntennaButton
          onClear={this.clear}
          hideClear={itemList.length === 0}
          onStart={this.onStartAntennaCallback}
          decodeFunction={this.decodeFunction}
          onItemDecoded={this.onDecodedItemCallback}
          autoStart={autoStart}
        />
      )
    }
    // Render a countdown
    return (
      <>
        <Spacer />
        <TextBox
          text={`${__(T.messages.waiting_next_print) + zeroPad(props.hours)}:${zeroPad(props.minutes)}:${zeroPad(
            props.seconds
          )}`}
          type="warning"
        />
      </>
    )
  }

  navigateBack = () => {
    navigate('/print-tag-envelope/:configCode', {configCode: this.operation?.code})
  }

  decodeFunction = async (epcs: string[]) => {
    try {
      let result
      const epc = epcs.find((value, index) => index === 0)
      if (!this.readedItem && !!epc) {
        this.readedItem = epc
        result = await Items.batchDecode([epc])
      }
      return result
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
      await this.stopAntenna()
      Sounds.error()
    }
  }

  onDecodedItemCallback = async (itemMap, tag) => {
    try {
      const { itemList } = this.state
      const locationState = getLocationState(this.props)

      const items: CustomTmrItem[] = Object.values<CustomTmrItem>(itemMap)
      if (items.length !== 1 || this.readedItem !== tag.epc) throw new Error(__(T.error.too_many_products_read))

      const item = itemMap[tag.epc]

      if (!item) throw new Error(__(T.misc.unknown_tag))
      if (!item.id && (item.product || item.epc)) throw new Error(__(T.error.tag_unassociated))
      if (!item.id && !item.product) throw new Error(__(T.misc.unknown_product))
      if (item.printCount === 0) throw new Error(__(T.error.print_tag_envelope_reprint_not_print))

      await CustomItems.printEnvelopeLabel(item.id, locationState.madeFor, locationState.printer, locationState.printsNumber)
      itemList.unshift(item)

      const waitTime = await AppStore.getEnvelopeWaitPrintTime()
      await AppStore.saveEnvelopeNextPrintDate(Date.now() + (waitTime ?? 10) * 1000)

      showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
      await this.stopAntenna()
      this.setState({ itemList })
      Sounds.next()
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
      await this.stopAntenna()
      Sounds.error()
    }
  }

  onStartAntennaCallback = () => {
    const { autoStart } = this.state
    this.resetOperation()
    if (!autoStart) this.setState({ autoStart: true })
  }

  clear = () => {
    this.resetOperation()
    this.setState({ itemList: [] })
  }

  resetOperation = () => {
    RfidReader.clear()
    this.readedItem = undefined
  }

  stopAntenna = async () => {
    if (RfidReader.isReading()) await RfidReader.stop()
  }

  componentWillUnmount = async () => {
    await this.stopAntenna()
    this.resetOperation()
  }

  async componentDidMount() {
    try {
      await this.stopAntenna()
      this.resetOperation()
      await AppStore.saveEnvelopeWaitPrintTime(this.operation.envelopeWaitPrintTime)
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }
  }

  render() {
    const { itemList } = this.state
    const locationState = getLocationState(this.props)
    const madeFor: string = locationState.madeFor
    const printer: string = locationState.printer
    const printsNumber: string = locationState.printsNumber
    return (
      <Page
        title={__(T.titles.reprint_tag_envelope)}
        header={{
          details: [
            { label: __(T.fields.made_for), value: madeFor },
            { label: __(T.misc.printer), value: printer },
            { label: __(T.titles.number_prints), value: printsNumber },
          ],
        }}
        enableEmulation
        onBackPress={() => {
          this.navigateBack()
        }}
      >
        <Page.Sidebar>
          <TagCounter detected={itemList.length} />
          <Countdown
            date={AppStore.envelopeNextPrintDate ?? Date.now()}
            renderer={this.renderCountdown}
            autoStart={false}
          />
        </Page.Sidebar>

        <Page.Content>
          <ItemList items={itemList} showCounter />
        </Page.Content>
      </Page>
    )
  }
}
