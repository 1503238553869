import React, { Component } from 'react'
import {
  Page,
  AntennaPlaceholder,
  Box,
  Image,
  AntennaBottomControl,
  RepairsModal,
  ItemBox,
  ActionsBox,
} from 'components'
import { navigate } from 'shared/router'
import { TmrItem } from 'api/types'
import styled from '@emotion/styled'
import RfidReader from 'shared/RfidReader'
import { T, __ } from 'translations/i18n'
import AppStore from 'AppStore'
import { showToast } from 'shared/utils'
import CustomItems from 'api/CustomItems'
import { Action } from 'components/ActionsBox'
import Sounds from 'shared/Sounds'

interface State {
  item?: TmrItem
  loading?: boolean
  showConfirmRepairsModal: boolean
  error: boolean
  errorMessage?: string
}

export default class CustomRepair extends Component<{}, State> {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showConfirmRepairsModal: false,
      error: false,
    }
  }

  async componentDidMount() {
    if ((!AppStore.workstations || AppStore.workstations.length === 0) && !AppStore.emulation) {
      navigate('/login')
      showToast({ title: __(T.error.error), description: __(T.messages.no_workstations_found), status: 'error' })
      return
    }
    try {
      this.setState({ loading: true })
      await RfidReader.initialize()
      RfidReader.onDecodedItemCallback = this.onDecodedItemCallback
      this.resetOperation()
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
      this.setState({ loading: false })
    }
  }

  onDecodedItemCallback = async (item) => {
    try {
      if (this.state.item) throw new Error(__(T.error.too_many_products_read))
      if (!item) throw new Error(__(T.misc.unknown_tag))
      if (!item.id && (item.product || item.epc)) throw new Error(__(T.error.tag_unassociated))
      if (!item.id && !item.product) throw new Error(__(T.misc.unknown_product))

      this.setState({ item: item, error: false, errorMessage: undefined })
      Sounds.next()
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
      await RfidReader.stop()
      this.setState({ error: true, showConfirmRepairsModal: false, loading: false, errorMessage: error.message })
    }
  }

  resetOperation = () => {
    RfidReader.clear()
    if (!RfidReader.isReading()) RfidReader.start()
    this.setState({
      item: undefined,
      showConfirmRepairsModal: false,
      loading: false,
      error: false,
      errorMessage: undefined,
    })
  }

  sendRepairRequest = async (reason: string) => {
    const { item } = this.state
    try {
      if (!item?.id) throw new Error(__(T.error.not_found))
      if (!reason || !reason.trim()) throw new Error(__(T.error.repair_reason_required))

      await CustomItems.requestRepair({ itemId: item?.id, reason })
      showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
      this.resetOperation()
    } catch (error) {
      if ((error.message as string).includes('SHIPPING_ERROR.ITEM_ALREADY_PRESENT')) {
        showToast({ title: __(T.error.error), description: __(T.error.repair_item_already_pending), status: 'error' })
      } else {
        showToast({ title: __(T.error.error), description: error.message, status: 'error' })
      }
      this.setState({ showConfirmRepairsModal: false })
    }
  }

  openConfirmModal = () => {
    this.setState({ showConfirmRepairsModal: true })
  }

  render() {
    const { item, loading, showConfirmRepairsModal, error, errorMessage } = this.state

    const actions: Action[] = [
      {
        label: error ? __(T.misc.retry) : __(T.misc.report_repair),
        callback: error ? this.resetOperation : this.openConfirmModal,
      },
    ]

    if (!error) actions.push({ label: __(T.misc.cancel), callback: this.resetOperation, buttonVariant: 'default' })

    return (
      <Page
        title={__(T.titles.repairs)}
        enableEmulation
        onBackPress={() => {
          if (RfidReader.isReading()) RfidReader.stop()
          navigate('/')
        }}
      >
        <Page.Content bgGrey>
          {!item && !loading && !error && (
            <Box flex center vcenter>
              <Box pb={200}>
                <AntennaPlaceholder />
              </Box>
              <AntennaBottomControl style={{ backgroundColor: 'white', padding: 20, height: 100 }} />
            </Box>
          )}
          {((!loading && item) || error) && (
            <Box flex center row style={{ alignItems: 'flex-start' }}>
              {!error && <ItemBox item={item} />}
              <ActionsBox actions={actions} containerStyle={{ marginLeft: 30 }} errorMessage={errorMessage} />
            </Box>
          )}
          {loading && (
            <RightContainerPlaceHolder style={{ flexDirection: 'column' }}>
              <Image style={{ width: 100, marginBottom: 20 }} alt="loader" src="assets/img/loader.svg" />
              {__(T.messages.connection_workstation_progress)}
            </RightContainerPlaceHolder>
          )}
        </Page.Content>
        {showConfirmRepairsModal && (
          <RepairsModal
            title={__(T.misc.reason)}
            onClose={() => {
              this.setState({ showConfirmRepairsModal: false })
            }}
            onConfirm={this.sendRepairRequest}
          />
        )}
      </Page>
    )
  }
}

const RightContainerPlaceHolder = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 3;
`
