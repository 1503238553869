import React, { Component } from 'react'
import { Page, InfoCard, ImageInfoCard, Tab, PlaceholderError, Spacer, Icons, Button, Box } from 'components'
import { navigate } from 'shared/router'
import { Product, ProductionOrder, TmrItem, TmrTag } from 'api/types'
import styled from '@emotion/styled'
import Products from 'api/Products'
import Encodings from 'api/Encodings'
import RfidReader from 'shared/RfidReader'
import { T, __ } from 'translations/i18n'
import AppStore from 'AppStore'
import { showToast, askUserConfirmation, hideConfirmModal } from 'shared/utils'
import Items from 'api/Items'
import IdentifiersGrid from './IdentifiersGrid'
import EncodingProvider from './EncodingProvider'

interface State {
  productionOrder?: ProductionOrder
  productionOrderRow?: Product //perchè me l'ha detto fusto
  item?: TmrItem
  identifiers: {
    code?: string
    errorMessage?: string
    type: 'UHFTag' | 'NFCTag' | 'association'
    status: 'waiting' | 'reading' | 'confirmed' | 'error'
  }[]
  options: any
  loading?: boolean
  readerError: boolean
}

export default class Encoding extends Component<{}, State> {
  constructor(props) {
    super(props)
    this.state = {
      identifiers: [],
      options: [
        { value: 'associate', label: __(T.misc.associate), active: false },
        { value: 'verify', label: __(T.misc.verify), active: true },
      ],
      loading: false,
      readerError: false,
    }
  }

  async componentDidMount() {
    if ((!AppStore.workstations || AppStore.workstations.length === 0) && !AppStore.emulation) {
      navigate('/login')
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      return
    }
    this.setState({ loading: true })
    await RfidReader.initialize()
    await RfidReader.stop()
    await RfidReader.clear()
    if (await RfidReader.start(this.onTagReadCallback, () => {}, this.failStartAntenna)) {
      this.setState({ loading: false })
    } else if (AppStore.emulation) {
      this.setState({ loading: false })
      showToast({
        title: __(T.misc.info),
        description: 'Emulation enabled, cannot connect to workstations',
        status: 'info',
      })
    }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  verify = async (idfs: any[]) => {
    if (idfs.length === 0) return
    const res = await Encodings.verify(idfs.filter((idf) => idf.code) as any)

    if (res) {
      this.setState({
        productionOrder: res.item?.productionOrderRow?.order,
        productionOrderRow: res.item?.product,
        item: res.item,
      })
      res.item?.product?.itemConfiguration?.identifiers.forEach((idf) => {
        for (
          let index = 0;
          index < idf.amount - idfs.filter((identifier) => idf.type === identifier.type).length;
          index++
        ) {
          idfs.push({ status: 'waiting', type: idf.type })
        }
      })
    }

    this.setState({
      identifiers: idfs.map((idf) => {
        if (!idf.code) return idf
        const errorTagRes = res.errors?.findIndex((err) => err.ref === idf.code)
        if (errorTagRes >= 0) {
          switch (res.errors[errorTagRes].errorCode) {
            case 'ENCODING_ERROR.IDENTIFIER_NOT_FOUND':
              idf.errorMessage = __(T.error.tag_unassociated)
              break
            case 'ENCODING_ERROR.IDENTIFIER_TYPE_UNEXPECTED':
              idf.errorMessage = __(T.error.tag_type_unexpected)
              break
            case 'ENCODING_ERROR.IDENTIFIER_ALREADY_ASSOCIATED':
              idf.errorMessage = __(T.error.tag_already_associated)
              break
            case 'ENCODING_ERROR.IDENTIFIER_NOT_ASSOCIATED':
              idf.errorMessage = __(T.error.tags_not_associated)
              break
            case 'ENCODING_ERROR.IDENTIFIERS_MISMATCH':
              idf.errorMessage = __(T.error.tags_mismatch)
              break
            default:
              idf.errorMessage = res.errors[errorTagRes].errorCode
              break
          }

          idf.status = 'error'
        } else {
          idf.status = 'confirmed'
        }
        return idf
      }),
    })
  }

  onTagReadCallback = async (tag: TmrTag) => {
    const { identifiers } = this.state
    const idfs = [...identifiers]

    EncodingProvider.onTagRead(tag, idfs)
    hideConfirmModal()
    this.setState({ identifiers: idfs })
    await this.verify(idfs)
  }

  removeErrorTag = async () => {
    this.setState(
      {
        identifiers: [],
        productionOrder: undefined,
        productionOrderRow: undefined,
      },
      () => {
        RfidReader.clear()
      }
    )
  }

  failStartAntenna = () => {
    this.setState({ readerError: true, loading: false })
  }

  disassociate = async () => {
    const { item } = this.state
    try {
      if (!item || !item.id) throw new Error(__(T.error.not_found))
      if (await askUserConfirmation(__(T.misc.delete_association), __(T.confirm.confirm_delete_association))) {
        await Items.delete(item.id)
        await this.removeErrorTag()
        showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
      }
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  render() {
    const { options, productionOrderRow, productionOrder, identifiers, loading, readerError, item } = this.state
    const itemSummary = {} as any
    if (productionOrder?.description) {
      itemSummary[__(T.misc.description)] = productionOrder?.description
    }
    return (
      <Page
        title={__(T.misc.verify)}
        headerRight={<Tab options={options} onOptionSelected={EncodingProvider.getOptionEncodingPage} />}
        enableEmulation
      >
        <Page.Sidebar>
          {productionOrder && (
            <InfoCard title={__(T.misc.production_order)} subtitle={productionOrder.code} item={itemSummary} />
          )}
          <Spacer />
          {productionOrderRow && (
            <>
              <ImageInfoCard
                title={__(T.misc.ean_code)}
                subtitle={productionOrderRow.code}
                image_url={Products.getImageUrl(productionOrderRow.code)}
                product={productionOrderRow}
                item={item}
              />
              {AppStore.loggedUser?.admin && (
                <>
                  <Box flex />
                  <Button title={__(T.misc.delete_association)} onClick={this.disassociate} />
                </>
              )}
            </>
          )}
        </Page.Sidebar>

        <Page.Content>
          {!loading ? (
            <>
              {readerError && !AppStore.emulation ? (
                <RightContainerPlaceHolder>
                  <PlaceholderError>{__(T.misc.unable_to_connect_to_workstation)}</PlaceholderError>
                </RightContainerPlaceHolder>
              ) : (
                <IdentifiersGrid
                  resetText="RESET"
                  identifiers={identifiers.filter((i) => i.type === 'UHFTag' || i.type === 'NFCTag')}
                  onTagReadCallback={this.onTagReadCallback}
                  removeErrorTag={
                    identifiers.filter((i) => (i.type === 'UHFTag' || i.type === 'NFCTag') && i.code).length > 0
                      ? this.removeErrorTag
                      : undefined
                  }
                />
              )}
            </>
          ) : (
            <RightContainerPlaceHolder style={{ flexDirection: 'column' }}>
              <Icons.Loader style={{ width: 100, marginBottom: 20 }} />
              {__(T.messages.connection_workstation_progress)}
            </RightContainerPlaceHolder>
          )}
        </Page.Content>
      </Page>
    )
  }
}

const RightContainerPlaceHolder = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 3;
`
