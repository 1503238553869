import React, {Component} from "react";
import {Spacer, TextArea} from "../index";
import Box from "../Box";
import Modal from "./Modal";
import Button from "../Button";
import {__, T} from "../../translations/i18n";


interface Props {
    title: string
    subtitle?: string
    isOpen?: boolean
    onConfirm: (
        note: string,
    ) => void
    onClose: () => void
}
interface State {
    note: string
}

export default class PackingListModal extends Component<Props, State> {
    state: State = {
        note: '',
    }

    render() {
        const {isOpen, onClose, title, onConfirm} = this.props

        return (
            <Modal visible={isOpen ?? true} size="lg" onClose={onClose}>
                <Box>
                    <Modal.Title>{title}</Modal.Title>
                    <Spacer />
                    <TextArea
                        textAreaStyle={{height: 140}}
                        placeholder={__(T.placeholder.notes)}
                        onChange={(note) => this.setState({note})}
                    />
                    <Box center row mt={15}>
                        <Button style={{flex: 0}} title={__(T.misc.cancel)} onClick={onClose} variant="default"/>
                        <Spacer/>
                        <Button
                            style={{flex: 1}}
                            title={__(T.misc.confirm)}
                            onClick={() => {
                                return onConfirm(
                                    this.state.note,
                                )
                            }}
                        />
                    </Box>
                </Box>
            </Modal>
        )
    }
}