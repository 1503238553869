import React, { Component } from 'react'
import { Form, Page, Box } from 'components'
import { MadeFor, TmrZone } from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import MadeFors from 'api/MadeFors'
import { navigate } from 'shared/router'
import AppStore from 'AppStore'
import Zones from 'api/Zones'

interface State {
  zones: TmrZone[]
  madeFors: MadeFor[]
}
export default class CustomTransferToZone extends Component<{}, State> {
  state: State = {
    zones: [],
    madeFors: [],
  }

  componentDidMount() {
    if (
      AppStore.loggedUser?.place?.attributes &&
      AppStore.loggedUser?.place?.attributes['place.ttz.show.madeFor'] === 'true'
    ) {
      this.fetchMadeFors()
    }
    this.fetchZones()
  }

  fetchZones = async () => {
    try {
      this.setState({ zones: (await Zones.search<TmrZone>({ 'place.id': AppStore.loggedUser?.place.id })) ?? [] })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  fetchMadeFors = async () => {
    try {
      this.setState({ madeFors: (await MadeFors.search<MadeFor>({ limit: 500 })) ?? [] })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  submit = async (data) => {
    if (!data.destination) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.destination_field_required),
        status: 'error',
      })
      return
    }
    const state = {
      destination: data.destination,
      tone: data.tone?.id,
      madeFor: data.madeFor?.code,
    }

    navigate('/items-list/:operationCode', { operationCode: 'transfer-to-zone' }, { state })
  }

  render() {
    const { zones, madeFors } = this.state
    const tones = Array.from({ length: 21 }, (_, i) => i).map((tone) => {
      return {
        id: tone,
        description: tone == 0 ? __(T.labels.toneUndefined) : tone + '',
      }
    })

    return (
      <Page title={__(T.titles.transfer_to_zone)}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  placeholder: __(T.misc.destination),
                  name: 'destination',
                  required: true,
                  type: 'select',
                  options: zones,
                },
                {
                  placeholder: __(T.fields.tone),
                  name: 'tone',
                  type: 'select',
                  options: tones,
                  hide:
                    AppStore.loggedUser?.place?.attributes &&
                    AppStore.loggedUser?.place?.attributes['place.ttz.show.tone'] !== 'true',
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
