import React, { Component } from 'react'
import { Button, List, Input, Page, Spacer, Box, Icons, PackingListParcelsRow } from 'components'
import { navigate, getLocationState } from 'shared/router'
import { CustomShippingParcel } from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import PackingLists from 'api/PackingLists'

interface State {
  parcels: CustomShippingParcel[]
  filter: string
  loading: boolean
  disableCheckboxes: boolean
  submittedDestination: string
  query: string
  parcelRows: any
  isAllChecked: boolean
  madeFor?: string
}

let checkedList: any = []

export default class PackingListCreate extends Component<{}, State> {
  state: State = {
    parcels: [],
    filter: '',
    loading: true,
    disableCheckboxes: true,
    submittedDestination: '',
    query: '',
    parcelRows: undefined,
    isAllChecked: false,
  }

  navigateBack = () => {
    checkedList = []
    navigate('/packinglist')
  }

  componentDidMount() {
    const locationState = getLocationState(this.props)
    checkedList = []
    PackingLists.sendAwait(locationState.destination?.code)
      .then((data) => {
        this.setState({ loading: false, disableCheckboxes: false, parcels: data ?? [], submittedDestination: locationState.destination?.code })
      })
      .catch((err) => {
        this.setState({ loading: false, parcels: [] })
        showToast({
          title: __(T.error.error),
          description: err?.message ?? __(T.error.error),
          status: 'error',
        })
        this.navigateBack()
      })
  }

  setFilter = (filter: string) => this.setState({ filter })

  filterResults = (result: CustomShippingParcel) => {
    const { query } = this.state
    const outboundParcel = result.shippingOperationParcels.filter((o) => o.operationType === 'OUTBOUND')[0] || undefined
    return (
      result.code?.toLowerCase().includes(query.toLowerCase()) ||
      outboundParcel?.attributes?.TOM?.toLowerCase().includes(query.toLowerCase())
    )
  }

  handleChange = (event: any) => {
    let { parcels } = this.state
    let isAllChecked = false
    if (event.target.checked) {
      checkedList.push(event.target.value)
      isAllChecked = checkedList.length === parcels.length
    } else {
      const idx = checkedList.indexOf(event.target.value)
      checkedList.splice(idx, 1)
    }
    this.setState({ isAllChecked })
  }

  onInputEnter = (input: string) => {
    this.setState({ query: input })
  }

  handleAllCheck = (checked: boolean, data: string[]) => {
    this.setState({disableCheckboxes: true})
    const { parcels } = this.state
    if (checked) {
      data.forEach((n) => {
        if (!checkedList.includes(n)) checkedList.push(n)
      })
    } else {
      data.forEach((n) => {
        const i = checkedList.indexOf(n)
        checkedList.splice(i, 1)
      })
    }
    this.setState({
      isAllChecked: checkedList.length === parcels.length,
      disableCheckboxes: false
    })
  }

  previewPackingList = (checkedList: string[]) => {
      if (checkedList.length === 0) {
        showToast({
          title: __(T.error.error),
          description: __(T.messages.select_on_or_more_parcels),
          status: 'error',
        })
        return
      }

      const madeFor = Array.from(this.getParcelsForMF(checkedList))[0] ?? '---'
      navigate('/packinglist/preview', undefined, { state: {...this.state, submittedParcels: checkedList,  madeFor: madeFor}})
  }

  getParcelsForMF = (checkedList: string[]) => {
    return new Set(this.state.parcels.filter(p => p.code && checkedList.includes( p.code )).map(p => p.madeFor))
  }

  render() {
    const { parcels, loading, disableCheckboxes, isAllChecked } = this.state
    const filtered = parcels?.filter(this.filterResults)
    const locationState = getLocationState(this.props)

    return (
      <Page
        loading={loading}
        title={`${__(T.titles.packing_list) ?? 'Packing List'} / ${__(T.misc.detail)}`}
        onBackPress={() => {
          checkedList = []
          navigate('/packinglist')
        }}
        header={{
          details: [
            { label: __(T.misc.destination), value: locationState.destination?.description },
          ],
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.placeholder.search_shipment_parcel)}
              onChange={(query) => this.setState({ query })}
              image={<Icons.Barcode />}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Box flex />
            <Button
              loading={loading}
              title={isAllChecked ? __(T.misc.unselect_all) : __(T.misc.select_all)}
              variant={'secondary'}
              onClick={() =>
                this.handleAllCheck(
                  !isAllChecked,
                  filtered.map((n) => n!.code!)
                )
              }
            />
            <Spacer />
            <Button
              loading={loading}
              title={__(T.misc.preview_packing_list)}
              onClick={() => this.previewPackingList(checkedList)}
            />
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.titles.last_parcels)} (${filtered?.length})`}
            renderItem={(parcel) => (
              <PackingListParcelsRow
                disabled={disableCheckboxes}
                shippingParcel={parcel}
                checkboxHandleChange={this.handleChange}
                isChecked={checkedList.includes(parcel.code)}
                checkboxLabel={__(T.misc.select)}
              />
            )}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
