import React, { Component } from 'react'
import {
  AntennaButton,
  Page,
  GroupedProductList,
  Spacer,
  TagCounter,
  Box,
  TextBox,
  EmulateButton,
  Button,
} from 'components'
import { GroupedShipmentProduct, ShipmentParcel, TmrPlace } from 'api/types'
import { navigate, getMatchParams, getLocationState } from 'shared/router'
import { showToast } from 'shared/utils'
import ShipmentProvider from 'ShipmentProvider'
import RfidReader from 'shared/RfidReader'
import { T, __ } from 'translations/i18n'
import testTags from 'shared/testTags'
import { PageParams } from 'pages'
import TransferToZone from 'api/TransferToZone'

interface Params extends PageParams {
  parcelCode: string
}

interface MatchParams {
  configCode: string
  parcelCode: string
}

interface State {
  parcel: ShipmentParcel
  // checkListType: 'UPCS' | 'TAGS' | 'ITEMS'
  groupedProducts: GroupedShipmentProduct[]
  loading: boolean
  transfer?: { destination: TmrPlace }
}

export default class TransferToZoneReading extends Component<{}, State> {
  unexpectedFound = false

  params: Params = {
    ...getMatchParams(this.props),
  }

  state: State = {
    // checkListType: 'ITEMS',
    groupedProducts: [],
    loading: true,
    parcel: ShipmentProvider.createShipmentParcel(undefined, undefined, getLocationState(this.props).destination),
  }

  async componentDidMount() {
    try {
      this.initRfidDevice()
      this.setState({
        loading: false,
        transfer: getLocationState(this.props),
      })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  async initRfidDevice() {
    await RfidReader.initialize()
    RfidReader.onDecodedItemCallback = this.onDecodedItemCallback
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  onDecodedItemCallback = async (item) => {
    const { parcel, groupedProducts } = this.state

    ShipmentProvider.processItemForItemsChecklist([item], [parcel!], groupedProducts)
    this.forceUpdate()
  }

  clear = () => {
    const { parcel } = this.state
    RfidReader.clear()
    this.unexpectedFound = false
    const groupedProducts = ShipmentProvider.clearAllReceivingReadings([parcel!])
    this.setState({ groupedProducts })
  }

  confirm = async () => {
    try {
      const { transfer, parcel } = this.state
      await TransferToZone.save({
        destinationZone: { id: transfer?.destination.id },
        items: parcel.detectedItems.map((item) => {
          return { id: item.id }
        }),
      })
      showToast({ title: __(T.misc.success), description: __(T.messages.transfer_to_zone_success), status: 'success' })
      navigate('/transfer-to-zone')
    } catch (error) {
      showToast({ title: __(T.error.error), description: error?.message ?? 'Generic Error', status: 'error' })
    }
  }

  navigateBack = () => navigate('/')

  render() {
    const { parcel, groupedProducts, loading, transfer } = this.state
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(parcel, 'ITEMS')
    const error = unexpected > 0 ? __(T.error.rfid_discrepancies_found) : ''

    return (
      <Page
        title={__(T.titles.transfer_to_zone)}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details: [{ label: __(T.misc.destination), value: transfer?.destination.description }],
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={detected} expected={expected} unexpected={unexpected} />
            <AntennaButton style={{ backgroundColor: 'white' }} onClear={this.clear} hideClear={detected === 0} />
            <Spacer />
            <TextBox text={error} type="error" />
          </Box>
          <Button title={__(T.misc.confirm)} onClick={this.confirm} />
        </Page.Sidebar>

        <Page.Content>
          <GroupedProductList loading={loading} data={groupedProducts} noChecklist />

          <Box>
            <EmulateButton title="Emulate tags 1" onPress={() => RfidReader.emulateTags(testTags.parcel1)} />
            <EmulateButton title="Emulate tags 2" onPress={() => RfidReader.emulateTags(testTags.parcel2)} />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
