import { ApisauceConfig } from 'apisauce'
import api, { responseErrorCheck } from './api'
import TmrBaseResource from './TmrBaseResource'
import {TmrItem, TmrTransfer} from "./types";

export interface CustomUpdateRequest {
  itemIds: string[]
  choice?: string
  tone?: number
  madeFor?: string
  flaw?: string
}

export default class Items extends TmrBaseResource {
  static endpoint = '/items'

  static batchDecode<T>(epcs, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`${this.endpoint}/decode`, epcs, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static decode(identifier: string, requestConfig?: ApisauceConfig) {
    return api
      .get<any>(`${this.endpoint}/decode?identifierCode=${identifier}`, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static getTransfers(item: TmrItem) {
    if (!item.id) return []
    return api
        .get<TmrTransfer[]>(
            `/transfers?asc=false&item.epc=${item.epc}&limit=25&offset=0&orderBy=CREATION_DATE&joins=ITEM_ZONE_PLACE`
        )
        .then(responseErrorCheck)
        .then((res) => res)
  }
}
