import InboundShipments from 'api/InboundShipments'
import { T, __ } from 'translations/i18n'
import {
  GateInboundRequest,
  GateMultipleInboundRequest,
  GroupedShipmentProduct,
  QualityControlEntry,
  ShipmentParcel,
  ShipmentParcelFilter,
  ShipmentParcelItem,
  ShipmentParcelTag,
  ShippingRequestParcel,
  TmrItem,
  TmrZone,
} from 'api/types'
import AppStore from 'AppStore'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import TransferToZone from 'api/TransferToZone'
import { showToast } from 'shared/utils'
import CustomShipmentProvider from 'CustomShipmentProvider'

export default class CustomInboundProvider {
  static async confirmInboundIgnoringUnexpected(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    inboundZoneId: string,
    checkListType: CheckListType = 'ITEMS',
    confirmParcel: boolean,
    cqItems?: { id: string }[],
    cqDestinationZone?: string
  ) {
    const shippingRequestParcels = this.getShippingRequestParcelsFromShipmentParcelsIgnoringUnexpected(
      shipmentParcels,
      configurationId,
      undefined,
      checkListType
    )

    try {
      await TransferToZone.customTransferToZone({ destinationZone: { id: cqDestinationZone }, items: cqItems })
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }

    for (let i = 0; i < shippingRequestParcels.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await InboundShipments.updateReadings(
        shippingRequestParcels[i].parcelCode,
        shippingRequestParcels[i].shippingParcelDetail
      )
      // eslint-disable-next-line no-await-in-loop
      if (confirmParcel) {
        // eslint-disable-next-line no-await-in-loop
        await InboundShipments.confirmParcel(shippingRequestParcels[i].parcelCode, { zone: inboundZoneId })
      }
    }
  }

  static getShippingRequestParcelsFromShipmentParcelsIgnoringUnexpected(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    destinationPlaceCode?: string,
    checklistTypes: CheckListType = 'ITEMS'
  ) {
    const parcels: ShippingRequestParcel[] = []
    shipmentParcels.forEach((parcel) => {
      const items = checklistTypes === 'ITEMS' ? parcel.detectedItems.map((itm) => itm.id) : []
      //WARNING HERE for checklistype
      const tags: string[] = []
      if (checklistTypes === 'TAGS') {
        parcel.detectedItems
          .filter((itm) => itm.expected)
          .forEach((itm) =>
            itm.itemIdentifiers.filter((idf) => idf.identifierType === 'UHFTag').forEach((idf) => tags.push(idf.code))
          )
        parcel.detectedTags.filter((tag) => tag.expected).forEach((tag) => tags.push(tag.tag))
      }
      parcels.push({
        parcelCode: parcel.header.parcelCode,
        shippingParcelDetail: {
          configurationId: configurationId,
          operation: 'replace',
          shippingCode: parcel.header.shippingCode,
          originPlaceCode: AppStore.loggedUser?.place.code,
          destinationPlaceCode: destinationPlaceCode,
          readings: { items: items, tags: tags, upcs: [] },
        },
      })
    })
    return parcels
  }

  static getDdtShippingRequestParcelsFromShipmentParcelsIgnoringUnexpected(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    destinationPlaceCode?: string,
    checklistTypes: CheckListType = 'ITEMS'
  ) {
    const parcels: ShippingRequestParcel[] = []
    shipmentParcels.forEach((parcel) => {
      let items: string[] = []
      if (checklistTypes === 'ITEMS') {
        items = parcel.detectedItems
          .map((itm) => {
            return itm.expected === true ? itm.id : ''
          })
          .filter(function (el) {
            return el !== ''
          })
      }

      //WARNING HERE for checklistype
      const tags: string[] = []
      if (checklistTypes === 'TAGS') {
        parcel.detectedItems
          .filter((itm) => itm.expected)
          .forEach((itm) =>
            itm.itemIdentifiers.filter((idf) => idf.identifierType === 'UHFTag').forEach((idf) => tags.push(idf.code))
          )
        parcel.detectedTags.filter((tag) => tag.expected).forEach((tag) => tags.push(tag.tag))
      }
      parcels.push({
        parcelCode: parcel.header.parcelCode,
        shippingParcelDetail: {
          configurationId: configurationId,
          operation: 'replace',
          shippingCode: parcel.header.shippingCode,
          originPlaceCode: AppStore.loggedUser?.place.code,
          destinationPlaceCode: destinationPlaceCode,
          readings: { items: items, tags: tags, upcs: [] },
        },
      })
    })
    return parcels
  }

  static getDdtShippingRequestParcelsFromShipmentParcels(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    destinationPlaceCode?: string,
    checklistTypes: CheckListType = 'ITEMS'
  ) {
    const parcels: ShippingRequestParcel[] = []
    shipmentParcels.forEach((parcel) => {
      const items = checklistTypes === 'ITEMS' || 'UPCS' ? parcel.detectedItems.map((itm) => itm.id) : []
      //WARNING HERE for checklistype
      const tags: string[] = []
      if (checklistTypes === 'TAGS') {
        parcel.detectedItems.forEach((itm) =>
          itm.itemIdentifiers.filter((idf) => idf.identifierType === 'UHFTag').forEach((idf) => tags.push(idf.code))
        )
        parcel.detectedTags.forEach((tag) => tags.push(tag.tag))
      }
      parcels.push({
        parcelCode: parcel.header.parcelCode,
        shippingParcelDetail: {
          configurationId: configurationId,
          operation: 'replace',
          shippingCode: parcel.header.shippingCode,
          originPlaceCode: AppStore.loggedUser?.place.code,
          destinationPlaceCode: destinationPlaceCode,
          readings: { items: items, tags: tags, upcs: [] },
        },
      })
    })
    return parcels
  }

  static getDefaultStockZone() {
    const zones = AppStore.zones

    if (!zones) return undefined

    const defaultZone = zones.find(
      (zone) => zone.zoneType === 'STOCK' && zone.attributes?.['zone.default.inbound']?.toLowerCase() === 'true'
    )
    return defaultZone ?? AppStore.getZoneByType('STOCK')
  }

  static getGateInboundRequest(parcelCode: string, items: ShipmentParcelItem[], qcEntries: QualityControlEntry[]) {
    if (!parcelCode || !items || !qcEntries) throw new Error('Cannot create inbound request')

    const request: GateInboundRequest = {
      parcelCode: parcelCode,
      items: [],
      qcEntries: qcEntries,
    }

    items.forEach((item) => {
      const key = `${item.product?.style?.value ?? ''}|${item.product?.color?.value ?? ''}|${
        item.product?.size?.value ?? ''
      }`
      const epc = item.itemIdentifiers.find((id) => id.identifierType === 'UHFTag')
      if (key && epc)
        request.items.push({
          epc: epc.code,
          qcKey: key,
        })
    })

    return request
  }

  static async confirmDdtReadingsInboundIgnoringUnexpected(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    inboundZoneId: string,
    checkListType: CheckListType = 'ITEMS',
    confirmParcel: boolean,
    cqItems?: { id: string }[],
    cqDestinationZone?: string
  ) {
    const shippingRequestParcels = this.getDdtShippingRequestParcelsFromShipmentParcelsIgnoringUnexpected(
      shipmentParcels,
      configurationId,
      undefined,
      checkListType
    )

    const shippingCodes = shippingRequestParcels.map((parcel) => parcel?.shippingParcelDetail.shippingCode || '')
    const readings = shippingRequestParcels.map((parcel) => {
      return { parcelCode: parcel.parcelCode, readings: parcel?.shippingParcelDetail.readings }
    })
    const originPlaceCode = AppStore.loggedUser?.place.code || ''
    const zone = inboundZoneId

    try {
      await TransferToZone.customTransferToZone({ destinationZone: { id: cqDestinationZone }, items: cqItems })
    } catch (error) {
      showToast({ title: __(T.error.error), description: error.message, status: 'error' })
    }

    await InboundShipments.updateMultipleReadings({
      configurationId: configurationId,
      operation: 'replace',
      shippingCodes: shippingCodes,
      originPlaceCode: originPlaceCode,
      destinationPlaceCode: undefined,
      shippingRequestParcels: readings.filter(
        (read) => read.readings.items.length + read.readings.tags.length + read.readings.upcs.length > 0
      ),
      zone,
    })

    if (confirmParcel) {
      const uniqueShippingCodes = Array.from(new Set(shippingCodes).values())
      await InboundShipments.confirmParcels({ shippingCodes: uniqueShippingCodes, zone, configurationId })
    }
  }

  static async confirmDdtReadingsInbound(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    inboundZoneId: string,
    checkListType: CheckListType = 'ITEMS',
    confirmParcel: boolean
  ) {
    const shippingRequestParcels = this.getDdtShippingRequestParcelsFromShipmentParcels(
      shipmentParcels,
      configurationId,
      undefined,
      checkListType
    )

    const shippingCodes = shippingRequestParcels.map((parcel) => parcel?.shippingParcelDetail.shippingCode || '')
    const readings = shippingRequestParcels.map((parcel) => {
      return { parcelCode: parcel.parcelCode, readings: parcel?.shippingParcelDetail.readings }
    })
    const originPlaceCode = AppStore.loggedUser?.place.code || ''
    const zone = inboundZoneId

    await InboundShipments.updateMultipleReadings({
      configurationId: configurationId,
      operation: 'replace',
      shippingCodes: shippingCodes,
      originPlaceCode: originPlaceCode,
      destinationPlaceCode: undefined,
      shippingRequestParcels: readings.filter(
        (read) => read.readings.items.length + read.readings.tags.length + read.readings.upcs.length > 0
      ),
      zone,
    })

    if (confirmParcel) {
      const uniqueShippingCodes = Array.from(new Set(shippingCodes).values())
      await InboundShipments.confirmParcels({ shippingCodes: uniqueShippingCodes, zone, configurationId })
    }
  }

  static customProcessItemForItemsChecklist(
    items: TmrItem[],
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[],
    destinationZone?: TmrZone
  ) {
    items.forEach((itm) => {
      if (!itm || itm.id === undefined) return

      const alreadyReadItem = shipmentParcels.find((shipmentParcel) =>
        shipmentParcel.detectedItems.find((detectedItem) => detectedItem.id === itm.id)
      )

      if (alreadyReadItem) return

      const parcel = shipmentParcels.find((shipmentParcel) =>
        shipmentParcel.expectedItems.find((expectedItem) => expectedItem.id === itm.id)
      )
      if (parcel) {
        parcel.detectedItems.push({
          ...itm,
          productCode: itm.upc ?? itm.product.code,
          expected: true,
          // zoneCode: destinationZone?.code ?? itm.zone?.code,
          // zoneId: destinationZone?.id ?? itm.zone?.id,
        })
        parcel.detectedCounter.items++
      } else {
        const firstParcel = shipmentParcels[0]
        const checkListExist = firstParcel.expectedCounter.tags === 0 && firstParcel.expectedCounter.items === 0
        if ((itm.upc || itm.product.code) && !itm.id) {
          firstParcel.detectedTags.push({
            tag: itm.epc,
            expected: checkListExist,
            productCode: itm.upc ?? itm.product.code,
          })
        } else {
          firstParcel.detectedItems.push({
            ...itm,
            productCode: itm.upc ?? itm.product.code,
            expected: checkListExist,
            // zoneCode: destinationZone.code,
            // zoneId: destinationZone.id,
          })
        }
        if (!shipmentParcels.find((shipmentParcel) => shipmentParcel.products[itm.upc ?? itm.product.code])) {
          firstParcel.products[itm.upc ?? itm.product.code] = itm.product
        }
        if (firstParcel.expectedCounter.items > 0) firstParcel.unexpectedCounter.items++ // WARN: validate this
        firstParcel.detectedCounter.items++
      }

      CustomShipmentProvider.customProcessGroupedProducts(shipmentParcels, groupedProducts, [itm])
    })
  }

  static getMultipleGateInboundRequest(
    parcelCodes: string[],
    items: ShipmentParcelItem[],
    qcEntries: QualityControlEntry[]
  ) {
    if (!parcelCodes || !items || !qcEntries) throw new Error(__(T.error.cannot_build_inbound_request))

    const request: GateMultipleInboundRequest = {
      parcelCodes: parcelCodes,
      items: [],
      qcEntries: qcEntries,
    }

    items.forEach((item) => {
      const key = `${item.product?.style?.value ?? ''}|${item.product?.color?.value ?? ''}|${
        item.product?.size?.value ?? ''
      }`
      const epc = item.itemIdentifiers.find((id) => id.identifierType === 'UHFTag')
      if (key && epc)
        request.items.push({
          epc: epc.code,
          qcKey: key,
        })
    })

    return request
  }

  static async fetchInboundShipmentParcels(params: ShipmentParcelFilter, forceTagsChecklist = false) {
    const inboundShipmentParcels = (await InboundShipments.searchDetail(params)) ?? []
    if (forceTagsChecklist) {
      inboundShipmentParcels.forEach((par) => {
        par.expectedItems = []
        par.expectedCounter.items = 0
      })
    }
    const checkListTypes = ShipmentProvider.getCheckListTypes(inboundShipmentParcels)
    if (inboundShipmentParcels.length === 0) throw new Error(__(T.error.parcel_not_found))

    // Inbound no checklist
    // if (checkListTypes.length !== 1) throw new Error('Number of checklists error')

    const checkListType = checkListTypes[0]

    //Add !checkListType for backend problem
    if (checkListType === 'ITEMS' || !checkListType) {
      for await (const parcel of inboundShipmentParcels) {
        parcel.groupedProducts = CustomInboundProvider.initializeCustomGroupedProducts([parcel])
      }
    }

    if (checkListType === 'TAGS') {
      for await (const parcel of inboundShipmentParcels) {
        const expectedTags: ShipmentParcelTag[] = []
        const detectedTags: ShipmentParcelTag[] = []
        const unexpectedTags: ShipmentParcelTag[] = []
        parcel.expectedTags.forEach((tag) => expectedTags.push(tag))
        parcel.detectedTags.forEach((tag) => (tag.expected ? detectedTags.push(tag) : unexpectedTags.push(tag)))
        parcel.expectedItems = await ShipmentProvider.decodeFromTagsList(expectedTags)
        parcel.detectedItems = await ShipmentProvider.decodeFromTagsList(detectedTags)

        parcel.expectedCounter.items = parcel.expectedItems.length ?? 0
        parcel.unexpectedCounter.items = parcel.detectedItems.filter((itm) => !itm.expected).length ?? 0
        parcel.detectedCounter.items = parcel.detectedItems.filter((itm) => itm.expected).length ?? 0

        parcel.groupedProducts = this.initializeCustomGroupedProducts([parcel])
      }
    }
    const groupedProducts = inboundShipmentParcels.flatMap((par) => par.groupedProducts!)
    return { inboundShipmentParcels, checkListType, groupedProducts }
  }

  static initializeCustomGroupedProducts(shipmentParcels: ShipmentParcel[]) {
    if (!shipmentParcels || shipmentParcels.length === 0) return []

    const groupedProducts: GroupedShipmentProduct[] = []
    shipmentParcels.forEach((parcel) => {
      parcel.expectedItems.forEach((itm) => {
        const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === itm.productCode)
        if (groupedProductIndex >= 0) {
          return
        }
        groupedProducts.push({
          product: ShipmentProvider.getParcelProduct(itm.productCode, shipmentParcels, itm.product ?? undefined),
          detected: 0,
          unexpected: 0,
          expected: ShipmentProvider.getExpectedProductCount(itm.productCode, shipmentParcels),
          parcelCode: parcel.header.parcelCode,
        })
      })
    })
    return CustomShipmentProvider.customProcessGroupedProducts(shipmentParcels, groupedProducts)
  }

  static customClearAllReceivingReadings(shippingParcels: ShipmentParcel[]) {
    shippingParcels.forEach((parcel) => {
      parcel.detectedUpcs = []
      parcel.detectedTags = []
      parcel.detectedItems = []
      parcel.detectedCounter = { items: 0, upcs: 0, tags: 0 }
      parcel.unexpectedCounter = { items: 0, upcs: 0, tags: 0 }
      parcel.groupedProducts = CustomInboundProvider.initializeCustomGroupedProducts([parcel])
    })
  }
}
