import React, { Component } from 'react'
import {
  Input,
  Page,
  Box,
  ParcelStatus,
  GroupedProductsTable,
  Button,
  Icons,
  KeyValueRow,
  SelectItemModal,
} from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { ShipmentParcel, GroupedShipmentProduct, TmrPrinter } from 'api/types'
import { decodeMadeFor, getChoiceDescription, getDatetime, hideModal, openModal, showToast } from 'shared/utils'
import ShipmentProvider from 'ShipmentProvider'
import OutboundShipments from 'api/OutboundShipments'
import { T, __ } from 'translations/i18n'
import Shipments from 'api/Shipments'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import AppStore from 'AppStore'

interface State {
  parcel?: ShipmentParcel
  filter: string
  loading: boolean
}

export default class extends Component {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    filter: '',
    loading: true,
  }

  async componentDidMount() {
    const { parcelCode } = getMatchParams(this.props)
    try {
      let detailResponse : ShipmentParcel[] = [];
      if (this?.operation?.isOutbound78ColliCross === 'yes'){
        detailResponse.push(...await OutboundShipments.searchDetail({ parcelCodes: [parcelCode] }) ?? [])
        detailResponse.forEach((parcel) => {
          parcel.detectedItems = parcel.expectedItems
          parcel.detectedTags = parcel.expectedTags
          parcel.detectedUpcs = parcel.expectedUpcs
          parcel.detectedCounter = parcel.expectedCounter
        })
      } else {
        detailResponse.push(...await OutboundShipments.searchDetailWithoutCrossParcels({ parcelCodes: [parcelCode] }) ?? [])
      }
      this.setState({ loading: false, parcel: detailResponse ? detailResponse[0] : undefined })
    } catch (error) {
      showToast({ title: __(T.error.error), description: error?.message ?? error, status: 'error' })
      this.navigateBack()
    }
  }

  showPrinterModal = async (onSelect: (printer: TmrPrinter) => void) => {
    openModal({
      id: 'printerModal',
      modal: (
          <SelectItemModal
              title={__(T.titles.select_printer)}
              options={AppStore.printers ?? []}
              onSelect={async (printer) => {
                await AppStore.saveDefaultPrinter(printer[0])
                onSelect(printer[0])
              }}
              selected={[AppStore.defaultPrinter]}
              visible
              field="description"
              onClose={() => hideModal('printerModal')}
          />
      ),
    })
  }

  onPrintLabel = async (printer?: TmrPrinter, afterPrint?: () => void) => {
    const { parcel } = this.state
    if (!parcel) {
      showToast({ title: __(T.error.error), description: __(T.error.parcel_not_found), status: 'error' })
      return
    }

    if (!printer) {
      showToast({ title: __(T.error.error), description: __(T.error.printer_not_found), status: 'error' })
      return
    }

    try {
      if (this?.operation?.isOutbound78ColliCross === 'yes'){
        await Shipments.printCrossLabel(parcel!.header.parcelCode, printer)
      } else {
        await Shipments.printLabel(parcel!.header.parcelCode, printer)
      }
      showToast({ title: __(T.misc.success), description: __(T.messages.print_success), status: 'success' })
    } catch (error) {
      showToast({title: __(T.error.error), description: error?.message ?? error, status: 'error'})
    }
    if (afterPrint) afterPrint()
  }

  navigateBack = () => navigate('/outbound/:configCode/create', { configCode: getMatchParams(this.props).configCode })

  setFilter = (filter: string) => this.setState({ filter })

  getFilteredProducts(products: GroupedShipmentProduct[], filter: string) {
    return products.filter(
      (product) =>
        product.product.code.toLowerCase().includes(filter) ||
        product.product.style?.value.toLowerCase().includes(filter) ||
        product.product.material?.value.toLowerCase().includes(filter) ||
        product.product.color?.value.toLowerCase().includes(filter) ||
        product.product.size?.value.toLowerCase().includes(filter)
    )
  }

  getHeaders = () => {
    const { parcel } = this.state
    const commessaCode = parcel?.header?.parcelAttributes?.TOM ?? parcel?.header?.parcelAttributes?.workCode
    const destinationPlace = parcel?.header?.destinationPlace?.description
    const details =
      this?.operation?.isCustomDirectTransfer === 'no'
        ? [
            { label: __(T.misc.madeFor), value: decodeMadeFor(parcel?.header.parcelAttributes?.madeFor) },
            { label: __(T.misc.parcel), value: parcel?.header.parcelCode },
            { label: __(T.misc.choiche), value: getChoiceDescription(parcel?.header.parcelAttributes?.choice) },
            { label: __(T.misc.shipment), value: commessaCode },
            {
              label: __(T.misc.destination),
              value: destinationPlace !== '' ? destinationPlace : parcel?.header?.destinationPlace?.code,
            },
          ]
        : [
          { label: __(T.misc.madeFor), value: decodeMadeFor(parcel?.header.parcelAttributes?.madeFor) },
            { label: __(T.misc.parcel), value: parcel?.header.parcelCode },
            { label: __(T.misc.shipment), value: commessaCode },
            {
              label: __(T.misc.destination),
              value: destinationPlace !== '' ? destinationPlace : parcel?.header?.destinationPlace?.code,
            },
          ]

    if (
      this?.operation?.isCustomDirectTransfer === 'no' &&
      AppStore.loggedUser?.place?.attributes?.tintoria === 'true'
    ) {
      details.splice(0, 0, { label: __(T.misc.tubful), value: parcel?.header.parcelAttributes?.tubful })
    }

    if (this.operation.isOutbound78ColliCross === 'yes') {
      details.splice(0,0,{ label: __(T.misc.printer), value: AppStore.defaultPrinter?.description })
    }

    return details
  }

  render() {
    const { parcel, filter, loading } = this.state
    const groupedProducts = parcel ? ShipmentProvider.initializeGroupedProducts([parcel]) : []

    return (
      <Page
        loading={loading}
        title={`${this.operation?.description ?? 'Outbound'} / ${__(T.misc.detail)}`}
        onBackPress={() => navigate('/outbound/:configCode', { configCode: getMatchParams(this.props).configCode })}
        header={{
          details: this.getHeaders(),
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              image={<Icons.Barcode />}
              onChange={this.setFilter}
              placeholder={__(T.misc.search)}
              style={{ width: 300 }}
            />
            <Box flex />
            {this.operation.isOutbound78ColliCross === 'yes' && (
                <Button style={{ flex: 'none' }}
                        loading={loading}
                        variant={'default'}
                        title={__(T.titles.select_printer)}
                        onClick={() => this.showPrinterModal(() => {})} />)
            }
            <KeyValueRow
              loading={loading}
              label={__(T.misc.creation_date)}
              variant="raw"
              value={getDatetime(parcel?.header.parcelCreationDate)}
            />
            <KeyValueRow
              style={{ marginLeft: 20, marginRight: 20 }}
              loading={loading}
              variant="raw"
              label={__(T.misc.status)}
              value={<ParcelStatus status={parcel?.header?.parcelState ?? '---'} />}
            />
            <Button
                title={__(this.operation.isOutbound78ColliCross === 'yes' ? T.misc.print : T.misc.reprint)}
                onClick={() => this.operation.isOutbound78ColliCross === 'yes' ?
                    this.onPrintLabel(AppStore.defaultPrinter, this.navigateBack) : this.showPrinterModal(this.onPrintLabel)
            }
            />
          </Page.TopBar>
          <GroupedProductsTable products={this.getFilteredProducts(groupedProducts, filter)} loading={loading} />
        </Page.Content>
      </Page>
    )
  }
}
