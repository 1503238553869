import {
  GateOutboundRequest,
  GateOutboundRequestItem,
  GroupedShipmentProduct,
  ShipmentParcel,
  ShipmentParcelUpc,
  TmrItem,
  TmrZone,
} from 'api/types'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import OutboundShipments from 'api/OutboundShipments'
import CustomShipmentProvider from 'CustomShipmentProvider'
import {__, T} from "../../translations/i18n";
import {showToast} from "../../shared/utils";
import CustomItems from "../../api/CustomItems";

export default class CustomOutboundProvider {
  static async confirmOutboundIgnoringUnexpected(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    destinationPlaceCode: string,
    checkListType: CheckListType = 'UPCS',
    confirmParcel: boolean
  ) {
    const shippingRequestParcels = ShipmentProvider.getShippingRequestParcelsFromShipmentParcels(
      shipmentParcels,
      configurationId,
      destinationPlaceCode,
      checkListType
    )
    for (let i = 0; i < shippingRequestParcels.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await OutboundShipments.updateReadings(
        shippingRequestParcels[i].parcelCode,
        shippingRequestParcels[i].shippingParcelDetail
      )
      //eslint-disable-next-line no-await-in-loop
      if (confirmParcel) {
        // eslint-disable-next-line no-await-in-loop
        await OutboundShipments.confirmParcel(shippingRequestParcels[i].parcelCode)
      }
    }
  }

  static getGateOutboundRequest(parcelCode: string, upcs: ShipmentParcelUpc[]) {
    if (!parcelCode || !upcs) throw new Error('Cannot create outbound request')

    const gateOutboundRequestItem: GateOutboundRequestItem = {}

    upcs.forEach((shipmentParcelUpc) => {
      const shipmentUpc = shipmentParcelUpc.upc
      gateOutboundRequestItem[shipmentUpc] = shipmentParcelUpc.quantity
    })

    const request: GateOutboundRequest = {
      entries: gateOutboundRequestItem,
    }

    return request
  }

  static processItemForUpcsChecklist(
    items: TmrItem[],
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[]
  ) {
    items.forEach((itm) => {
      if (!itm || itm.id === undefined) return

      const alreadyReadItem = shipmentParcels.find((shipmentParcel) =>
        shipmentParcel.detectedItems.find((detectedItem) => detectedItem.id === itm.id)
      )

      if (alreadyReadItem) return

      const parcelsWithItemUpc = shipmentParcels.filter((shipmentParcel) =>
        shipmentParcel.expectedUpcs.find((expectedUpc) => expectedUpc.upc === itm?.product?.code ?? itm.upc)
      )
      const parcel = parcelsWithItemUpc.find((shipmentParcel) => {
        const targetUpc = itm.product.code ?? itm.upc
        const detectedItemsByUpc = shipmentParcel.detectedItems.filter(
          (shipmentParcelDetectedItem) =>
            shipmentParcelDetectedItem.upc === targetUpc || shipmentParcelDetectedItem.productCode === targetUpc
        )
        return (
          detectedItemsByUpc.length <
          (shipmentParcel.expectedUpcs.find((expectedUpc) => expectedUpc.upc === targetUpc)?.quantity ?? 0)
        )
      })

      if (parcel) {
        parcel.detectedItems.push({
          productCode: itm.upc ?? itm.product.code,
          expected: true,
          ...itm,
          // zoneCode: destinationZone?.code ?? itm.zone?.code,
          // zoneId: destinationZone?.id ?? itm.zone?.id,
        })
        parcel.detectedCounter.items++
      } else {
        const firstParcel = shipmentParcels[0]
        const checkListExist =
          firstParcel.expectedCounter.tags === 0 &&
          firstParcel.expectedCounter.items === 0 &&
          firstParcel.expectedCounter.upcs === 0
        if ((itm.upc || itm.product.code) && !itm.id) {
          firstParcel.detectedTags.push({
            tag: itm.epc,
            expected: checkListExist,
            productCode: itm.upc ?? itm.product.code,
          })
        } else {
          firstParcel.detectedItems.push({
            productCode: itm.upc ?? itm.product.code,
            expected: checkListExist,
            ...itm,
            // zoneCode: destinationZone.code,
            // zoneId: destinationZone.id,
          })
        }
        if (!shipmentParcels.find((shipmentParcel) => shipmentParcel.products[itm.upc ?? itm.product.code])) {
          firstParcel.products[itm.upc ?? itm.product.code] = itm.product
        }
        if (firstParcel.expectedCounter.upcs > 0) firstParcel.unexpectedCounter.items++ // WARN: validate this
        firstParcel.detectedCounter.items++
      }
      ShipmentProvider.processGroupedProducts(shipmentParcels, groupedProducts, [itm])
    })
  }

  static customProcessItemForItemsChecklist(
    items: TmrItem[],
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[],
    destinationZone?: TmrZone
  ) {
    items.forEach((itm) => {
      if (!itm || itm.id === undefined) return

      const alreadyReadItem = shipmentParcels.find((shipmentParcel) =>
          shipmentParcel.detectedItems.find((detectedItem) => detectedItem.id === itm.id)
      )

      if (alreadyReadItem) return

      const parcel = shipmentParcels.find(
          (shipmentParcel) =>
              shipmentParcel.expectedItems.find((expectedItem) => expectedItem.id === itm.id) ||
              shipmentParcel.expectedUpcs.find((expectedUpc) => expectedUpc.upc === itm.upc)
      )
      if (parcel) {
        parcel.detectedItems.push({
          ...itm,
          productCode: itm.upc ?? itm.product.code,
          expected: true,
          // zoneCode: destinationZone?.code ?? itm.zone?.code,
          // zoneId: destinationZone?.id ?? itm.zone?.id,
        })
        parcel.detectedCounter.items++
      } else {
        const firstParcel = shipmentParcels[0]
        const checkListExist = firstParcel.expectedCounter.tags === 0 && firstParcel.expectedCounter.items === 0
        if ((itm.upc || itm.product.code) && !itm.id) {
          firstParcel.detectedTags.push({
            tag: itm.epc,
            expected: checkListExist,
            productCode: itm.upc ?? itm.product.code,
          })
        } else {
          firstParcel.detectedItems.push({
            ...itm,
            productCode: itm.upc ?? itm.product.code,
            expected: checkListExist,
            // zoneCode: destinationZone.code,
            // zoneId: destinationZone.id,
          })
        }
        if (!shipmentParcels.find((shipmentParcel) => shipmentParcel.products[itm.upc ?? itm.product.code])) {
          firstParcel.products[itm.upc ?? itm.product.code] = itm.product
        }
        if (firstParcel.expectedCounter.items > 0) firstParcel.unexpectedCounter.items++ // WARN: validate this
        firstParcel.detectedCounter.items++
      }

      CustomShipmentProvider.customProcessGroupedProducts(shipmentParcels, groupedProducts, [itm])
    })
  }
}
