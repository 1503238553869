import TmrBaseResource from './TmrBaseResource'
import api, { responseErrorCheck } from './api'

export default class Places extends TmrBaseResource {
  static endpoint = '/places'

  static getReadersPlaces(placeType: string): Promise<any> {
    return api
      .get<any>(`custom${this.endpoint}/getReaderPlaces/${placeType}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
