import {
  CustomShippingRequest,
  ConfirmParcelsData,
  Ddt,
  QualityControlEntry,
  SearchShippingCounters,
  ShipmentParcel,
  ShipmentParcelFilter,
  ShipmentParcelPreview,
  ShippingConfirmRequest,
  UpdateMultipleReadingsData,
} from './types'
import api, { responseErrorCheck } from './api'

export default class InboundShipments {
  static endpoint = '/shipping/inbound'

  static searchCounters(
    filters?: ShipmentParcelFilter,
    requestConfig = undefined
  ): Promise<ShipmentParcelPreview[] | undefined> {
    return api
      .post<ShipmentParcelPreview[]>(`${this.endpoint}/search/counters`, filters ?? {}, requestConfig)
      .then(responseErrorCheck)
      .then((data) => data?.sort((a, b) => (b.header.parcelCreationDate ?? 0) - (a.header.parcelCreationDate ?? 0)))
  }

  static searchDetail(filters: ShipmentParcelFilter, requestConfig = undefined): Promise<ShipmentParcel[] | undefined> {
    return api.post<ShipmentParcel[]>(`${this.endpoint}/search/detail`, filters, requestConfig).then(responseErrorCheck)
  }

  static updateReadings(parcelCode, data, requestConfig = undefined) {
    return api
      .post(`${this.endpoint}/parcel/${parcelCode}/updateReadings`, data, requestConfig)
      .then(responseErrorCheck)
  }

  static confirmParcel(parcelCode, data: ShippingConfirmRequest = {}, requestConfig = undefined) {
    return api
      .post(`${this.endpoint}/custom/parcel/${parcelCode}/confirm`, data, requestConfig)
      .then(responseErrorCheck)
  }

  static searchShippingCounters(
    filters?: { shippingCodes?: string[]; parcelCodes?: string[]; shippingState?: string },
    requestConfig = undefined
  ): Promise<SearchShippingCounters[] | undefined> {
    return api
      .post<SearchShippingCounters[]>(`${this.endpoint}/search/shippingCounters`, filters ?? {}, requestConfig)
      .then(responseErrorCheck)
  }

  static searchDdtCounters(
    filters?: { shippingState?: string },
    requestConfig = undefined
  ): Promise<Ddt[] | undefined> {
    return api.post<Ddt[]>(`${this.endpoint}/search/ddtCounters`, filters ?? {}, requestConfig).then(responseErrorCheck)
  }

  static updateMultipleReadings(data: UpdateMultipleReadingsData) {
    return api.post(`${this.endpoint}/ddt/updateReadings`, data, { timeout: 240000 }).then(responseErrorCheck)
  }

  static confirmParcels(data: ConfirmParcelsData) {
    return api.post(`${this.endpoint}/ddt/confirm`, data, { timeout: 240000 }).then(responseErrorCheck)
  }

  static confirmShipment(data: { shippingCode: string; configurationId: string }, requestConfig = undefined) {
    return api
      .post<ShipmentParcelPreview>(`${this.endpoint}/custom/shipment/confirm`, data, requestConfig)
      .then(responseErrorCheck)
  }

  static ddtQualityControls(shippingCodes: []) {
    return api
      .get<QualityControlEntry[]>(`shippingParcelQualityControls`, { shippingCodes: shippingCodes })
      .then(responseErrorCheck)
  }

  static getParcelByIdentifierAndStartInbound(identifier: string) {
    return api.post<ShipmentParcel>(`${this.endpoint}/custom/parcel/start/${identifier}`).then(responseErrorCheck)
  }

  static startParcelRI(data: CustomShippingRequest) {
    return api.post<ShipmentParcelPreview>(`${this.endpoint}/custom/start/parcel`, data).then(responseErrorCheck)
  }
}
