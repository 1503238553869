import React, { Component } from 'react'
import { Spacer, Box, TextArea } from 'components'
import styled from '@emotion/styled'
import Button from 'components/Button'
import { T, __ } from 'translations/i18n'
import Modal from './Modal'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onConfirm: (note: string) => void
  onClose: () => void
  style?: any
}
interface State {
  note: string
}
export default class RepairsModal extends Component<Props, State> {
  state: State = {
    note: '',
  }
  render() {
    const { isOpen, onClose, title, onConfirm, subtitle } = this.props
    return (
      <Modal visible={isOpen ?? true} size="lg" onClose={onClose}>
        <Box>
          <Title>{title}</Title>
          {subtitle && <Value>{subtitle}</Value>}
          <TextArea
            textAreaStyle={{ height: 170 }}
            placeholder={__(T.messages.describe_repair_reason)}
            onChange={(note) => this.setState({ note })}
          />
          <Box center row mt={15}>
            <Button style={{ flex: 1 }} title={__(T.misc.cancel)} onClick={onClose} variant="default" />
            <Spacer />
            <Button style={{ flex: 1 }} title={__(T.misc.confirm)} onClick={() => onConfirm(this.state.note)} />
          </Box>
        </Box>
      </Modal>
    )
  }
}

const Title = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
`

const Value = styled.label`
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  overflow: hidden;
`
