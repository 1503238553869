/* eslint-disable no-await-in-loop */
import Items from 'api/Items'
import { TmrItem, TmrTag, TmrWorkstation } from 'api/types'
import { create, ApisauceInstance, ApiOkResponse } from 'apisauce'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import RemoteConfig from './RemoteConfig'
import Sounds from './Sounds'
import { showToast, sleep } from './utils'

interface ItemsMap {
  [epc: string]: TmrItem | any | null // TODO improve this
}

class RfidReader {
  deviceManagerApi!: ApisauceInstance
  webSocket?: WebSocket[]
  storageKey = 'workstation'
  sessionId = 'client1'
  wakeUpSocketTimeout?: NodeJS.Timeout
  tagsBatchInterval
  workstation?: TmrWorkstation
  tags!: TmrTag[]
  pendingTags!: TmrTag[]

  onTagReadCallback?: (tag: TmrTag) => void
  onStopCallback?: () => void
  onStartCallback?: () => Promise<void>
  onDecodedItemCallback?: (item: TmrItem | { epc: string }, tag?: TmrTag) => void
  onPendingTagsChangeAntennaButton?: (pendingTags: number) => void
  connectionFailedCallback?: () => void
  onStartCallbackAntennaButton?: () => void
  onStopCallbackAntennaButton?: () => void
  decodeFunction?: (epcs: string[]) => Promise<any>

  async initialize() {
    try {
      this.onDecodedItemCallback = undefined
      this.onTagReadCallback = undefined
      this.decodeFunction = undefined
      this.tags = []
      this.pendingTags = []

      // if antenna is reading stop then
      if (this.isReading()) await this.turn('stop')

      // check if workstation was already chosen
      if (AppStore.defaultWorkstation) {
        this.workstation = AppStore.defaultWorkstation
        return
      }

      if (!AppStore.workstations || AppStore.workstations.length === 0) throw new Error('No workstations found!')

      // set default workstation or select the first one
      const defaultCode = AppStore.loggedUser?.place?.attributes?.default_workstation_code ?? false
      this.workstation = AppStore.workstations?.find((ws) => ws.code === defaultCode)
    } catch (error) {
      if (!AppStore.emulation) throw error
    }
  }

  clear() {
    this.tags = []
    this.pendingTags = []
  }

  removeTags(identifiers: string[]) {
    this.tags = this.tags.filter((tag) => !identifiers.includes(tag.epc))
  }

  setDecodeFunction(decodeFunction: (epcs: string[]) => Promise<any>) {
    this.decodeFunction = decodeFunction
  }

  isReading = (): boolean => {
    return !!this.webSocket && this.webSocket[0].readyState === WebSocket.OPEN
  }

  async emulateTag(rfidTag: Partial<TmrTag>) {
    this.onTagRead(JSON.stringify(rfidTag))
  }

  async emulateTags(tags: string[]) {
    this.emulateTagAuto(tags.map((t) => ({ epc: t })))
  }

  async emulateTagAuto(rfidTags: Partial<TmrTag> | Partial<TmrTag>[]) {
    this.onStartCallback && (await this.onStartCallback())
    this.onStartCallbackAntennaButton && (await this.onStartCallbackAntennaButton())
    if (Array.isArray(rfidTags)) {
      rfidTags.map((rfidTag) => this.onTagRead(JSON.stringify(rfidTag)))
    } else {
      this.onTagRead(JSON.stringify(rfidTags))
    }

    setTimeout(async () => {
      this.onStopCallback && (await this.onStopCallback())
      this.onStopCallbackAntennaButton && (await this.onStopCallbackAntennaButton())
    }, 1000)
  }

  decodeTag = (tags: TmrTag[]) => {
    const tagIndexes = tags.map((tag) => {
      return this.tags.findIndex((t) => t.epc === tag.epc)
    })
    if (this.decodeFunction) {
      this.decodeFunction(tags.map((t) => t.epc))
        .then((itemsMap: ItemsMap) => {
          tagIndexes.forEach((idx) => {
            this.tags[idx].pending = false
          })
          this.onPendingTagsChangeAntennaButton?.(this.tags.filter((t) => t.pending).length)
          Object.keys(itemsMap).forEach((key) => {
            const item = itemsMap && itemsMap[key]
            const tag = tags.find((t) => t.epc === key)
            const obj: any = {} // TODO: fatto così per non dover modificare Inbound/outbound
            obj[key] = item
            this.onDecodedItemCallback?.(obj ?? { epc: tag?.epc ?? key }, tag)
          })
        })
        .catch((err) => {
          this.tags = this.tags.filter((t) => !tags.map((tag) => tag.epc).includes(t.epc))
          this.onPendingTagsChangeAntennaButton?.(this.tags.filter((t) => t.pending).length)
          console.error(err)
        })
      return
    }
    Items.batchDecode(tags.map((t) => t.epc))
      .then((itemsMap: ItemsMap) => {
        tagIndexes.forEach((idx) => {
          this.tags[idx].pending = false
        })
        this.onPendingTagsChangeAntennaButton?.(this.tags.filter((t) => t.pending).length)
        Object.keys(itemsMap).forEach((key) => {
          const item = itemsMap && itemsMap[key]
          const tag = tags.find((t) => t.epc === key)
          this.onDecodedItemCallback?.(item ?? { epc: tag?.epc ?? key }, tag)
        })
      })
      .catch((err) => console.error(err))
  }

  onTagRead(stringTagData: string) {
    console.log(stringTagData)
    let tag: TmrTag
    try {
      tag = JSON.parse(stringTagData) as TmrTag
    } catch (err) {
      return
    }

    if (tag.epc === '') return

    const tagExists = this.tags.find((t) => (!!t.epc && t.epc === tag.epc) || (!!t.uid && t.uid === tag.uid))
    if (tagExists) return

    this.tags.push({ ...tag, pending: true })
    this.onPendingTagsChangeAntennaButton?.(this.tags.filter((t) => t.pending).length)

    if (this.onTagReadCallback) {
      this.onTagReadCallback(tag)
    } else if (this.tagsBatchInterval) {
      this.addPendingTag(tag)
    } else {
      console.log(tag)
      this.decodeTag([tag])
    }
  }

  async turn(type: 'start' | 'stop' = 'start'): Promise<string[] | undefined> {
    if (!this.workstation) return undefined

    this.deviceManagerApi = create({
      baseURL: `${this.workstation.deviceManagerBasePath}`,
      timeout: this.workstation.antennas?.[0].rfidReader.maxConnectionTimeout ?? 3000,
    })
    const wsConfigurations: string[] = []
    try {
      // eslint-disable-next-line consistent-return
      for (let index = 0; index < this.workstation.antennas.length; index++) {
        const antenna = this.workstation.antennas[index]
        await sleep(100)

        const response = await this.deviceManagerApi.post(
          `/rfidAntennas/${antenna.id}/${type}?sessionId=${this.sessionId}`,
          {
            type: `${antenna.rfidReader.type}`,
            id: antenna.rfidReader.code,
            connectionMode: antenna.rfidReader.connectionMode,
            ipAddress: antenna.rfidReader.ipAddress,
            rfidAntennas: this.workstation.antennas,
            maxConnectionTimeout: antenna.rfidReader.maxConnectionTimeout,
            port: antenna.rfidReader.port,
          }
        )

        if (response.ok) {
          if (type === 'start') {
            this.onStartCallback && (await this.onStartCallback())
            this.onStartCallbackAntennaButton && (await this.onStartCallbackAntennaButton())

            wsConfigurations.push(
              `${this.workstation?.deviceManagerWebsocketPath}/rfidAntennas/scannings?antennaId=${antenna.id}`
            )
          } else wsConfigurations.push('stopped')
        } else if (type === 'start') {
          if (!AppStore.emulation) {
            Sounds.error()
          }
          if (this.connectionFailedCallback) {
            this.connectionFailedCallback()
            return []
          }
          if (!AppStore.emulation) {
            showToast({
              sound: false,
              title: __(T.error.error),
              description: `Cannot start ${antenna.code}`,
              status: 'error',
            })
          }
        }
      }
    } catch (e) {
      if (!AppStore.emulation) {
        showToast({
          title: __(T.error.error),
          description: `Cannot start ${this.workstation.code}`,
          status: 'error',
        })
      }
      return []
    }
    if (type === 'start' && wsConfigurations.length > 0) {
      Sounds.scan()
    }
    return wsConfigurations
  }

  tagsBatchIntervalCallback = () => {
    if (Array.isArray(this.pendingTags) && this.pendingTags.length) {
      const processingTags: TmrTag[] = [...this.pendingTags]
      this.decodeTag(processingTags)
      const processedEpcs = processingTags.map((p) => p.epc)
      // cleanup failed tag list
      this.pendingTags = this.pendingTags.filter((t) => !processedEpcs.includes(t.epc))
    }
  }

  addPendingTag(tag: TmrTag) {
    this.pendingTags.push({ ...tag, pending: true })
  }

  async start(
    onTagReadCallback?: (tag: TmrTag) => void,
    onStop?: () => void,
    connectionFailedCallback?: () => void
  ): Promise<boolean> {
    this.connectionFailedCallback = connectionFailedCallback
    if (onTagReadCallback) this.onTagReadCallback = onTagReadCallback

    const wsConfigurations = await this.turn('start')

    let tagsBatchIntervalTime =
      RemoteConfig.defaultTagsBatchInterval && RemoteConfig.defaultTagsBatchInterval > 0
        ? RemoteConfig.defaultTagsBatchInterval
        : 0
    const workstationIntervalTime =
      this.workstation && !Number.isNaN(Number(this.workstation.attributes.tagsBatchInterval))
        ? Number(this.workstation.attributes.tagsBatchInterval)
        : 0
    if (workstationIntervalTime > 0) tagsBatchIntervalTime = workstationIntervalTime

    if (!wsConfigurations || wsConfigurations.length === 0) {
      if (!AppStore.emulation) {
        showToast({
          title: __(T.error.error),
          description: `Cannot start ${this.workstation?.code}`,
          status: 'error',
        })
      } else if (this.workstation && tagsBatchIntervalTime > 0) {
        // register interval for retry tag decode on fail
        this.tagsBatchInterval = setInterval(this.tagsBatchIntervalCallback, tagsBatchIntervalTime)
      }
      return false
    }

    this.webSocket = []

    if (this.workstation && wsConfigurations.length && tagsBatchIntervalTime > 0) {
      // register interval for retry tag decode on fail
      this.tagsBatchInterval = setInterval(this.tagsBatchIntervalCallback, tagsBatchIntervalTime)
    }

    for (let i = 0; i < wsConfigurations.length; i += 1) {
      const webSocket = new WebSocket(wsConfigurations[i])

      if (webSocket) {
        webSocket.onmessage = (ev) => this.onTagRead(ev.data)
        webSocket.onerror = () => {
          onStop ? onStop() : this.stop()
        }
        webSocket.onclose = () => {
          onStop ? onStop() : this.stop()
        }

        this.webSocket?.push(webSocket)
        this.wakeUpSocket()
      } else {
        return false
      }
    }

    return true
  }

  stop = async (): Promise<boolean> => {
    const stopped = await this.turn('stop')

    this.webSocket?.forEach((ws) => ws.close())
    this.webSocket = undefined
    this.onStopCallback && (await this.onStopCallback())
    this.onStopCallbackAntennaButton && (await this.onStopCallbackAntennaButton())
    this.resetWakeupTimeout()
    if (this.tagsBatchInterval) {
      this.tagsBatchIntervalCallback()
      clearInterval(this.tagsBatchInterval)
      this.tagsBatchInterval = undefined
    }

    if (
      stopped &&
      this.workstation &&
      this.workstation.antennas.length === stopped.filter((elem) => elem === 'stopped').length
    ) {
      return true
    }
    return false
  }

  resetWakeupTimeout = () => {
    if (this.wakeUpSocketTimeout) {
      clearInterval(this.wakeUpSocketTimeout)
      this.wakeUpSocketTimeout = undefined
    }
  }

  wakeUpSocket = () => {
    this.resetWakeupTimeout()

    this.wakeUpSocketTimeout = setTimeout(() => {
      if (this.isReading()) {
        this.webSocket?.forEach((ws) => ws.send('{}'))
        this.wakeUpSocket()
      } else {
        this.webSocket?.forEach((ws) => ws.close())
        this.webSocket = undefined
      }
    }, 5000) as any
  }

  readTid = async (targetEpc: string) => {
    try {
      const type = 'read'
      const sessionId = 'client1'
      const memoryBank = 'TID'
      const address = '0'
      const numBlocks = '6'
      // const timeout = 1000
      const antennaUHF = this.workstation?.antennas.find((a) => a.rfidReader.supportedTagType === 'UHF')
      const { rfidReader } = antennaUHF!
      return this.deviceManagerApi.post<{ value: string }>(
        `/rfidAntennas/${
          antennaUHF!.id
        }/${type}?sessionId=${sessionId}&memoryBank=${memoryBank}&address=${address}&numBlocks=${numBlocks}&targetEpc=${targetEpc}`,
        {
          type: `${rfidReader.type}`,
          id: rfidReader.code,
          ipAddress: rfidReader.ipAddress,
          rfidAntennas: this.workstation?.antennas ?? [],
          maxConnectionTimeout: rfidReader.maxConnectionTimeout,
          port: rfidReader.port,
          connectionMode: rfidReader.connectionMode,
          transmitPower: rfidReader.transmitPower,
        }
      )
    } catch (err) {
      console.error(err)
      return undefined
    }
  }

  writeEpc = async (targetEpc: string, data: string) => {
    try {
      if (AppStore.emulation) {
        const ok: ApiOkResponse<{ value: string }> = {
          ok: true,
          problem: null,
          originalError: null,
          data: { value: targetEpc },
        }
        return ok
      }
      const type = 'write'
      const sessionId = 'client1'
      const memoryBank = 'EPC'
      const address = '2'
      const numBlocks = '6'
      // const timeout = 1000
      const antennaUHF = this.workstation?.antennas.find((a) => a.rfidReader.supportedTagType === 'UHF')
      const { rfidReader } = antennaUHF!
      return this.deviceManagerApi.post<{ value: string }>(
        `/rfidAntennas/${
          antennaUHF!.id
        }/${type}?sessionId=${sessionId}&memoryBank=${memoryBank}&address=${address}&numBlocks=${numBlocks}&targetEpc=${targetEpc}&data=${data}`,
        {
          type: `${rfidReader.type}`,
          id: rfidReader.code,
          ipAddress: rfidReader.ipAddress,
          rfidAntennas: this.workstation?.antennas ?? [],
          maxConnectionTimeout: rfidReader.maxConnectionTimeout,
          port: rfidReader.port,
          connectionMode: rfidReader.connectionMode,
          transmitPower: rfidReader.transmitPower,
        }
      )
    } catch (err) {
      console.error(err)
      return undefined
    }
  }
}

export default new RfidReader()
