import React, { useState } from 'react'
import { Box } from 'components'
import { Switch as ChakraSwitch } from '@chakra-ui/react'
import { MotionStyle } from 'framer-motion'
import styled from '@emotion/styled'

export default function BoxedSwitch({
  check,
  onChange,
  containerStyle,
  switchSize,
  icon,
}: {
  check?: boolean
  onChange: (checked: boolean) => void
  containerStyle?: MotionStyle
  switchSize?: 'sm' | 'md' | 'lg'
  icon?: any
}) {
  const [checked, setchecked] = useState(check)

  const onChangeFn = () => {
    setchecked(!checked)
    onChange(!checked)
  }

  return (
    <Container row vcenter style={containerStyle}>
      {icon}
      <Switch size={switchSize ?? 'lg'} isChecked={checked} defaultChecked={check} />
      <TouchableContainer onClick={onChangeFn} />
    </Container>
  )
}

const Container = styled(Box)`
  background: #fbfbfb;
  border: 2px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 13px;
  position: relative;
`

const TouchableContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px;
  height: 100%;
`

const Switch = styled(ChakraSwitch)`
  margin-left: 10px;
  margin-top: 3px;
`
