import React, { Component } from 'react'
import { Page, Placeholder, PlaceholderError, Box } from 'components'
import { getLocationState, navigate, navigateBack } from 'shared/router'
import { askUserConfirmation, showToast, sleep } from 'shared/utils'
import {
  ProductionOrder,
  ProductionOrderRow,
  TmrTag,
  ReadIdentifierType,
  TmrItem,
  RpacIdentifier,
  CheckSeason
} from 'api/types'
import RfidReader from 'shared/RfidReader'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import Sounds from 'shared/Sounds'
import RemoteConfig, { EncodingConfig, fakeEncodingOperation } from 'shared/RemoteConfig'
import EncodingProvider from 'pages/Encoding/EncodingProvider'
import IdentifiersGrid from 'pages/Encoding/IdentifiersGrid'
import Items from 'api/Items'
import CustomCertilogoInputBox from './CustomCertilogoInputBox'
import Encodings from 'api/Encodings'
import CustomChangeCode from 'api/CustomChangeCode'

export type EncodingPageParams = {
  navigateBack?: boolean
  data?: any
  hideOptions?: boolean
  title?: string
  autoBackOnEncoded?: boolean
}

interface State {
  productionOrderValue?: string
  productionOrderRowValue?: string
  errorProductionOrder?: string
  identifiers: ReadIdentifierType[]
  errorProductionOrderRow?: any
  productionOrder?: ProductionOrder
  productionOrderRow?: ProductionOrderRow
  validateResponse?: any
  associateResponse?: any
  readerError: boolean
  canReset: boolean
  locationState?: EncodingPageParams
  item?: TmrItem
  readFromTag? : string
}
// eslint-disable-next-line @typescript-eslint/ban-types
export default class CustomChangeTag extends Component<{}, State> {
  state: State = {
    identifiers: [],
    readerError: false,
    canReset: false,
    locationState: getLocationState(this.props),
    readFromTag : undefined
  }

  operation = RemoteConfig.getOperationConfig<EncodingConfig>(fakeEncodingOperation)

  encodingInitialType = RemoteConfig.getEncodingInitialType(this.operation)

  timer!: NodeJS.Timeout

  ignoreEpcs: string[] = []

  inputProductionOrder = React.createRef<HTMLInputElement>()

  inputEAN = React.createRef<HTMLInputElement>()

  async componentDidMount() {
    if (AppStore.loggedUser?.place?.attributes?.changeTagEnabled !== 'true' && !AppStore.loggedUser?.admin) {
      navigate('/')
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.error.unauthorized),
        status: 'error',
      })
      return
    }
    if ((!AppStore.workstations || AppStore.workstations.length === 0) && !AppStore.emulation) {
      navigate('/')
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      return
    }

    await RfidReader.initialize()
    this.stopReader()
    switch (this.encodingInitialType) {
      case 'ean':
        this.inputEAN?.current?.focus()
        break
      case 'certilogo':
        this.inputProductionOrder?.current?.focus()
        break
      case 'order':
      default:
        this.inputProductionOrder?.current?.focus()
        break
    }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  stopReader = async () => {
    RfidReader.clear()
    await RfidReader.stop()
  }

  resetState = () =>
    this.setState({
      errorProductionOrderRow: undefined,
      productionOrderRow: undefined,
      identifiers: [],
    })

  clearProduct = () => {
    this.ignoreEpcs = []
    this.stopReader()
    this.setState({
      errorProductionOrderRow: undefined,
      productionOrderRow: undefined,
      identifiers: [],
    })
  }

  clearProductionOrder = () => {
    this.stopReader()
    this.clearProduct()
    this.setState(
      {
        errorProductionOrderRow: undefined,
      },
      () => {
        this.inputProductionOrder?.current?.focus()
      }
    )
  }

  resetReading = () => {
    const { productionOrderRow } = this.state
    const idfs: any = []
    productionOrderRow?.product.itemConfiguration.identifiers
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1
        }
        if (b.type > a.type) {
          return -1
        }
        return 0
      })
      .forEach((idf) => {
        for (let index = 0; index < idf.amount; index++) {
          idfs.push({ status: 'waiting', type: idf.type })
        }
      })
    this.setState({
      identifiers: idfs,
      canReset: false,
    })
    RfidReader.clear()
    if (!RfidReader.isReading()) RfidReader.start(this.onTagReadCallback)
  }

  onTagReadCallback = async (tag: TmrTag) => {
    const { item, identifiers, productionOrder } = this.state
    let decodedItem
    try {
      if (!tag.epc) throw new Error(__(T.misc.invalid_tag))

      const epcToWrite = item?.itemIdentifiers?.find((id) => id.identifierType === 'UHFTag')
      if (!epcToWrite || !epcToWrite.code) throw new Error(__(T.error.uhf_tag_not_found))

      decodedItem = await Items.decode(tag.epc)

      if (decodedItem && decodedItem.id) throw new Error(__(T.error.tag_already_associated))

      let newEpc = await Encodings.nextEpc(item!.product.code)
      if (!newEpc || !newEpc.value) throw new Error(__(T.misc.unable_to_get_next_epc, { productCode: item!.product.code }))

      await RfidReader.stop()

      let checkSeason;
      try{
       checkSeason = await Encodings.checkSeason({
        prodOrdId: productionOrder!.id,
        oldEpc: tag.epc,
        newEpc: newEpc.value,
        operation: 'changeTag',
      }).then((res) => res as CheckSeason)
    } catch (error) {
      if (
        error.message.startsWith('EPC_NOT_FOUND_IN_RPAC_TAGS')
      ) {
        RfidReader.stop()
        await sleep(1000)
        const risp = await RfidReader.readTid(tag.epc)
        if (!risp || !risp?.data?.value) {
          Sounds.fail()
          throw new Error(__(T.error.unable_to_get_tid))
        }
        //recall check season
        checkSeason = await Encodings.checkSeason({
          prodOrdId: productionOrder!.id,
          oldEpc: tag.epc,
          newEpc: newEpc.value,
          operation: 'changeTag',
          tid: risp.data.value,
        }).then((res) => res as CheckSeason)
      } else {
        Sounds.fail()
        throw new Error(
          __(T.misc.unable_to_write, { code: tag.epc, productCode: productionOrder!.code })
        )
      }
    }

      const writeRes = await RfidReader.writeEpc(tag.epc, checkSeason.nextGeneratedEpc)
      if (!writeRes || !writeRes.ok)
        throw new Error(__(T.misc.unable_to_write, { code: newEpc.value, productCode: item!.product.code }))


      epcToWrite.code = newEpc.value

      const idfs = [...identifiers]
      for (let idf of idfs) {
        idf.code = item?.itemIdentifiers.find((i) => idf.type === i.identifierType)?.code
        if (idf.type === 'UHFTag') {
          idf.status = 'confirmed'
        }
      }

      const association = idfs.find((id) => id.type === 'association')
      if (association) {
        association.status = 'confirmed'
      }

      const changeEpcRes = await CustomChangeCode.changeEpc(
        {
          itemId: item!.id,
          productionOrderId: item!.productionOrderRow?.order.id,
          productId: item!.product.id,
          identifiers: idfs.filter((idf) => idf.code) as any,
        },
        checkSeason.epc ?? tag.epc
      )

      if (!changeEpcRes?.success) {
        Sounds.fail()
        throw new Error(changeEpcRes?.errors?.[0].errorCode ?? `Generic error`)
      }
      Sounds.success()
      this.setState({ identifiers: idfs })
      await sleep(1000)
    } catch (err) {
      showToast({ status: 'error', description: err.message })
    } finally {
      this.clearProduct()
    }
  }

  searchProduct = async (productCode: string) => {
    // if the user scans a certilogo qrcode eg. http://certilogo.com/qr/006FWY6B5B, select only the code
    if (this.operation.identifierMatchRegex && this.encodingInitialType === 'certilogo') {
      const match = productCode.match(this.operation.identifierMatchRegex) ?? []
      productCode = match[0] || productCode
    }

    try {
      this.setState({ readerError: false })

      const decodedItem = await Items.decode(productCode)

      if (!decodedItem || !decodedItem.id) throw new Error(__(T.error.not_found))

      const idfs = [] as ReadIdentifierType[]

      decodedItem.itemIdentifiers
        .sort((a, b) => {
          if (a.type > b.type) {
            return 1
          }
          if (b.type > a.type) {
            return -1
          }
          return 0
        })
        .forEach((idf) => {
          idfs.push({ status: 'waiting', type: idf.identifierType })
        })

      this.setState({ errorProductionOrderRow: undefined })
      Sounds.tap()
      await RfidReader.initialize()
      await RfidReader.stop()
      const newState = {
        identifiers: idfs,
        productionOrderRow: decodedItem.productionOrderRow,
        productionOrder: decodedItem.productionOrderRow?.order,
        readerError: false,
        associateResponse: undefined,
        validateResponse: undefined,
        productionOrderRowValue: productCode,
        item: decodedItem,
      }
      const antennaStarted = await RfidReader.start(this.onTagReadCallback)
      if (antennaStarted) {
        this.setState(newState)
      } else if (AppStore.emulation) {
        this.setState(newState)
        showToast({
          title: __(T.misc.info),
          description: 'Emulation enabled, cannot connect to workstations',
          status: 'info',
        })
      }
      return
    } catch (err) {
      Sounds.error()
      this.setState({
        errorProductionOrderRow: EncodingProvider.getEncodingMessageError(err, productCode, this.encodingInitialType),
      })
    }
  }

  onResetDailyItems = async () => {
    if (await askUserConfirmation(__(T.titles.reset_daily_items), __(T.messages.are_you_sure_to_reset_daily_items))) {
      AppStore.resetDailyItems()
      this.forceUpdate()
    }
  }

  render() {
    const {
      productionOrderRow,
      errorProductionOrderRow,
      identifiers,
      readerError,
      canReset,
      locationState,
      item,
    } = this.state

    const title = __(T.titles.change_tag)

    return (
      <Page title={title} enableEmulation onBackPress={locationState?.navigateBack ? navigateBack : undefined}>
        <Page.Sidebar>
          <CustomCertilogoInputBox
            productionOrderRow={productionOrderRow}
            errorProductionOrderRow={errorProductionOrderRow}
            inputEAN={this.inputEAN}
            searchProduct={this.searchProduct}
            clearProduct={this.clearProduct}
            item={item}
          />
        </Page.Sidebar>
        <Page.Content>
          {readerError && !AppStore.emulation && (
            <Box center>
              <PlaceholderError>{__(T.misc.unable_to_connect_to_workstation)}</PlaceholderError>
            </Box>
          )}
          {productionOrderRow && (
            <IdentifiersGrid
              identifiers={
                identifiers.length > 0 ? [...identifiers.filter((i) => i.type === 'UHFTag' || i.type === 'NFCTag')] : []
              }
              onTagReadCallback={this.onTagReadCallback}
              removeErrorTag={EncodingProvider.checkErrorTag(identifiers) || canReset ? this.resetReading : undefined}
              placeholder={
                identifiers.length === 0 && (
                  <Placeholder style={{ width: 370 }}>{__(T.misc.enter_the_necessary_fields)}</Placeholder>
                )
              }
            />
          )}
        </Page.Content>
      </Page>
    )
  }
}
