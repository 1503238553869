import React, { Component } from 'react'
import { CustomTmrItem, TmrPlace } from 'api/types'
import { Box, List } from 'components'
import styled from '@emotion/styled'
import { __, T } from 'translations/i18n'
import { getDatetime } from 'shared/utils'
import ProductionOrderCounterStatus from './ProductionOrderCounterStatus'

interface Props {
  items: CustomTmrItem
  checkboxHandleChange?: (event) => void
  isChecked?: boolean
  disabled?: boolean
  checkboxLabel?: string
  placeOfRequest?: TmrPlace | undefined
}

export default class ChangeCodeApprovalRow extends Component<Props> {
  getMadeFor = (items: CustomTmrItem) => {
    const madeFor = items?.madeFor
    return madeFor ? madeFor : '---'
  }

  getTone = (items: CustomTmrItem) => {
    const tone = items?.tone
    return tone ? tone : '---'
  }

  ellipsisLabel = (label?: string) => {
    if (!label || label.length < 1) return '---'
    if (label.length <= 10) return label
    return label.slice(0, 10) + '...'
  }

  render() {
    const { items, checkboxHandleChange, isChecked, checkboxLabel, disabled, placeOfRequest } = this.props

    return (
      <RowItem>
        {/*<Box style={{ width: 200 }}>*/}
        {/*  <Box style={{ fontWeight: 700, marginBottom: 5 }}>{__(T.misc.status)}</Box>*/}
        {/*  <ProductionOrderCounterStatus width={200} status={items.changeStatus + ''} />*/}
        {/*</Box>*/}
        <List.Cell style={{ width: 200 }} label={__(T.misc.phase)} value={items.phase?.code} />
        <List.Cell style={{ width: 200 }} label={__(T.misc.executionPhase)} value={items.executionPhase?.code ?? ''} />
        <List.Cell style={{ width: 200 }} label={__(T.fields.supplier)} value={items?.supplier?.description ?? ''} />
        <List.Cell style={{ width: 200 }} label={__(T.misc.color)} value={items.product.color?.value} />
        <List.Cell style={{ width: 200 }} label={__(T.misc.size)} value={items.product.size?.value} />
        <List.Cell style={{ width: 200 }} label={__(T.misc.newColor)} value={items?.newColor ?? ''} />
        <List.Cell style={{ width: 200 }} label={__(T.misc.newSize)} value={items?.newSize ?? ''} />
        <List.Cell
          style={{ width: 200 }}
          label={__(T.misc.certilogo)}
          value={items.itemIdentifiers.find((idf) => idf.identifierType === 'CertilogoIdentifier')?.code}
        />

        {checkboxHandleChange && (
          <Box style={{ alignItems: 'center' }}>
            {checkboxLabel && (
              <Box height={24} style={{ fontWeight: 700, fontSize: 16, marginBottom: 5 }}>
                {checkboxLabel}
              </Box>
            )}
            <Box>
              <CheckBoxStyle
                type="checkbox"
                style={{ padding: 10, verticalAlign: 'middle' }}
                id={items.id}
                value={items.id}
                onClick={(event: any) => {
                  event.stopPropagation()
                }}
                onChange={(event: any) => {
                  checkboxHandleChange(event)
                }}
                defaultChecked={false}
                checked={isChecked}
                disabled={disabled}
              />
            </Box>
          </Box>
        )}
      </RowItem>
    )
  }
}

const CheckBoxStyle = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  transform: scale(1.5);
  :focus {
    outline: black;
  }
`
const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`
