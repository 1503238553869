import React, { Component } from 'react'
import { Page, Icons, Box, Form } from 'components'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import { getMatchParams, navigate } from 'shared/router'
import { T, __ } from 'translations/i18n'
import OutboundShipments from 'api/OutboundShipments'
import { ShippingParcelCreateRequest } from 'api/types'
import { showToast } from 'shared/utils'

interface State {
  shipmentCode: string
  parcelCode?: string
  destination?: string
}

export default class CustomOutboundAppesi extends Component {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    shipmentCode: '',
  }

  onSubmit = async (data: { parcelCode: string }) => {
    if (!data.parcelCode) throw new Error(__(T.error.parcel_not_found))

    const request: ShippingParcelCreateRequest = {
      configurationId: this.operation.id,
      parcelCode: data.parcelCode,
    }

    try {
      const response = await OutboundShipments.createParcel(request)
      if (!response || !response?.header.shippingCode || !response?.header.parcelCode)
        throw new Error('No Parcel created')

      this.setState({
        shipmentCode: response.header.shippingCode,
        parcelCode: response.header.parcelCode,
        destination: response.header.destinationPlace,
      })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? __(T.error.parcel_not_found),
        status: 'error',
      })
      return
    }

    if (this.operation.isCustomGateOutbound === 'yes' && this.operation.outboundMode === 'parcelByParcel') {
      navigate('/outbound/gate/:configCode/reading/:parcelCode', {
        configCode: this.operation.code,
        parcelCode: this.state.parcelCode,
      })
    }
  }

  render() {
    return (
      <Page title={`${this.operation?.description ?? 'Outbound'}`} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  label: __(T.misc.parcel),
                  name: 'parcelCode',
                  image: <Icons.Barcode />,
                  focus: true,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.onSubmit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
