import { ApisauceConfig } from 'apisauce'
import api, { responseErrorCheck } from './api'
import TmrBaseResource from './TmrBaseResource'

export default class TransferToZone extends TmrBaseResource {
  static endpoint = '/transfersToZone'

  static customTransferToZone(request: any, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`/custom${this.endpoint}`, request, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res.data)
  }
}
