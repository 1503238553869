import React, { Component } from 'react'
import { Box, Button } from 'components'
import styled from '@emotion/styled'
import { getChoiceOptions } from 'shared/utils'
import { Choice } from 'api/types'
import Modal from './Modal'

interface Props {
  title: string
  isOpen?: boolean
  onSelect?: (choice: string) => void
  onClose: () => void
  style?: any
  selectedOption?: Choice
}

interface State {
  options: any[]
}

export default class QualityChoiceModal extends Component<Props> {
  state: State = {
    options: [],
  }

  async componentDidMount() {
    this.setState({ options: await getChoiceOptions() })
  }

  render() {
    const { isOpen, onClose, title, onSelect, selectedOption } = this.props
    const { options } = this.state
    return (
      <Modal visible={isOpen ?? true} onClose={onClose}>
        <Box center>
          <Label>{title}</Label>
          <GridView>
            {options.map((op) => {
              return (
                <Button
                  title={op.label}
                  style={{ margin: 10, flex: 1 }}
                  variant={selectedOption && selectedOption.code === op.value ? 'primary' : 'default'}
                  onClick={() => {
                    onSelect && onSelect(op.value)
                  }}
                />
              )
            })}
          </GridView>
        </Box>
      </Modal>
    )
  }
}

const Label = styled.label`
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 20px;
`

const GridView = styled.div`
  max-width: 500px;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  align-self: center;
  padding-top: 20px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
