import React, { Component } from 'react'
import { Page, Tab, Placeholder, PlaceholderError, Box } from 'components'
import { getLocationState, navigate, navigateBack } from 'shared/router'
import { askUserConfirmation, showToast, sleep } from 'shared/utils'
import Encodings from 'api/Encodings'
import { ProductionOrder, ProductionOrderRow, TmrTag, ReadIdentifierType, CheckSeason, RpacIdentifier } from 'api/types'
import RfidReader from 'shared/RfidReader'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import Sounds from 'shared/Sounds'
import RemoteConfig, { EncodingConfig, fakeEncodingOperation } from 'shared/RemoteConfig'
import ProductionOrderInputBox from './ProductionOrderInputBox'
import ProductionOrderRowInputBox from './ProductionOrderRowInputBox'
import IdentifiersGrid from './IdentifiersGrid'
import CertilogoInputBox from './CertilogoInputBox'
import EncodingProvider, { EncodingType } from './EncodingProvider'

export type EncodingPageParams = {
  navigateBack?: boolean
  data?: any
  encodeFn?: EncodingType
  hideOptions?: boolean
  title?: string
  autoBackOnEncoded?: boolean
  disableValidationCheck?: string[]
  disableWrite?: boolean
  disableQuantityVerify?: boolean
  validatedEpc?: string
  tid?: string
}

interface State {
  productionOrderValue?: string
  productionOrderRowValue?: string
  errorProductionOrder?: string
  identifiers: ReadIdentifierType[]
  errorProductionOrderRow?: any
  productionOrder?: ProductionOrder
  productionOrderRow?: ProductionOrderRow
  validateResponse?: any
  associateResponse?: any
  options: any
  readerError: boolean
  associating: boolean
  canReset: boolean
  locationState?: EncodingPageParams
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default class Encoding extends Component<{}, State> {
  state: State = {
    identifiers: [],
    options: [
      { value: 'associate', label: __(T.misc.associate), active: true },
      { value: 'verify', label: __(T.misc.verify), active: false },
    ],
    readerError: false,
    associating: false,
    canReset: false,
    locationState: getLocationState(this.props),
  }

  operation = RemoteConfig.getOperationConfig<EncodingConfig>(fakeEncodingOperation)

  encodingInitialType = RemoteConfig.getEncodingInitialType(this.operation)

  timer!: NodeJS.Timeout

  ignoreEpcs: string[] = []

  inputProductionOrder = React.createRef<HTMLInputElement>()

  inputEAN = React.createRef<HTMLInputElement>()

  async componentDidMount() {
    if ((!AppStore.workstations || AppStore.workstations.length === 0) && !AppStore.emulation) {
      navigate('/')
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      return
    }

    await RfidReader.initialize()
    this.stopReader()
    switch (this.encodingInitialType) {
      case 'ean':
        this.inputEAN?.current?.focus()
        break
      case 'certilogo':
        this.inputProductionOrder?.current?.focus()
        break
      case 'order':
      default:
        this.inputProductionOrder?.current?.focus()
        break
    }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  stopReader = async () => {
    RfidReader.clear()
    await RfidReader.stop()
  }

  resetState = () =>
    this.setState({
      errorProductionOrder: undefined,
      errorProductionOrderRow: undefined,
      productionOrderRow: undefined,
      associateResponse: undefined,
      validateResponse: undefined,
      productionOrderValue: '',
      productionOrderRowValue: '',
      productionOrder: undefined,
      identifiers: [],
    })

  clearProduct = () => {
    this.ignoreEpcs = []
    this.stopReader()
    if (this.encodingInitialType === 'certilogo') {
      this.resetState()
      return
    }
    this.setState({
      errorProductionOrder: undefined,
      errorProductionOrderRow: undefined,
      productionOrderRowValue: '',
      productionOrderValue: '',
      productionOrderRow: undefined,
      associateResponse: undefined,
      validateResponse: undefined,
      identifiers: [],
    })
  }

  clearProductionOrder = () => {
    this.stopReader()
    this.clearProduct()
    this.setState(
      {
        errorProductionOrderRow: undefined,
        errorProductionOrder: undefined,
        productionOrderValue: '',
        productionOrder: undefined,
      },
      () => {
        this.inputProductionOrder?.current?.focus()
      }
    )
  }

  resetReading = () => {
    const { productionOrderRow } = this.state
    const idfs: any = []
    productionOrderRow?.product.itemConfiguration.identifiers
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1
        }
        if (b.type > a.type) {
          return -1
        }
        return 0
      })
      .forEach((idf) => {
        for (let index = 0; index < idf.amount; index++) {
          idfs.push({ status: 'waiting', type: idf.type })
        }
      })
    this.setState({
      identifiers: idfs,
      validateResponse: undefined,
      associateResponse: undefined,
      associating: false,
      canReset: false,
    })
    RfidReader.clear()
    if (!RfidReader.isReading()) RfidReader.start(this.onTagReadCallback)
  }

  verify = async (idfs: any[]) => {
    try {
      const { productionOrder, productionOrderRow, productionOrderRowValue } = this.state
      if (idfs.length === 0 || !productionOrderRow) return

      //Add certilogo identifier code if exists
      const certilogoIndex = idfs.findIndex((idf) => idf.type === 'CertilogoIdentifier')
      if (certilogoIndex >= 0) {
        idfs[certilogoIndex] = { code: productionOrderRowValue, status: 'reading', type: 'CertilogoIdentifier' }
      }

      const res = await Encodings.validate(idfs, {
        productionOrderId: productionOrder?.id,
        productId: productionOrderRow?.product.id,
        identifiers: idfs.filter((idf) => !!idf.code) as any,
        disableValidationCheck: this.state.locationState?.disableValidationCheck ?? [],
      })

      this.setState({ identifiers: idfs, validateResponse: res })
      if (!res.success) {
        clearTimeout(this.timer)
        this.setState({ associateResponse: undefined })
        return
      }

      //If read tag is not equal to validated decoded item then error
      const validatedEpc = this.state.locationState?.validatedEpc
      const epcIndex = res.identifiers.findIndex((id) => id.type === 'UHFTag')

      if (
        epcIndex !== -1 &&
        this.state.locationState?.encodeFn === 'CustomChangeCode' &&
        validatedEpc !== res.identifiers[epcIndex].code
      ) {
        RfidReader.stop()
        await sleep(1000)
        const risp = await RfidReader.readTid(res.identifiers[epcIndex].code)
        if (!risp?.data?.value) {
          Sounds.fail()
          throw new Error(__(T.error.unable_to_get_tid))
        }

        const rpacIdentifier: RpacIdentifier = await Encodings.getRpacTag(risp?.data.value)
        if (!rpacIdentifier?.epc || validatedEpc !== rpacIdentifier.epc) {
          throw new Error(__(T.error.tag_is_different_from_previously_read_tag))
        }
      }

      this.associate(idfs, productionOrder, productionOrderRow)
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  associate = (idfs, productionOrder, productionOrderRow) => {
    this.setState({ associating: true })
    let virginEpc = undefined
    let savedEpc = undefined
    let checkSeason: CheckSeason | undefined
    //Start timer to delay the association (in case of multiple reading)
    this.timer = setTimeout(async () => {
      try {
        await RfidReader.stop()
        if (this.operation.enableWrite) {
          checkSeason = await EncodingProvider.writeUHFTags(
            idfs,
            productionOrderRow,
            productionOrder,
            this.state.locationState?.encodeFn,
            (nextEpc) => {
              this.ignoreEpcs.push(nextEpc)
              if (this.state.locationState?.encodeFn === 'CustomChangeCode') {
                if (idfs.find((id) => id.type === 'UHFTag')) {
                  const idfIndex = idfs.findIndex((id) => id.type === 'UHFTag')
                  savedEpc = idfs[idfIndex].code
                  idfs[idfIndex].code = nextEpc
                }
              } else {
                virginEpc = idfs.find((id) => id.type === 'UHFTag')?.code
              }
            }
          )
        } else if (this.state.locationState?.encodeFn === 'CustomChangeCode') {
          throw new Error(__(T.error.check_your_writing_capabilities))
        }
        if (virginEpc) {
          idfs.push({ code: virginEpc, type: 'virginEPC' })
        }

        //Adjust parameters for encoding request
        if (checkSeason?.tid) {
          const index = idfs.findIndex((id) => id.type === (this.isEncoding() ? 'virginEPC' : 'UHFTag'))
          idfs[index].code = checkSeason.nextGeneratedEpc
        }

        let associateRes
        try {
          associateRes = await EncodingProvider.encode(
            {
              productionOrderId: productionOrder?.id,
              productId: productionOrderRow?.product?.id,
              identifiers: idfs.filter((idf) => idf.code) as any,
              disableValidationCheck: this.state.locationState?.disableValidationCheck ?? [],
            },
            this.state.locationState?.encodeFn,
            this.state.locationState?.data
          ).then((res) => {
            this.ignoreEpcs.push(idfs[idfs.findIndex((id) => id.type === 'UHFTag')].code)
            return res
          })
          if (!associateRes?.success) {
            Sounds.fail()
            await this.onErrorDo(idfs, savedEpc, productionOrderRow)
          }
        } catch (err) {
          Sounds.fail()
          await this.onErrorDo(idfs, savedEpc, productionOrderRow, err)
        }
        if (virginEpc) {
          idfs.splice(
            idfs.findIndex((idf) => idf.type === 'virginEPC'),
            1
          )
        }
        this.ignoreEpcs = idfs.map((idf) => idf.code ?? '')
        this.setState({ associateResponse: associateRes, associating: false })

        await sleep(500)
        if (associateRes?.success) {
          Sounds.success()
          await AppStore.increaseDailyItems()
        }
        if (this.state.locationState?.autoBackOnEncoded) {
          navigateBack()
        }
        const EANCode = productionOrderRow?.product?.code || ''
        if (this.encodingInitialType === 'certilogo') {
          this.clearProduct()
          return
        }
        this.searchProduct(EANCode)
      } catch (error) {
        if (error && error.message) {
          showToast({
            title: __(T.error.error),
            description: error?.message ?? error,
            status: 'error',
          })
        }
        this.setState({ associateResponse: { success: false }, associating: false, canReset: true })
      }
    }, 1000)
  }

  onErrorDo = async (idfs: any, savedEpc: string | undefined, productionOrderRow: ProductionOrderRow, error?: any) => {
    const oldEpc = idfs[idfs.findIndex((id) => id.type === 'UHFTag')].code
    //Write OK, reencoding KO -> reset original tag
    if ('CustomChangeCode' === this.state.locationState?.encodeFn) {
      await sleep(50)
      if (!!error) {
        showToast({
          title: __(T.error.error),
          description: error?.message ?? 'Generic error',
          status: 'error',
        })
      }
      const writeRes = await RfidReader.writeEpc(oldEpc, savedEpc!)
      if (!writeRes || !writeRes.ok) {
        throw new Error(__(T.misc.unable_to_write, { code: savedEpc, productCode: productionOrderRow.product.code }))
      }
    }
    //Encoding error
    if (undefined === this.state.locationState?.encodeFn) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onTagReadCallback = async (tag: TmrTag) => {
    try {
      const { identifiers } = this.state
      const idfs = [...identifiers]

      EncodingProvider.onTagRead(tag, idfs, this.ignoreEpcs)

      this.setState({ identifiers: idfs })

      this.verify(idfs)
    } catch (err) {
      //excluded epc
    }
  }

  searchProduct = async (productCode: string) => {
    const { productionOrderValue } = this.state

    // if the user scans a certilogo qrcode eg. http://certilogo.com/qr/006FWY6B5B, select only the code
    if (this.operation.identifierMatchRegex && this.encodingInitialType === 'certilogo') {
      const match = productCode.match(this.operation.identifierMatchRegex) ?? []
      productCode = match[0] || productCode
    }

    try {
      this.setState({ readerError: false, errorProductionOrder: undefined })

      const { idfs, data } = await Encodings.getProductionOrderRows(this.encodingInitialType, {
        orderCode: productionOrderValue,
        identifier: productCode,
        productCode,
      })

      if (
        !this.state.locationState?.disableQuantityVerify &&
        this.encodingInitialType === 'certilogo' &&
        data.encoded >= data.quantity
      ) {
        throw new Error('ENCODING_ERROR.CERTILOGO_ALREADY_ENCODED')
      }

      this.setState({ errorProductionOrderRow: undefined })
      Sounds.tap()
      await RfidReader.initialize()
      await RfidReader.stop()
      const newState = {
        identifiers: idfs,
        productionOrderRow: data,
        productionOrder: data.order,
        readerError: false,
        associateResponse: undefined,
        validateResponse: undefined,
        productionOrderRowValue: productCode,
      }
      const antennaStarted = await RfidReader.start(this.onTagReadCallback)
      if (antennaStarted) {
        this.setState(newState)
      } else if (AppStore.emulation) {
        this.setState(newState)
        showToast({
          title: __(T.misc.info),
          description: 'Emulation enabled, cannot connect to workstations',
          status: 'info',
        })
      }
      return
    } catch (err) {
      Sounds.error()
      this.setState({
        errorProductionOrderRow: EncodingProvider.getEncodingMessageError(err, productCode, this.encodingInitialType),
      })
    }
  }

  searchProductionOrder = async (value: string) => {
    try {
      const data = await Encodings.getProductionOrder(value)
      if (!data) throw new Error()
      Sounds.tap()
      this.setState({
        errorProductionOrder: undefined,
        productionOrderValue: value,
        productionOrderRowValue: '',
        productionOrder: data,
      })
      await sleep(100)
      this.inputEAN?.current?.focus()
    } catch (error) {
      this.setState({ errorProductionOrder: __(T.error.production_order_not_found, { code: value }) })
    }
  }

  onResetDailyItems = async () => {
    if (await askUserConfirmation(__(T.titles.reset_daily_items), __(T.messages.are_you_sure_to_reset_daily_items))) {
      AppStore.resetDailyItems()
      this.forceUpdate()
    }
  }

  isEncoding = () =>{
    return this.state.locationState?.encodeFn === undefined
  }

  isChangeCode = () => {
    return this.state.locationState?.encodeFn === 'CustomChangeCode'
  }

  render() {
    const {
      productionOrder,
      errorProductionOrder,
      productionOrderRow,
      errorProductionOrderRow,
      productionOrderValue,
      options,
      identifiers,
      validateResponse,
      associateResponse,
      readerError,
      associating,
      canReset,
      locationState,
    } = this.state

    const title = locationState?.title ?? 'Encoding'

    const headerRight = !locationState?.hideOptions ? (
      <Tab options={options} onOptionSelected={EncodingProvider.getOptionEncodingPage} />
    ) : undefined

    return (
      <Page
        title={title}
        header={{
          details: [
            {
              label: __(T.misc.items),
              value: `${AppStore.dailyItems}`,
              onPress: AppStore.dailyItems > 0 ? this.onResetDailyItems : undefined,
            },
          ],
        }}
        headerRight={headerRight}
        enableEmulation
        onBackPress={locationState?.navigateBack ? navigateBack : undefined}
      >
        <Page.Sidebar>
          {(this.encodingInitialType === 'order' || productionOrder) && (
            <ProductionOrderInputBox
              productionOrder={productionOrder}
              productionOrderValue={productionOrderValue}
              errorProductionOrder={errorProductionOrder}
              inputProductionOrder={this.inputProductionOrder}
              clearProductionOrder={this.clearProductionOrder}
              searchProductionOrder={this.searchProductionOrder}
            />
          )}
          {((productionOrder && this.encodingInitialType === 'order') || this.encodingInitialType === 'ean') && (
            <ProductionOrderRowInputBox
              productionOrderRow={productionOrderRow}
              errorProductionOrderRow={errorProductionOrderRow}
              inputEAN={this.inputEAN}
              searchProduct={this.searchProduct}
              clearProduct={this.clearProduct}
              searchProductionOrderSetting={this.encodingInitialType === 'order'}
            />
          )}

          {this.encodingInitialType === 'certilogo' && (
            <CertilogoInputBox
              productionOrderRow={productionOrderRow}
              errorProductionOrderRow={errorProductionOrderRow}
              inputEAN={this.inputEAN}
              searchProduct={this.searchProduct}
              clearProduct={this.clearProduct}
              searchProductionOrderSetting={this.encodingInitialType === 'certilogo'}
            />
          )}
        </Page.Sidebar>
        <Page.Content>
          {readerError && !AppStore.emulation && (
            <Box center>
              <PlaceholderError>{__(T.misc.unable_to_connect_to_workstation)}</PlaceholderError>
            </Box>
          )}
          {productionOrderRow && (
            <IdentifiersGrid
              productionOrderRow={productionOrderRow}
              identifiers={
                identifiers.length > 0
                  ? [
                      ...identifiers.filter((i) => i.type === 'UHFTag' || i.type === 'NFCTag'),
                      //Add associating operation box
                      EncodingProvider.getAssociationStatus(
                        associateResponse,
                        associating,
                        validateResponse,
                        this.operation.enableWrite
                      ),
                    ]
                  : []
              }
              onTagReadCallback={this.onTagReadCallback}
              removeErrorTag={EncodingProvider.checkErrorTag(identifiers) || canReset ? this.resetReading : undefined}
              placeholder={
                identifiers.length === 0 && (
                  <Placeholder style={{ width: 370 }}>{__(T.misc.enter_the_necessary_fields)}</Placeholder>
                )
              }
            />
          )}
        </Page.Content>
      </Page>
    )
  }
}
