import React, { Component } from 'react'
import { Box, Form } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import Modal from './Modal'

interface Props {
  title: string
  subtitle?: string
  isOpen?: boolean
  onConfirm: (code: string, description: string) => void
  onClose: () => void
  style?: any
}

export default class BayFormModal extends Component<Props, {}> {
  submit = async (data) => {
    if (!data.code || !data.description) return

    this.props.onConfirm(data.code, data.description)
  }

  render() {
    const { isOpen, onClose, title, subtitle } = this.props
    return (
      <Modal visible={isOpen ?? true} size="lg" onClose={onClose}>
        <Box>
          <Title>{title}</Title>
          {subtitle && <Value>{subtitle}</Value>}
          <Form
            schema={[
              {
                placeholder: __(T.fields.code),
                name: 'code',
                required: true,
              },
              {
                placeholder: __(T.fields.description),
                name: 'description',
                required: true,
              },
            ]}
            submitText="Salva"
            onSubmit={this.submit}
          />
        </Box>
      </Modal>
    )
  }
}

const Title = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
`

const Value = styled.label`
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  overflow: hidden;
`
