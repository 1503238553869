import TmrBaseResource from './TmrBaseResource'
import api, { responseErrorCheck } from './api'
import { PackingListFilter, PhaseOrderSupplier, PhaseOrderSupplierFilter } from './types'

export default class PhaseOrderSuppliers extends TmrBaseResource {
  static endpoint = '/phaseOrderSupplier'

  static get(phaseOrderSupplierFilter: PhaseOrderSupplierFilter) {
    return api
      .get<PhaseOrderSupplier[]>(`${this.endpoint}`, phaseOrderSupplierFilter)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
