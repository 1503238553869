import CustomChangeCode from 'api/CustomChangeCode'
import Encodings, { ValidationBodyRequest } from 'api/Encodings'
import {
  CheckSeason,
  IdentifierStatus,
  IdentifierType,
  ProductionOrder,
  ProductionOrderRow,
  ReadIdentifierType,
  TmrTag,
} from 'api/types'
import { EncodingInitialType } from 'shared/RemoteConfig'
import RfidReader from 'shared/RfidReader'
import { navigate } from 'shared/router'
import { showToast, sleep } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import Sounds from 'shared/Sounds'

export type EncodingType = 'CustomChangeCode'
export default class EncodingProvider {
  static async writeUHFTags(
    idfs: any[],
    productionOrderRow: ProductionOrderRow,
    productionOrder: ProductionOrder,
    operation: string | undefined,
    nextEpcCallback: (newEpc: string) => void
  ) {
    let newEpc
    let checkSeason: CheckSeason | undefined
    await sleep(200)
    for await (const idf of idfs) {
      if (idf.type === 'UHFTag') {
        newEpc = await Encodings.nextEpc(productionOrderRow.product.code)
        if (!newEpc || !newEpc.value) {
          throw new Error(__(T.misc.unable_to_get_next_epc, { productCode: productionOrderRow.product.code }))
        }
        if (!newEpc.value.startsWith('3035')) {
          throw new Error(__(T.misc.wrong_next_epc, { epc: newEpc }))
        }
        try {
          checkSeason = await Encodings.checkSeason({
            prodOrdId: productionOrder.id,
            oldEpc: idf.code,
            newEpc: newEpc.value,
            operation: this.getOperation(operation),
          }).then((res) => res as CheckSeason)
        } catch (error) {
          if (
            error.message.startsWith('EPC_NOT_FOUND_IN_RPAC_TAGS')
          ) {
            RfidReader.stop()
            await sleep(1000)
            const risp = await RfidReader.readTid(idf.code)
            if (!risp || !risp?.data?.value) {
              Sounds.fail()
              throw new Error(__(T.error.unable_to_get_tid))
            }
            //recall check season
            checkSeason = await Encodings.checkSeason({
              prodOrdId: productionOrder.id,
              oldEpc: idf.code,
              newEpc: newEpc.value,
              operation: this.getOperation(operation),
              tid: risp.data.value,
            }).then((res) => res as CheckSeason)
          } else {
            Sounds.fail()
            throw new Error(
              __(T.misc.unable_to_write, { code: idf.code, productCode: productionOrderRow.product.code })
            )
          }
        }

        if (checkSeason) {
          await sleep(50)
          const writeRes = await RfidReader.writeEpc(
            idf.code,
            checkSeason.nextGeneratedEpc
          )
          if (!writeRes || !writeRes.ok) {
            throw new Error(
              __(T.misc.unable_to_write, { code: idf.code, productCode: productionOrderRow.product.code })
            )
          }
          nextEpcCallback(checkSeason.nextGeneratedEpc)
          idf.code = checkSeason.nextGeneratedEpc
        }
      }
    }
    return checkSeason
  }

  static async encode(data: ValidationBodyRequest, custom?: EncodingType, extraData?: any) {
    switch (custom) {
      case undefined:
        return Encodings.associate(data)
      case 'CustomChangeCode':
        return CustomChangeCode.reEncode({ ...data, ...extraData })
      default:
        return undefined
    }
  }

  static getOptionEncodingPage(value) {
    switch (value) {
      case 'associate':
        navigate('/encoding')
        break
      case 'verify':
        navigate('/encoding/verify')
        break
      default:
        navigate('/encoding')
        break
    }
  }

  static getEncodingMessageError(err, productCode, encodingInitialType?: EncodingInitialType) {
    let errorMessage
    const error = err.message ?? 'Codice Certilogo non trovato'
    if (error.includes('ENCODING_ERROR.CERTILOGO_NOT_FOUND')) {
      err.message = 'Codice Certilogo non trovato'
    }
    if (encodingInitialType === 'certilogo') {
      if (
        error.includes('ENCODING_ERROR.CERTILOGO_ALREADY_ENCODED') ||
        error.includes('ENCODING_ERROR.CERTILOGO_ALREADY_ASSOCIATED')
      ) {
        errorMessage = __(T.error.certilogo_already_encoded, { code: productCode })
      } else {
        errorMessage = __(T.error.certilogo_not_found, { code: productCode })
      }
    } else {
      errorMessage = __(T.error.ean_not_found, { code: productCode })
    }
    return errorMessage
  }

  static onTagRead(tag: TmrTag, idfs, ignoreEpcs?) {
    let code = ''
    let tagType: IdentifierType

    if (tag.uid) {
      code = tag.uid
      tagType = 'NFCTag'
    } else {
      code = tag.epc
      tagType = 'UHFTag'
    }
    if (ignoreEpcs && ignoreEpcs.includes(code)) {
      throw new Error('Excluded epc')
    }
    let index = idfs.findIndex((i) => i.code === code && i.type === tagType)
    if (index === -1) {
      index = idfs.findIndex((i) => !i.code && i.type === tagType && i.status === 'waiting')
    } else if (idfs[index].status === 'confirmed' || idfs[index].status === 'error') {
      return
    }

    if (index >= 0) {
      idfs[index].code = code
      idfs[index].status = 'reading'
    } else {
      idfs.push({ code: code, status: 'reading', type: tagType })
    }
  }

  static getAssociationStatus(associateResponse, associating, validateResponse, writeEnabled?: boolean) {
    let associationStatus: IdentifierStatus = 'waiting'

    if (associateResponse) {
      if (associateResponse.success) {
        associationStatus = 'confirmed'
      } else {
        associationStatus = 'error'
      }
    }
    if (associating) {
      associationStatus = 'associating'
    }

    let status = validateResponse?.success === true ? associationStatus : 'hidden'
    if (validateResponse?.status_error === 'ENCODING_ERROR.PRODUCTION_ORDER_NOT_FOUND') {
      status = 'error'
    }
    const operationIdentifier = {
      type: writeEnabled ? 'write' : 'association',
      status: status,
    } as ReadIdentifierType

    return operationIdentifier
  }

  static checkErrorTag = (identifiers: any[] = []) =>
    identifiers.filter((i) => (i.type === 'UHFTag' || i.type === 'NFCTag') && i.code && i.status === 'error').length > 0

  static getOperation = (operation: string | undefined) => {
    if (!operation) {
      return 'encoding'
    }
    return 'reencoding'
  }
}
