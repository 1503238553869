import React, { Component } from 'react'
import { Page, Spacer, TagCounter, Box, TextBox, Button, Icons, Section, ParcelSectionHeader, Table } from 'components'
import {
  GroupedShipmentProduct,
  QualityControlEntry,
  ShipmentParcel,
  ShipmentParcelItem,
  TmrItem,
  TmrZone,
} from 'api/types'
import { navigate, getMatchParams, getLocationState } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import { T, __, __UP } from 'translations/i18n'
import Shipments from 'api/Shipments'
import Gate from 'api/Gate'
import { PageParams } from 'pages'
import Sounds from 'shared/Sounds'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import styled from '@emotion/styled'
import Items from 'api/Items'
import * as Onde from 'assets/onde.json'
import Lottie from 'react-lottie'
import Structures from 'shared/Structures'
import InboundShipments from 'api/InboundShipments'
import CustomInboundProvider from './CustomInboundProvider'
import AppStore from 'AppStore'
import CustomShipmentProvider from 'CustomShipmentProvider'

interface Params extends PageParams {
  inboundZone?: TmrZone
  ddtCode: string
  parcels: string
}

interface State {
  parcels: ShipmentParcel[]
  checkListType: CheckListType
  groupedProducts: GroupedShipmentProduct[]
  cqItems: { id: string }[]
  cqDestinationZone?: string
  expectedItems?: ShipmentParcelItem[]
  loading: boolean
  reading: boolean
  totalProductsQuantity: number
}

export default class CustomGateInboundShipmentReading extends Component<{}, State> {
  unexpectedFound = false
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  params: Params = {
    inboundZone: CustomInboundProvider.getDefaultStockZone(),
    ...getMatchParams(this.props),
  }

  state: State = {
    parcels: [],
    checkListType: 'ITEMS',
    totalProductsQuantity: 0,
    groupedProducts: [],
    cqItems: [],
    loading: true,
    reading: false,
  }

  async componentDidMount() {
    try {
      if (!this.params.inboundZone) throw new Error(__(T.error.no_inbound_zones))
      if (!this.operation) throw new Error(__(T.error.no_operation_config))
      if (this.operation.readingMode !== 'rfid') throw new Error(__(T.error.not_supported_reading_mode))

      const { ddtCode } = getMatchParams(this.props)
      const { shipments } = getLocationState(this.props)

      if (!shipments && shipments.length === 0) throw new Error(__(T.error.shipment_not_found))

      const {
        checkListType,
        inboundShipmentParcels,
        groupedProducts,
      } = await CustomInboundProvider.fetchInboundShipmentParcels({ shippingCodes: shipments }, false)

      if (!inboundShipmentParcels || inboundShipmentParcels.length === 0)
        throw new Error(__(T.error.no_parcels_for_ddt_found, { ddtCode: ddtCode }))

      let qcResponse: QualityControlEntry[] = []
      qcResponse = await InboundShipments.ddtQualityControls(shipments)
      if (!qcResponse) throw new Error(__(T.error.quality_control_info_not_found))
      Gate.initSSE(this.onTagRead, this.onError)

      const expectedItems = Array.from(inboundShipmentParcels.map((e) => e.expectedItems || []).values()).reduce(
        (x, y) => [...x, ...y],
        []
      )

      await CustomShipmentProvider.fetchItemsFromShipmentParcelItems(expectedItems)
      this.setState({ expectedItems: inboundShipmentParcels[0].expectedItems })

      const destinationZone = AppStore.zones?.find(
        (zone) => zone.attributes?.['zone.replica.type']?.toUpperCase() === 'CQ'
      )

      if (!destinationZone) {
        throw new Error(__(T.error.qc_zone_not_found))
      }
      this.setState({ cqDestinationZone: destinationZone.id })

      const multipleInboundRequest = CustomInboundProvider.getMultipleGateInboundRequest(
        Array.from(inboundShipmentParcels.map((parcel) => parcel?.header.parcelCode || '').values()),
        expectedItems,
        qcResponse
      )

      await Gate.startMultipleInbound(multipleInboundRequest)

      this.setState({
        loading: false,
        checkListType,
        parcels: inboundShipmentParcels,
        groupedProducts,
        reading: true,
      })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? __(T.error.generic_error),
        status: 'error',
      })
      this.navigateBack()
    }
  }

  componentWillUnmount() {
    Gate.closeEventSource()
    //Gate.emergencyStop() // TODO: questo va fatto?
  }

  onTagRead = (event: any) => {
    const data = JSON.parse((event.data as string).replace(/'/g, '"'))

    if (data.gateStatus === 'QC') {
      const items = this.state.cqItems ?? []
      const expectedItems = this.state.expectedItems!.map((expectedItem) => {
        return { id: expectedItem.id, codes: expectedItem.itemIdentifiers.flatMap((identifier) => identifier.code) }
      })

      const itemToAdd = expectedItems.find((itm) => itm.codes.includes(data.epc))
      if (itemToAdd) {
        items?.push({ id: itemToAdd.id })
      }

      this.setState({ cqItems: items, cqDestinationZone: this.state.cqDestinationZone })
    }

    this.onDecodedItemCallback(data.epc)
  }

  onError = (event: any) => {
    const data = JSON.parse((event.data as string).replace(/'/g, '"'))
    showToast({ status: 'error', title: `${__(T.error.error)}: ${data.code}`, description: data.message })
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error(__(T.error.undefined_operation_config))

    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
    })
  }

  onDecodedItemCallback = async (epc: string) => {
    const { parcels: inboundShipmentParcels, groupedProducts } = this.state

    const expectedItems: TmrItem[] = []
    inboundShipmentParcels.forEach((parcel) => parcel.expectedItems.forEach((item) => expectedItems.push(item)))
    const items = expectedItems.filter((itm) => itm?.itemIdentifiers?.find((itmId) => itmId.code === epc) !== undefined)

    if (!items || items.length === 0) {
      const item = await Items.decode(epc)
      if (item.id && item.itemIdentifiers) items.push(item as TmrItem)
    }

    ShipmentProvider.processInboundItemsStates(items, this.operation)

    CustomInboundProvider.customProcessItemForItemsChecklist(
      items,
      inboundShipmentParcels,
      groupedProducts,
      this.params.inboundZone
    )

    const counters = this.getParcelsCounters(inboundShipmentParcels, this.state.checkListType)

    if (!this.unexpectedFound && counters.totalUnexpected > 0) {
      this.unexpectedFound = true
      Sounds.error()
    }

    if (counters.totalDetected === counters.totalExpected && counters.totalUnexpected === 0) {
      Sounds.success()
    }

    this.forceUpdate()
  }

  save = async () => {
    await this.confirmInbound(false)
  }

  saveAndConfirm = async () => {
    await this.confirmInbound(true)
  }

  confirmInbound = async (confirmParcels: boolean) => {
    const { parcels: inboundShipmentParcels, checkListType } = this.state
    const counters = this.getParcelsCounters(inboundShipmentParcels, this.state.checkListType)

    try {
      for (const parcel of inboundShipmentParcels) {
        if (
          parcel?.detectedItems?.filter((item) =>
            item.__processedStates?.map((state) => state.processedState)?.includes('ERROR')
          ).length
        )
          throw new Error(__(T.error.items_in_error_found))
      }

      if (
        counters.totalDetected < counters.totalExpected ||
        counters.totalDetected > counters.totalExpected ||
        counters.totalUnexpected > 0
      ) {
        const confirmed = confirmParcels
          ? await askUserConfirmation(__(T.confirm.discrepancy_title), __(T.confirm.discrepancy_message))
          : await askUserConfirmation(__(T.confirm.save_parcel_readings), __(T.confirm.save_parcel_readings_message))
        if (!confirmed) return
      }

      if (confirmParcels) {
        await Gate.closeInbound(getMatchParams(this.props).ddtCode)
      }

      await CustomInboundProvider.confirmDdtReadingsInboundIgnoringUnexpected(
        inboundShipmentParcels,
        this.operation!.id,
        this.params.inboundZone!.id,
        checkListType,
        confirmParcels,
        this.state.cqItems,
        this.state.cqDestinationZone
      )
      showToast({
        title: __(T.misc.success),
        description: confirmParcels ? __(T.messages.inbound_success) : __(T.messages.operation_success),
        status: 'success',
      })

      if (confirmParcels) {
        this.navigateBack()
      }
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? __(T.error.generic_error),
        status: 'error',
      })
    }
  }

  openReadParcel = (parcel: ShipmentParcel) => {}

  navigateBack = () =>
    navigate('/inbound/gate/shipments/:configCode', { configCode: getMatchParams(this.props).configCode })

  toggleGate = async () => {
    const { reading } = this.state
    try {
      if (reading) {
        const confirmed = await askUserConfirmation(__(T.misc.alarm), __(T.confirm.confirm_stop_gate))
        if (!confirmed) return
        await Gate.emergencyStop()
      } else {
        await Gate.resume()
      }
      this.setState({ reading: !this.state.reading })
    } catch (error) {
      showToast({ status: 'error', title: __(T.error.error), description: error.message })
    }
  }

  renderIcon = () => {
    const style = {
      marginRight: 10,
      width: 36,
      height: 36,
    }
    const { reading } = this.state
    if (reading) {
      return <Icons.Pause style={style} />
    }
    return <Icons.Play style={style} />
  }

  renderGateButton = () => {
    const { reading } = this.state
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: (Onde as any).default,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }
    return (
      <Container row>
        <ButtonControl
          style={{ backgroundColor: reading ? '#EF4141' : '#ceff00' }}
          row
          center
          onClick={() => this.toggleGate()}
        >
          {this.renderIcon()}
          {reading ? __UP(T.misc.alarm) : __UP(T.misc.start)}
        </ButtonControl>
        {reading && (
          <div style={{ padding: '5px 10px' }}>
            <Lottie isClickToPauseDisabled style={{ width: '100%', height: '100%' }} options={defaultOptions} />
          </div>
        )}
      </Container>
    )
  }

  getParcelsCounters = (parcels: ShipmentParcel[], checklistType: CheckListType) => {
    const counters = { totalDetected: 0, totalExpected: 0, totalUnexpected: 0 }
    for (const parcel of parcels) {
      const counter = ShipmentProvider.getCounters(parcel, checklistType)
      counters.totalDetected += counter.detected
      counters.totalExpected += counter.expected
      counters.totalUnexpected += counter.unexpected
    }
    return counters
  }

  render() {
    const { parcels: inboundShipmentParcels, loading, totalProductsQuantity } = this.state
    const { totalDetected, totalExpected, totalUnexpected } = this.getParcelsCounters(inboundShipmentParcels, 'ITEMS')
    const error = totalUnexpected > 0 ? __(T.error.rfid_discrepancies_found) : ''
    const pageTitle = this.operation?.description ?? __(T.titles.inbound)

    const { ddtCode } = getMatchParams(this.props)
    const parcelsCount = inboundShipmentParcels?.length?.toString() ?? '0'

    return (
      <Page
        title={pageTitle}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details: [
            { label: __(T.misc.parcels), value: parcelsCount },
            { label: __(T.misc.items), value: `${totalDetected}/${totalExpected}` },
            { label: __(T.misc.ddt_code), value: ddtCode },
          ],
        }}
        enableEmulation
        emulationFunction={this.emulate}
      >
        <Page.Sidebar style={{ overflow: 'auto' }}>
          <Box flex style={{ overflow: 'auto' }}>
            <TagCounter
              detected={totalDetected - totalUnexpected}
              expected={totalExpected}
              unexpected={totalUnexpected}
            />
            {this.renderGateButton()}
            <Spacer />
            <TextBox text={error} type="error" />
          </Box>
          <Button title={__(T.misc.save_parcels)} onClick={this.save} variant="default" />
          <Spacer />
          <Button title={__(T.misc.confirm_parcels)} onClick={this.saveAndConfirm} />
        </Page.Sidebar>

        <Page.Content>
          {inboundShipmentParcels && inboundShipmentParcels.length > 0 && (
            <>
              <SectionTitle>{__(T.misc.parcels)}</SectionTitle>
              {inboundShipmentParcels?.map((parcel, index) => (
                <Section
                  key={index}
                  header={
                    <ParcelSectionHeader
                      parcel={parcel}
                      openReadParcel={this.openReadParcel}
                      operation={this.operation}
                    />
                  }
                  body={
                    <Table
                      headerStyle={{ position: 'relative' }}
                      style={{ paddingTop: 0 }}
                      showHeader={false}
                      structure={Structures.ShipmentProductStructure(totalProductsQuantity)}
                      loading={loading}
                      data={parcel.groupedProducts ?? []}
                      emptyStyle={{ height: 300 }}
                    />
                  }
                />
              ))}
            </>
          )}
        </Page.Content>
      </Page>
    )
  }

  emulate = async (epcs: string[]) => {
    await Gate.emulateTags({ epcs: epcs, delay: 1000 })
  }
}

const ButtonControl = styled(Box)`
  flex: 1;
  background-color: #ceff00;
  border-radius: 50px;
  padding: 15px 25px;
  cursor: pointer;
  font-weight: 900;
  font-size: 22px;
`

const Container = styled(Box)`
  background-color: white;
  border: solid 2px #eeeeee;
  border-radius: 50px;
  padding: 10px;
`
const SectionTitle = styled.div`
  font-weight: 900;
  font-size: 28px;
  margin-bottom: 10px;
`
