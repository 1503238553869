import React, { Component } from 'react'
import { StatusLabel } from 'components'
import { T, __ } from 'translations/i18n'

interface Props {
  status: string
  width?: number
}

export default class PackingListStatus extends Component<Props> {
  statuses = {
    DRAFT: __(T.labels.draft) ,
    WAIT_FOR_CQ: __(T.labels.wait_for_cq) ,
    CQ_IN_PROGRESS: __(T.labels.cq_in_progress) ,
    WAIT_FOR_DDT: __(T.labels.wait_for_ddt) ,
    READY_TO_SEND: __(T.labels.ready_to_send) ,
    COMPLETED: __(T.labels.completed) ,
    CQ_ERROR: __(T.labels.cq_error) ,
    ERROR_PL: __(T.labels.error_pl),
  }

  getPackingListState = () => {
    const { status } = this.props
    return status
  }

  getStatusLabelText = (): string => {
    const { status } = this.props
    return this.statuses[this.getPackingListState()] ?? status
  }

  render() {
    const { width } = this.props

    return (
      <StatusLabel width={width} status={this.getPackingListState()} text={this.getStatusLabelText().toUpperCase()} />
    )
  }
}
