import { ApisauceConfig } from 'apisauce'
import { EncodingInitialType } from 'shared/RemoteConfig'
import Sounds from 'shared/Sounds'
import { T, __ } from 'translations/i18n'
import api, { responseErrorCheck } from './api'
import Products from './Products'
import { CheckSeason, ProductionOrder, ProductionOrderRow, ReadIdentifierType, RpacIdentifier } from './types'

type ProductionOrderRowSearchParam = { orderCode?: string; productCode?: string; identifier?: string }

export type ValidationBodyRequest = {
  productionOrderId?: string
  productId: string
  identifiers: {
    code: string
    type: string
  }[]
  zoneId?: string
  itemId?: string
  disableValidationCheck?: string[]
}

export type CheckSeasonRequest = {
  prodOrdId: string
  oldEpc: string
  newEpc: string
  operation: string
  tid?: string
}

export default class Encodings {
  static getProductionOrder(code: string, requestConfig?: ApisauceConfig) {
    return api
      .get<ProductionOrder>(`/operations/encoding/productionOrder/${code}`, undefined, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static nextEpc(productCode: string, tid?: string, tagRole?: string) {
    return api
      .get<{ value: string }>(`/operations/encoding/nextEpc`, { productCode, tid, tagRole })
      .then(responseErrorCheck)
      .then((res) => res)
  }

  // static async getProductionOrderRows(
  //   searchProductionOrderSetting: boolean,
  //   orderCode,
  //   productCode,
  //   requestConfig?: ApisauceConfig
  // ) {
  static async getProductionOrderRows(
    searchProductionOrderSetting: EncodingInitialType,
    params: ProductionOrderRowSearchParam,
    requestConfig?: ApisauceConfig
  ) {
    const { orderCode, productCode, identifier } = params
    let data
    switch (searchProductionOrderSetting) {
      case 'order':
        data = responseErrorCheck(
          await api.get<ProductionOrderRow>(
            '/operations/encoding/productionOrderRows',
            { orderCode: orderCode, productCode: productCode },
            requestConfig
          )
        )
        if (!data) throw new Error()
        break
      case 'ean':
        data = await Products.get(productCode!)
        if (!data || data.length !== 1) throw new Error()
        data = { product: data[0] }
        break
      case 'certilogo':
        data = responseErrorCheck(
          await api.get<ProductionOrderRow>(
            `/operations/encoding/info?identifierCode=${identifier}`,
            undefined,
            requestConfig
          )
        )
        break

      default:
        throw new Error('searchProductionOrderSetting cannot be empty')
    }

    const idfs = [] as ReadIdentifierType[]

    // idfs.push({ status: 'waiting', type: 'NFCTag' })
    // idfs.push({ status: 'waiting', type: 'UHFTag' })
    data.product.itemConfiguration.identifiers
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1
        }
        if (b.type > a.type) {
          return -1
        }
        return 0
      })
      .forEach((idf) => {
        for (let index = 0; index < idf.amount; index++) {
          idfs.push({ status: 'waiting', type: idf.type })
        }
      })

    return { data, idfs } as { data: ProductionOrderRow; idfs: ReadIdentifierType[] }
  }

  static async validate(idfs: any[], params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    const res = responseErrorCheck(await api.post<any>('/operations/encoding/validate', params, requestConfig))
    let foundError = false
    idfs
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1
        }
        if (b.type > a.type) {
          return -1
        }
        return 0
      })
      .forEach((idf) => {
        if (!idf.code) {
          return
        }
        const err = res.errors && res.errors.find((idfError) => idfError.ref === idf.code)
        if (err) {
          foundError = true
          idf.status = 'error'
          switch (err.errorCode) {
            case 'ENCODING_ERROR.IDENTIFIER_NOT_FOUND':
              idf.errorMessage = __(T.error.tag_not_found)
              break
            case 'ENCODING_ERROR.IDENTIFIER_TYPE_UNEXPECTED':
              idf.errorMessage = __(T.error.tag_type_unexpected)
              break
            case 'ENCODING_ERROR.IDENTIFIER_ALREADY_ASSOCIATED':
              idf.errorMessage = __(T.error.tag_already_associated)
              break

            default:
              idf.errorMessage = err.errorCode
              break
          }
        } else {
          idf.status = 'confirmed'
        }
      })
    if (foundError) {
      Sounds.error()
    }
    if (
      res &&
      res.errors.findIndex((e) => e.errorCode === 'ENCODING_ERROR.PRODUCTION_ORDER_NOT_FOUND' && !e.ref) > -1
    ) {
      res.status_error = 'ENCODING_ERROR.PRODUCTION_ORDER_NOT_FOUND'
    }
    return res
  }

  static associate(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
        .post<any>('custom/operations/encoding/create', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static checkSeason(params: CheckSeasonRequest) {
    return api
      .post<CheckSeason>('custom/operations/encoding/checkSeason', params)
      .then(responseErrorCheck)
      .then((res) => res as CheckSeason)
  }

  static getRpacTag(tid: string) {
    return api
      .get<RpacIdentifier>(`/custom/operations/encoding/getRpacTag/${tid}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static settings(params?: any, requestConfig?: ApisauceConfig) {
    return api
      .get<any>('/operations/encoding/settings', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static verify(
    params: {
      code: string
      type: string
    }[],
    requestConfig?: ApisauceConfig
  ) {
    return api
      .post<any>('/operations/encoding/verify', { identifiers: params }, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
