import React, { Component } from 'react'
import {
  Page,
  Spacer,
  TagCounter,
  Box,
  TextBox,
  Button,
  Section,
  ParcelSectionHeader,
  Table,
  AntennaButton,
} from 'components'
import { GroupedShipmentProduct, ShipmentParcel, TmrItem, TmrZone } from 'api/types'
import { navigate, getMatchParams, getLocationState } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import { T, __ } from 'translations/i18n'
import { PageParams } from 'pages'
import Sounds from 'shared/Sounds'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import styled from '@emotion/styled'
import Items from 'api/Items'
import Structures from 'shared/Structures'
import CustomInboundProvider from './CustomInboundProvider'
import RfidReader from '../../shared/RfidReader'
import Shipments from '../../api/Shipments'

interface Params extends PageParams {
  inboundZone?: TmrZone
  ddtCode: string
  parcels: string
}

interface State {
  parcels: ShipmentParcel[]
  checkListType: CheckListType
  groupedProducts: GroupedShipmentProduct[]
  loading: boolean
  reading: boolean
  totalProductsQuantity: number
}

export default class CustomStesiDdtInboundShipmentReading extends Component<{}, State> {
  unexpectedFound = false
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  params: Params = {
    inboundZone: CustomInboundProvider.getDefaultStockZone(),
    ...getMatchParams(this.props),
  }

  state: State = {
    parcels: [],
    checkListType: 'ITEMS',
    totalProductsQuantity: 0,
    groupedProducts: [],
    loading: true,
    reading: false,
  }

  async initRfidDevice() {
    await RfidReader.initialize()
    RfidReader.onDecodedItemCallback = this.onDecodedItemCallback
    RfidReader.setDecodeFunction(this.decodeFunction)
  }

  async componentDidMount() {
    try {
      if (!this.params.inboundZone) throw new Error(__(T.error.no_inbound_zones))
      if (!this.operation) throw new Error(__(T.error.no_operation_config))
      if (this.operation.readingMode !== 'rfid') throw new Error(__(T.error.not_supported_reading_mode))

      const { ddtCode } = getMatchParams(this.props)
      const { shipments } = getLocationState(this.props)

      if (!shipments && shipments.length === 0) throw new Error(__(T.error.shipment_not_found))

      const {
        checkListType,
        inboundShipmentParcels,
        groupedProducts,
      } = await CustomInboundProvider.fetchInboundShipmentParcels({ shippingCodes: shipments }, false)

      if (!inboundShipmentParcels || inboundShipmentParcels.length === 0)
        throw new Error(__(T.error.no_parcels_for_ddt_found, { ddtCode: ddtCode }))

      await this.initRfidDevice()

      this.setState({
        loading: false,
        checkListType,
        parcels: inboundShipmentParcels,
        groupedProducts,
        reading: true,
      })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? __(T.error.generic_error),
        status: 'error',
      })
      this.navigateBack()
    }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  clear = () => {
    const { parcels: inboundShipmentParcels } = this.state

    RfidReader.clear()
    this.unexpectedFound = false

    CustomInboundProvider.customClearAllReceivingReadings(inboundShipmentParcels)

    this.setState({
      parcels: inboundShipmentParcels,
      groupedProducts: inboundShipmentParcels.flatMap((parcel) => parcel.groupedProducts ?? []),
    })
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error(__(T.error.undefined_operation_config))

    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
    })
  }

  onDecodedItemCallback = async (itemMap) => {
    const { parcels: inboundShipmentParcels, groupedProducts } = this.state

    let items = Object.values<TmrItem>(itemMap).filter((itm) => itm?.zone?.zoneType !== 'STOCK')

    ShipmentProvider.processInboundItemsStates(items, this.operation)

    CustomInboundProvider.customProcessItemForItemsChecklist(
      items,
      inboundShipmentParcels,
      groupedProducts,
      this.params.inboundZone
    )

    const counters = this.getParcelsCounters(inboundShipmentParcels, this.state.checkListType)

    if (!this.unexpectedFound && counters.totalUnexpected > 0) {
      this.unexpectedFound = true
      Sounds.error()
    }

    if (counters.totalDetected === counters.totalExpected && counters.totalUnexpected === 0) {
      Sounds.success()
    }

    // this.setState({ parcels: inboundShipmentParcels })
    this.forceUpdate()
  }

  save = async () => {
    await this.confirmInbound(false)
  }

  saveAndConfirm = async () => {
    await this.confirmInbound(true)
  }

  confirmInbound = async (confirmParcels: boolean) => {
    const { parcels: inboundShipmentParcels, checkListType } = this.state
    const counters = this.getParcelsCounters(inboundShipmentParcels, this.state.checkListType)

    try {
      for (const parcel of inboundShipmentParcels) {
        if (
          parcel?.detectedItems?.filter((item) =>
            item.__processedStates?.map((state) => state.processedState)?.includes('ERROR')
          ).length
        )
          throw new Error(__(T.error.items_in_error_found))
      }

      if (
        counters.totalDetected < counters.totalExpected ||
        counters.totalDetected > counters.totalExpected ||
        counters.totalUnexpected > 0
      ) {
        const confirmed = confirmParcels
          ? await askUserConfirmation(__(T.confirm.discrepancy_title), __(T.confirm.discrepancy_message))
          : await askUserConfirmation(__(T.confirm.save_parcel_readings), __(T.confirm.save_parcel_readings_message))
        if (!confirmed) return
      }

      await CustomInboundProvider.confirmDdtReadingsInbound(
        inboundShipmentParcels,
        this.operation!.id,
        this.params.inboundZone!.id,
        checkListType,
        confirmParcels
      )
      showToast({
        title: __(T.misc.success),
        description: confirmParcels ? __(T.messages.inbound_success) : __(T.messages.operation_success),
        status: 'success',
      })

      if (confirmParcels) {
        this.navigateBack()
      }
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? __(T.error.generic_error),
        status: 'error',
      })
    }
  }

  openReadParcel = (parcel: ShipmentParcel) => {}

  navigateBack = () =>
    navigate('/inbound/stesi/shipments/:configCode', { configCode: getMatchParams(this.props).configCode })

  getParcelsCounters = (parcels: ShipmentParcel[], checklistType: CheckListType) => {
    const counters = { totalDetected: 0, totalExpected: 0, totalUnexpected: 0 }
    for (const parcel of parcels) {
      const counter = ShipmentProvider.getCounters(parcel, checklistType)
      counters.totalDetected += counter.detected
      counters.totalExpected += counter.expected
      counters.totalUnexpected += counter.unexpected
    }
    return counters
  }

  render() {
    const { parcels: inboundShipmentParcels, loading, totalProductsQuantity, groupedProducts } = this.state
    const { totalDetected, totalExpected, totalUnexpected } = this.getParcelsCounters(inboundShipmentParcels, 'ITEMS')
    const error = totalUnexpected > 0 ? __(T.error.rfid_discrepancies_found) : ''
    const pageTitle = this.operation?.description ?? __(T.titles.inbound)

    const { ddtCode } = getMatchParams(this.props)
    const parcelsCount = inboundShipmentParcels?.length?.toString() ?? '0'

    return (
      <Page
        title={pageTitle}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details: [
            { label: __(T.misc.parcels), value: parcelsCount },
            { label: __(T.misc.items), value: `${totalDetected}/${totalExpected}` },
            { label: __(T.misc.ddt_code), value: ddtCode },
          ],
        }}
        enableEmulation
      >
        <Page.Sidebar style={{ overflow: 'auto' }}>
          <Box flex style={{ overflow: 'auto' }}>
            <TagCounter detected={totalDetected} expected={totalExpected} unexpected={totalUnexpected} />
            <AntennaButton style={{ backgroundColor: 'white' }} onClear={this.clear} hideClear={totalDetected === 0} />
            <Spacer />
            <TextBox text={error} type="error" />
          </Box>
          <Button title={__(T.misc.save_parcels)} onClick={this.save} variant="default" />
          <Spacer />
          <Button title={__(T.misc.confirm_parcels)} onClick={this.saveAndConfirm} />
        </Page.Sidebar>

        <Page.Content>
          {inboundShipmentParcels && inboundShipmentParcels.length > 0 && (
            <>
              <SectionTitle>{__(T.misc.parcels)}</SectionTitle>
              {inboundShipmentParcels?.map((parcel, index) => (
                <Section
                  key={index}
                  header={
                    <ParcelSectionHeader
                      parcel={parcel}
                      openReadParcel={this.openReadParcel}
                      operation={this.operation}
                    />
                  }
                  body={
                    <Table
                      headerStyle={{ position: 'relative' }}
                      style={{ paddingTop: 0 }}
                      showHeader={false}
                      structure={Structures.ShipmentProductStructure(totalProductsQuantity)}
                      loading={loading}
                      data={parcel.groupedProducts ?? []}
                      emptyStyle={{ height: 300 }}
                    />
                  }
                />
              ))}
            </>
          )}
        </Page.Content>
      </Page>
    )
  }
}

const SectionTitle = styled.div`
  font-weight: 900;
  font-size: 28px;
  margin-bottom: 10px;
`
